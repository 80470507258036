import React from 'react'

import { Item, Icon, ItemWrapper } from './styles/indexStyle'

const Attractions = props => {

  return (
    <ItemWrapper key={props.index}>
      <Item
        attractionCategory={props?.properties?.icon}
        activeBtn={props.activeBtn}
        activeCityID={props.activeCityID}
        index={props.index}
        onClick={e => {
          e.preventDefault()
          props.goToAttraction(props.index)
          if (window.innerWidth < '1024') {
            window.location.href = '#map'
          }
        }}
      >
        <div>
          <Icon attractionCategory={props?.properties?.icon} />
          <h3 key={props?.id}>{props?.properties?.attraction?.title}</h3>
        </div>
      </Item>
    </ItemWrapper>
  )
}
export default Attractions
