import React from 'react'

import { Teaser } from '../Teaser'
import { SectionHeader } from '../SectionHeader'

import { WhatsOnContainer, ThreeWrapper } from './style.js'

const WhatsOn = (props) => {
  const mainEvent = props?.featuredEvents[0]

  return (
    <div>
      <WhatsOnContainer>
        <SectionHeader
          secondary
          title="What’s on"
          more="VIEW ALL EVENTS"
          path="/whats-on"
        />
        <div style={{ overflow: 'hidden' }}>
          {mainEvent && (
            <Teaser
              displayCat={mainEvent.displayCat}
              event
              fullWidth
              free={mainEvent.avEvents.dates === 'free'}
              soldOut={mainEvent.avEvents.dates === 'sold'}
              img={{ ...mainEvent.avContent.featureImage, sizes: '1000px' }}
              title={mainEvent.title}
              categories={mainEvent.categories}
              url={mainEvent.uri}
              openInNewTab
              desc={mainEvent.excerpt}
              date={mainEvent.avEvents.dates}
              databaseId={mainEvent.databaseId}
              region={mainEvent?.avContactInfo?.region}
              homeReduced
            />
          )}
        </div>

        <ThreeWrapper>
          {props?.featuredEvents?.map(
            (event, index) =>
              index !== 0 && (
                <Teaser
                  displayCat={event.displayCat}
                  event
                  free={event.avEvents.ticketStatus === 'free'}
                  soldOut={event.avEvents.ticketStatus === 'sold'}
                  key={index}
                  standard
                  img={{
                    ...event.avContent.featureImage,
                    sizes: '(min-width: 768px) 400px, 600px',
                  }}
                  title={event.title}
                  categories={event.categories}
                  url={event.uri}
                  openInNewTab
                  desc={event.excerpt}
                  date={event.avEvents.dates}
                  databaseId={event.databaseId}
                  region={event?.avContactInfo?.region}
                />
              )
          )}
        </ThreeWrapper>
      </WhatsOnContainer>
    </div>
  )
}

export default WhatsOn
