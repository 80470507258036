import React, { Component } from 'react'
import { HorizontalLine } from '../HorizontalLine'
import { StaticQuery, graphql } from 'gatsby'
import SelectorItem from './SelectorItem'

import {
  SelectorContainer,
  SelectorTagContainer,
  SelectorTag,
  SelectorTitle,
  ClickContainer,
  Selector,
  SelectorMenu,
  DropDown,
} from './style.js'
import arrow from '../../images/carousel-arrow.svg'
import rain from '../../images/sun-cloud-rain.svg'
import sun from '../../images/sun.svg'
import kid from '../../images/kid.svg'
import coffee from '../../images/coffee.svg'
import dog from '../../images/dog.svg'
import history from '../../images/museum.svg'
import date from '../../images/kiss.svg'

class CarouselSelector extends Component {
  constructor(props) {
    super(props)

    this.selectorRef = React.createRef()

    this.state = {
      selectedText: ``,
      tagText: '',
      slug: '',
      menuOpen: false,
    }

    this.handleSelect = this.handleSelect.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleLeave = this.handleLeave.bind(this)
  }

  componentDidMount() {
    this.createObserver()
  }

  createObserver() {
    var observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      root: null,
      rootMargin: '200px 0px 200px 0px',
      threshold: 0,
    })

    observer.observe(this.selectorRef.current)
  }

  handleIntersect(entries, observer) {
    if (entries[0].isIntersecting) {
      observer.unobserve(this.selectorRef.current)

      this.setTag(this.props.selectiondata[0])
    }
  }

  setTag(selection) {
    // const tagImg = selection.slug.replace(/-/g, '');

    this.setState(
      {
        tagText: selection.avSuggestion.tagline,
        slug: selection.slug,
        selectedText: selection.title,
      },
      () => this.props.updateData(selection.databaseId)
    )
  }

  handleSelect(selection) {
    this.setState(
      {
        selectedText: `${selection.title}`,
        menuOpen: false,
      },
      () => this.setTag(selection)
    )

    this.props.fadeOut()
  }

  handleClick() {
    this.setState({
      menuOpen: true,
    })
  }

  handleLeave() {
    this.setState({
      menuOpen: false,
    })
  }

  render() {
    const stringArray = this.props.selectiondata.map(
      suggestion => suggestion.slug
    )
    const iconArray = [date, history, kid, coffee, sun, rain]
    const iconObject = Object.assign(
      ...stringArray.map((string, i) => ({ [string]: iconArray[i] }))
    )

    return (
      <SelectorContainer ref={this.selectorRef}>
        <SelectorTagContainer>
          <img
            src={iconObject[this.state.slug]}
            alt="icon"
            width="60"
            height="60"
          />

          <SelectorTag>
            <HorizontalLine />
            <p>{this.state.tagText}</p>
            <HorizontalLine />
          </SelectorTag>
        </SelectorTagContainer>

        <SelectorTitle>
          <h1>FIND THINGS TO DO</h1>
          <ClickContainer
            onMouseDown={this.handleClick}
            onMouseLeave={this.handleLeave}
          >
            <Selector>
              <h1
                dangerouslySetInnerHTML={{
                  __html: this.state.selectedText,
                }}
              />

              <SelectorMenu menuOpen={this.state.menuOpen === true}>
                {this.props.selectiondata.map((selection, index) => (
                  <SelectorItem
                    index={index}
                    key={index}
                    selection={selection}
                    handleSelect={this.handleSelect}
                  />
                ))}
              </SelectorMenu>
            </Selector>

            <DropDown>
              {' '}
              <img src={arrow} alt="" />
            </DropDown>
          </ClickContainer>
        </SelectorTitle>
      </SelectorContainer>
    )
  }
}

// export default CarouselSelector;

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWpSuggestion {
          nodes {
            title
            slug
            databaseId
            avSuggestion {
              tagline
            }
          }
        }
      }
    `}
    render={({ allWpSuggestion }) => (
      <CarouselSelector selectiondata={allWpSuggestion.nodes} {...props} />
    )}
  />
)
