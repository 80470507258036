import React, { Component } from 'react'
import CarouselCards from './CarouselCards.js'
import { CarouselSelector } from '../CarouselSelector'
import axios from 'axios'

import {} from './style.js'

class Carousel extends Component {
  constructor(props) {
    super(props)

    // this.carouselRef = React.createRef()

    this.state = {
      selectedArray: '0',
      opaque: true,
      dataLoaded: false,
      selectedData: [],
      resetTriggered: false,
    }

    this.updateData = this.updateData.bind(this)
    this.fadeOut = this.fadeOut.bind(this)
  }

  async updateData(selection) {
    await axios
      .get(
        `${
          process.env.GATSBY_WORDPRESS_DOMAIN
        }/wp-json/madeofballarat/v1/suggestions/${selection}`
      )
      .then(res => {
        this.setState(
          {
            selectedData: res.data.items,
          },
          () => this.fadeIn()
        )
      })
  }

  triggerReset() {
    this.setState({
      resetTriggered: !this.state.resetTriggered,
    })
  }

  fadeOut() {
    this.setState(
      {
        opaque: false,
      },
      () => this.triggerReset()
    )
  }

  fadeIn() {
    this.setState({
      opaque: true,
    })
  }

  render() {
    return (
      <div>
        {this.props.selector && (
          <CarouselSelector
            fadeOut={this.fadeOut}
            updateData={this.updateData}
          />
        )}

        <CarouselCards
          resetTriggered={this.state.resetTriggered}
          selectedData={this.state.selectedData}
          numberOfCards={this.state.selectedData.length}
          opaque={this.state.opaque}
          selectedArray={this.state.selectedArray}
          {...this.props}
        />
      </div>
    )
  }
}

export default Carousel
