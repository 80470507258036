import React from 'react'

import City from './city'
import Attractions from './attractions'
import { List, ListWrapper } from './styles/indexStyle'

const Listing = props => {
  const createCityList = (city, index) => {
    return props.activeBtn === 'attractions' ? (
      <Attractions
        activeBtn={props.activeBtn}
        properties={city}
        goToCity={props.goToCity}
        goToAttraction={props.goToAttraction}
        activeCityID={props.activeCityID}
        key={index}
        index={index}
      />
    ) : (
      <City
        activeBtn={props.activeBtn}
        properties={city.properties}
        goToCity={props.goToCity}
        activeCityID={props.activeCityID}
        key={index}
        index={index}
      />
    )
  }

  const city = props.cities


  const cityList = city.map(createCityList)
  return (
    <ListWrapper>
      <List>{cityList}</List>
    </ListWrapper>
  )
}

export default Listing
