import React, { Component } from 'react'
import { AvenueImg } from '../AvenueImg'
import { getImageRatio } from '../../utils/helpers'
import { Carousel } from '../Carousel'

import {
  Wrapper,
  ImagesContainer,
  TextContainer,
  LeftSide,
  InstaLink,
  Icon,
} from './style.js'
import instagram from '../../images/instagram-logo.svg'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'

class Journeys extends Component {
  render() {
    return (
      <div>
        <ParallaxProvider>
          <Wrapper>
            <LeftSide>
              <Icon src={instagram} alt="hi" />

              <TextContainer>
                <h1>{this.props.title}</h1>
                <Carousel
                  journeys
                  journeysImgs={this.props.gallery}
                  cardsToShow={3}
                />

                <div
                  className="textWrap"
                  dangerouslySetInnerHTML={{ __html: this.props.desc }}
                />

                <InstaLink
                  target="_blank"
                  href={this.props.link}
                  dangerouslySetInnerHTML={{ __html: this.props.text }}
                />
              </TextContainer>
            </LeftSide>
            <ImagesContainer>
              {this.props.gallery.map((img, index) => {
                const ratio = getImageRatio(img.srcSet)
                return (
                  <Parallax
                    key={index}
                    y={[-25, 25]}
                    tagOuter="figure"
                  >
                    <AvenueImg
                      key={index}
                      aspectRatio={ratio}
                      sizes="350px"
                      src={img.url}
                      srcSet={img.srcSet}
                      alt={this.props.title}
                    />
                  </Parallax>
                )
              })}
            </ImagesContainer>
          </Wrapper>
        </ParallaxProvider>
      </div>
    )
  }
}

export default Journeys
