/* global mapboxgl */

import './styles/mapbox.css'
import React from 'react'
import anime from 'animejs'
import LoadExternalScript from '../LoadExternalScript'
import location from './data/cityData'
import Listing from './listing'
import SideBarHeader from './sideBarHeader'

import {
  Sidebar,
  Heading,
  Map,
  SubHeading,
  Container,
} from './styles/indexStyle'

const locationData = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    },
  ],
}

let aAttractions

class BallMap extends React.Component {
  constructor(props) {
    super(props)
    location.features = location.features.map(function(city, index) {
      city.id = index
      return city
    })
    aAttractions = props.attractions

    aAttractions = aAttractions.map(function(attraction, index) {
      attraction.id = index
      return attraction
    })

    this.handleClick = this.handleClick.bind(this)
    this.flyToCity = this.flyToCity.bind(this)

    this.state = {
      data: aAttractions,
      center: [143.84841, -37.55822], //Ballarat
      zoom: 11,
      activeCityID: 0,
      activeBtn: 'attractions',
      displaySideBar: false,
    }

    this.attractions = aAttractions
    this.mapPoints = locationData
    this.map = null
    this.currentCity = 0
    this.marker = 0
    this.speed = 10
    this.markers = []
    this.attractions = []
    this.animate = []
    this.markerLocation = []
    this.bbox = []
    this.counter = 0
    this.popUpSize = 0
    this.stopID = 0
  }

  componentDidMount() {
    if (typeof mapboxgl !== 'undefined') {
      this.createMap()
    }
  }

  handleClick(btn) {
    if (btn !== this.state.activeBtn) {
      this.setState({
        activeBtn: btn,
      })
      this.menuToggle(btn)
    }
  }

  setActiveCity(index) {
    this.setState({
      activeCityID: index,
    })
  }

  boundsView() {
    let startPoint = this.currentCity.geometry.coordinates[0]
    let lastPoint = this.currentCity.geometry.coordinates.length
    let endPoint = this.currentCity.geometry.coordinates[lastPoint - 1]

    this.bbox = [startPoint, endPoint]
    let isMobile = window.matchMedia('only screen and (max-width: 1024px)')
      .matches
    if (!isMobile) {
      this.map.fitBounds(this.bbox, {
        padding: { top: 50, bottom: 50, left: 50, right: 385 },
        maxZoom: 9,
      })
    } else {
      this.map.fitBounds(this.bbox, {
        padding: { top: 50, bottom: 50, left: 50, right: 50 },
        maxZoom: 9,
      })
    }
  }

  createMap() {
    if (!document.querySelector('#map')) {
      return false
    }

    mapboxgl.accessToken =
      'pk.eyJ1IjoidmlzaXRiYWxsYXJhdCIsImEiOiJjanQ5aWw0a3MwMGxuM3lsNno1d29mMjQ2In0.c7entLvKAHX7RDv72p0iew'

    this.reset = () => {
      this.counter = 0
      this.removeMarker()
      this.mapPoints.features[0].geometry.coordinates = []
      this.map.getSource('line-animation').setData(this.mapPoints)
      this.removePopups()
    }

    this.map = new mapboxgl.Map({
      center: this.state.center,
      zoom: this.state.zoom,
      container: 'map',
      style: 'mapbox://styles/visitballarat/cjt9mdyxm0e2c1flbsuepc4xh',
    })

    this.map.on('load', () => {
      this.setState({ displaySideBar: true })
      this.createAttraction()
      this.map.scrollZoom.disable()

      this.map.addLayer({
        id: 'line-animation',
        type: 'line',
        source: {
          type: 'geojson',
          data: locationData,
        },
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#212C1F',
          'line-width': 5,
          'line-opacity': 1,
        },
      })
    })

    this.animateLine = () => {
      if (!this.currentCity) return
      const coordinates = this.currentCity.geometry.coordinates[this.counter]
      if (!coordinates) return
      this.mapPoints.features[0].geometry.coordinates.push(coordinates)
      this.map.getSource('line-animation').setData(this.mapPoints)
      if (this.state.activeBtn === 'ballarat') {
        if (this.counter < this.currentCity.geometry.coordinates.length) {
          this.stopID = requestAnimationFrame(this.animateLine)
        }
      }

      this.counter += this.speed

      if (this.counter >= this.currentCity.geometry.coordinates.length) {
        let cityLength = this.currentCity.geometry.coordinates.length
        this.marker(cityLength - 1, true)
        this.title(Math.round(cityLength - 350))
        const finalCoordinate = this.currentCity.geometry.coordinates[
          cityLength - 1
        ]
        this.mapPoints.features[0].geometry.coordinates.push(finalCoordinate)
        this.map.getSource('line-animation').setData(this.mapPoints)
      }
    }

    this.removeMarker = () => {
      for (let i = 0; i < this.markers.length; i++) {
        let marker = this.markers
        if (marker[i]) marker[i].remove()
        this.markers = marker
      }
    }

    this.marker = (position, lastCoordinate) => {
      var el = document.createElement('div')
      el.className = lastCoordinate ? `last__marker` : `marker`
      this.markerLocation = new mapboxgl.Marker(el, {
        anchor: 'center',
      })
        .setLngLat(this.currentCity.geometry.coordinates[position])
        .addTo(this.map)

      this.markers.push(this.markerLocation)
      lastCoordinate === 'yes'
        ? anime({
            translateX: '-50%',
            translateY: '-50%',
            targets: this.markerLocation._element,
            scale: [0.7, 1],
            easing: 'easeOutElastic(1, .5)',
            duration: 1500,
          })
        : anime({
            translateX: '-50%',
            translateY: '-50%',
            targets: this.markerLocation._element,
            scale: [0, 1],
            easing: 'easeOutElastic(1, .5)',
            duration: 1500,
          })
    }

    this.title = position => {
      var el = document.createElement('div')
      el.className = `label`
      let content = document.createTextNode(this.currentCity.properties.time)
      el.appendChild(content)
      const label = new mapboxgl.Marker(el, {
        anchor: 'bottom',
      })
        .setLngLat(this.currentCity.geometry.coordinates[position])
        .addTo(this.map)
      this.markers.push(label)
      anime({
        targets: el,
        easing: 'linear',
        duration: 500,
        opacity: [0, 1],
        translateY: 0,
      })
    }

    this.removeAttraction = () => {
      for (let i = 0; i < this.attractions.length; i++) {
        let attraction = this.attractions
        if (attraction[i]) attraction[i].remove()
        this.attractions = attraction
      }
    }
    this.createAttraction = () => {
      aAttractions.forEach(attraction => {
        
        var el = document.createElement('div')
        el.className = `marker ${attraction.icon}`
        const attractionLocation = new mapboxgl.Marker(el, {
          anchor: 'center',
        })
          .setLngLat([attraction.attraction.map.lat, attraction.attraction.map.lng])
          .addTo(this.map)
        //create click event
        el.addEventListener('click', () => {
          // open popup and pass marker
          this.goToAttraction(attraction.id)
        })
        this.attractions.push(attractionLocation)
      })
    }
  }

  flyToCity = index => {
    const currentCity = this.state.data[index]
    this.currentCity = currentCity

    this.state.activeBtn === 'attractions'
      ? this.map.flyTo({
          center: [this.currentCity.attraction.map.lat, this.currentCity.attraction.map.lng],
          offset: [0, 100],
          zoom: 12,
        })
      : this.map.flyTo({
          center: this.state.center,
          zoom: this.state.zoom,
        })
  }

  goToAttraction = index => {
    this.reset()
    this.setActiveCity(index)
    this.flyToCity(index)
    this.createPopUp(index)
  }
  goToCity = index => {
    if (this.state.activeCityID === index) {
      return
    } else {
      cancelAnimationFrame(this.stopID)
      this.setActiveCity(index)
      this.flyToCity(index)
      // Wait for the flyTo, to finish
      this.map.once('movestart', () => {
        this.reset()
        this.removeAttraction()
      })

      this.boundsView()

      // Wait for the flyTo, to finish
      this.map.once('moveend', () => {
        this.marker(0)
        this.animateLine()
      })
    }
  }

  menuToggle = btn => {
    this.removePopups()
    this.reset()

    return btn === 'attractions'
      ? this.setState({ data: aAttractions, activeCityID: 0 }, () => {
          this.createAttraction()
          this.map.flyTo({
            center: [143.84841, -37.55822],
            zoom: 11,
          })
        })
      : this.setState({ data: location.features, activeCityID: 1 }, () => {
          this.goToCity(0)
        })
  }

  removePopups() {
    const popUps = document.getElementsByClassName('mapboxgl-popup')
    if (popUps[0]) popUps[0].remove()
  }

  createPopUp() {

    new mapboxgl.Popup({
      closeOnClick: false,
      className: 'popUp',
      anchor: 'bottom',
    })
      .setLngLat([this.currentCity.attraction.map.lat, this.currentCity.attraction.map.lng])
      .setHTML(
        `
          <a style="display: block;" href="${
            this.currentCity.attraction.uri
          }" target="_blank">
            <div class="popup__img-wrap">
              <img src="${this.currentCity.attraction.avContent.featureImage.mediaDetails.sizes[1].sourceUrl}" width="200"  height=" 120
              ${this.popUpSize}" alt="${this.currentCity.attraction.title}"/>
            </div>
            <div>
              <h4>
                ${this.currentCity.attraction.title}
              </h4>
            </div>
          </a>
        `
      )
      .addTo(this.map)
  }

  render() {
    return (
      <Container>
        <LoadExternalScript
          src="https://cdnjs.cloudflare.com/ajax/libs/mapbox-gl/0.53.1/mapbox-gl.js"
          id="example"
          onLoad={() => this.createMap()}
        />
        <Map id="map" />
        <Sidebar
          displaySideBar={this.state.displaySideBar}
          activeBtn={this.state.activeBtn}
        >
          <SideBarHeader
            activeBtn={this.state.activeBtn}
            index={this.state.activeBtn}
            handleClick={this.handleClick}
          />

          <Heading activeBtn={this.state.activeBtn}>
            LET'S GO FOR A DRIVE
          </Heading>
          <SubHeading activeBtn={this.state.activeBtn}>
            There’s a lot to discover and you’re only a short drive away.
          </SubHeading>

          <Listing
            id="listings"
            cities={this.state.data}
            activeCityID={this.state.activeCityID}
            goToCity={this.goToCity}
            activeBtn={this.state.activeBtn}
            goToAttraction={this.goToAttraction}
          />
        </Sidebar>
      </Container>
    )
  }
}

export default BallMap
