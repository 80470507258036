import styled from 'styled-components'

export const ComponentContainer = styled.div`
  padding: ${props => props.theme.globalPaddingMobile};

  @media (min-width: 768px) {
    display: grid;
    /* border: 1px dashed black; */
    margin: 0;
    padding: 0 30px;
  }

  @media (min-width: 1280px) {
    padding: ${props => props.theme.globalPaddingDesktop};
  }
`

export const WhatsOnContainer = styled.div`
  @media (min-width: 768px) {
    padding-bottom: 40px;
  }
  @media (min-width: 1024px) {
    padding-bottom: 60px;
  }
`
export const OverflowContainer = styled.div`
  overflow-x: hidden;
  /* padding-bottom: 3px;
margin-bottom:-3px; */
`
export const ThreeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px;
  overflow: hidden;

  @media (min-width: 480px) {
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 768px) {
    grid-gap: 30px;

    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1440px) {
    grid-gap: 55px;
  }
`

export const FourWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 20px;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const WhatsOnHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h1 {
    margin: 0;
  }
`

export const HorizontalLine = styled.div`
  background: black;
  height: 2px;
  width: 100%;
  display: ${props => (props.storyMain ? 'none' : props.story ? 'none' : null)};
  margin: ${props => (props.standard ? '0' : props.fullWidth ? '0' : '30PX 0')};
`

export const StoryLine = styled.div`
	background: black;
	height: 2px;
	width: 100%;
	display: ${props => (props.story ? 'inline' : 'none')};
	grid-column: ${props => (props.story ? 'span 2' : null)};

	/* position: absolute; */
	/* margin: ${props =>
    props.standard ? '0' : props.fullWidth ? '0' : '30PX 0'}; */
`
