import React, { Component } from 'react'
import { Teaser } from '../Teaser'
import { SectionHeader } from '../SectionHeader'

import { ComponentContainer, OverflowContainer } from '../WhatsOn/style.js'
import { StoriesContainer, RightWrapper, MainWrapper } from './style.js'

class Stories extends Component {
  render() {
    return (
      <div>
        <ComponentContainer>
          <SectionHeader
            secondary
            title="Stories"
            more="VIEW ALL STORIES"
            path="/stories"
          />

          <StoriesContainer>
            <MainWrapper>
              <Teaser
                storyMain
                badge="FREE"
                img={{
                  ...this.props.stories[0].avContent.featureImage,
                  sizes: '600px',
                }}
                title={this.props.stories[0].title}
                desc={this.props.stories[0].excerpt}
                categories={[this.props.stories[0].primaryCat.node]}
                url={`${this.props.stories[0].uri}`}
              />
            </MainWrapper>

            <RightWrapper>
              {this.props.stories.map(
                (teaser, index) =>
                  index !== 0 && (
                    <Teaser
                      homeStory
                      key={index}
                      story
                      categories={[teaser.primaryCat.node]}
                      img={{ ...teaser.avContent.featureImage, sizes: '600px' }}
                      title={teaser.title}
                      desc={teaser.excerpt}
                      url={`${teaser.uri}`}
                    />
                  )
              )}
            </RightWrapper>
          </StoriesContainer>
        </ComponentContainer>
      </div>
    )
  }
}

export default Stories
