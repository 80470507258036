import React from 'react'

import { Header, Attractions, Ballarat } from './styles/indexStyle'

const HeaderBtn = props => {
  return (
    <>
      <Header activeBtn={props.activeBtn}>
        <Attractions
          activeBtn={props.activeBtn}
          index={props.activeBtn}
          onClick={e => {
            e.preventDefault()
            props.handleClick('attractions')
          }}
        >
          OUR TOP ATTRACTIONS
        </Attractions>
        <Ballarat
          activeBtn={props.activeBtn}
          index={props.activeBtn}
          onClick={e => {
            e.preventDefault()
            props.handleClick('ballarat')
          }}
        >
          GETTING TO BALLARAT
        </Ballarat>
      </Header>
    </>
  )
}
export default HeaderBtn
