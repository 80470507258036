export default {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        fill: 'none',
        address: 'Melbourne',
        time: '1h 15m',
      },

      geometry: {
        coordinates: [
          [144.95481, -37.78666],
          [144.95468, -37.78655],
          [144.95467, -37.78645],
          [144.95469, -37.78634],
          [144.95478, -37.78623],
          [144.95488, -37.78616],
          [144.95494, -37.78614],
          [144.95503, -37.78614],
          [144.95512, -37.78616],
          [144.9552, -37.78619],
          [144.95526, -37.78624],
          [144.9553, -37.78629],
          [144.95533, -37.78635],
          [144.95533, -37.78642],
          [144.95533, -37.78649],
          [144.95532, -37.78661],
          [144.95531, -37.78668],
          [144.95529, -37.7867],
          [144.95526, -37.78673],
          [144.95516, -37.78676],
          [144.95496, -37.78684],
          [144.95494, -37.78685],
          [144.95493, -37.78687],
          [144.95492, -37.78689],
          [144.95492, -37.78692],
          [144.95474, -37.7881],
          [144.95437, -37.78806],
          [144.95365, -37.78799],
          [144.95274, -37.78788],
          [144.95204, -37.78783],
          [144.95189, -37.78782],
          [144.95187, -37.78782],
          [144.9517, -37.78781],
          [144.95156, -37.78781],
          [144.95141, -37.78781],
          [144.95125, -37.78782],
          [144.95116, -37.78782],
          [144.95103, -37.78784],
          [144.95094, -37.78786],
          [144.95084, -37.78789],
          [144.95069, -37.78793],
          [144.95058, -37.78798],
          [144.95034, -37.7881],
          [144.95026, -37.78815],
          [144.95019, -37.78819],
          [144.95007, -37.78826],
          [144.94991, -37.78836],
          [144.94986, -37.78839],
          [144.94981, -37.78842],
          [144.94977, -37.78844],
          [144.94974, -37.78845],
          [144.94961, -37.78852],
          [144.94937, -37.78864],
          [144.94918, -37.78873],
          [144.94892, -37.78884],
          [144.94855, -37.78898],
          [144.94812, -37.78912],
          [144.94805, -37.78914],
          [144.9478, -37.78922],
          [144.94728, -37.78935],
          [144.94701, -37.7894],
          [144.94653, -37.78948],
          [144.94607, -37.78953],
          [144.94568, -37.78955],
          [144.94563, -37.78956],
          [144.94522, -37.78957],
          [144.94484, -37.78958],
          [144.94447, -37.78957],
          [144.94409, -37.78954],
          [144.94374, -37.7895],
          [144.94344, -37.78948],
          [144.94316, -37.78947],
          [144.9427, -37.78945],
          [144.94265, -37.78945],
          [144.94239, -37.78945],
          [144.94228, -37.78945],
          [144.94221, -37.78945],
          [144.94214, -37.78944],
          [144.94204, -37.78944],
          [144.94199, -37.7894],
          [144.94195, -37.78937],
          [144.94185, -37.78929],
          [144.94176, -37.78924],
          [144.94144, -37.78897],
          [144.94116, -37.78875],
          [144.94097, -37.78859],
          [144.94084, -37.78846],
          [144.94079, -37.7884],
          [144.94068, -37.78828],
          [144.94052, -37.78807],
          [144.94046, -37.78799],
          [144.94031, -37.78772],
          [144.94018, -37.78756],
          [144.94002, -37.78736],
          [144.93992, -37.78723],
          [144.9397, -37.78694],
          [144.9392, -37.78638],
          [144.93914, -37.78629],
          [144.93914, -37.78624],
          [144.93918, -37.78611],
          [144.93919, -37.78607],
          [144.93921, -37.78598],
          [144.93924, -37.78592],
          [144.93924, -37.78592],
          [144.93935, -37.78556],
          [144.93946, -37.78514],
          [144.93946, -37.78473],
          [144.93941, -37.78429],
          [144.9394, -37.78362],
          [144.93941, -37.78295],
          [144.9394, -37.78257],
          [144.93938, -37.78244],
          [144.93934, -37.78209],
          [144.9392, -37.78133],
          [144.93908, -37.78097],
          [144.93891, -37.78052],
          [144.93874, -37.78017],
          [144.93841, -37.77958],
          [144.93809, -37.779],
          [144.93781, -37.77846],
          [144.93758, -37.77792],
          [144.93747, -37.77755],
          [144.93727, -37.77673],
          [144.93715, -37.7763],
          [144.93704, -37.77573],
          [144.93697, -37.77531],
          [144.93693, -37.77485],
          [144.93692, -37.77442],
          [144.93692, -37.77413],
          [144.93691, -37.7736],
          [144.9369, -37.77325],
          [144.93691, -37.77285],
          [144.93693, -37.77246],
          [144.93703, -37.77114],
          [144.93706, -37.77079],
          [144.93713, -37.76986],
          [144.93719, -37.76929],
          [144.93722, -37.76886],
          [144.93729, -37.76837],
          [144.93745, -37.76674],
          [144.93754, -37.76537],
          [144.93761, -37.76428],
          [144.93769, -37.76321],
          [144.93775, -37.76214],
          [144.93782, -37.76078],
          [144.93784, -37.76048],
          [144.93788, -37.7601],
          [144.9379, -37.75944],
          [144.9379, -37.75897],
          [144.93789, -37.75856],
          [144.93789, -37.75845],
          [144.93787, -37.75807],
          [144.93782, -37.75774],
          [144.93774, -37.75726],
          [144.93766, -37.75681],
          [144.93755, -37.75638],
          [144.93739, -37.75585],
          [144.93722, -37.75537],
          [144.93705, -37.75495],
          [144.93682, -37.75441],
          [144.93649, -37.75381],
          [144.93622, -37.75335],
          [144.93577, -37.7525],
          [144.9354, -37.75186],
          [144.93498, -37.75106],
          [144.9348, -37.75068],
          [144.93461, -37.75026],
          [144.93445, -37.74985],
          [144.93432, -37.74944],
          [144.93425, -37.74906],
          [144.93419, -37.74863],
          [144.93418, -37.748],
          [144.9342, -37.74746],
          [144.93426, -37.7465],
          [144.93426, -37.74599],
          [144.93423, -37.7456],
          [144.93412, -37.7451],
          [144.93401, -37.74469],
          [144.93388, -37.74439],
          [144.93375, -37.74406],
          [144.93359, -37.74374],
          [144.93342, -37.74345],
          [144.9332, -37.74312],
          [144.93298, -37.74283],
          [144.93271, -37.7425],
          [144.93238, -37.74212],
          [144.93173, -37.74138],
          [144.93153, -37.74111],
          [144.93134, -37.74083],
          [144.93113, -37.74053],
          [144.93099, -37.74021],
          [144.9309, -37.74002],
          [144.93085, -37.73989],
          [144.93075, -37.7396],
          [144.93062, -37.73918],
          [144.93034, -37.73821],
          [144.93024, -37.73794],
          [144.93012, -37.73773],
          [144.93, -37.73752],
          [144.92985, -37.7373],
          [144.92966, -37.73705],
          [144.92949, -37.73682],
          [144.92922, -37.73655],
          [144.9289, -37.73626],
          [144.92857, -37.73598],
          [144.92824, -37.73572],
          [144.92802, -37.73557],
          [144.92772, -37.73536],
          [144.92743, -37.73518],
          [144.92711, -37.73499],
          [144.92683, -37.73482],
          [144.92642, -37.7346],
          [144.92604, -37.73442],
          [144.92565, -37.73425],
          [144.92532, -37.73413],
          [144.92494, -37.73399],
          [144.92446, -37.73384],
          [144.92402, -37.73371],
          [144.92353, -37.7336],
          [144.92321, -37.73353],
          [144.9228, -37.73345],
          [144.92231, -37.73339],
          [144.92179, -37.73332],
          [144.92109, -37.73323],
          [144.91844, -37.73293],
          [144.91768, -37.73284],
          [144.9168, -37.73274],
          [144.91613, -37.73269],
          [144.9154, -37.73266],
          [144.91457, -37.73268],
          [144.9138, -37.73273],
          [144.91304, -37.73281],
          [144.91241, -37.7329],
          [144.91183, -37.73302],
          [144.91127, -37.73316],
          [144.91079, -37.7333],
          [144.91023, -37.73348],
          [144.90919, -37.73388],
          [144.90762, -37.73451],
          [144.90635, -37.73501],
          [144.90563, -37.73527],
          [144.90507, -37.73542],
          [144.9046, -37.73553],
          [144.90423, -37.73557],
          [144.90366, -37.73561],
          [144.90326, -37.73561],
          [144.90298, -37.73561],
          [144.90267, -37.73559],
          [144.89867, -37.73524],
          [144.89594, -37.73498],
          [144.8933, -37.73474],
          [144.89229, -37.73466],
          [144.8911, -37.73456],
          [144.89093, -37.73454],
          [144.89027, -37.73447],
          [144.88936, -37.73437],
          [144.88648, -37.73407],
          [144.88592, -37.73401],
          [144.88507, -37.73391],
          [144.88466, -37.73384],
          [144.88443, -37.7338],
          [144.88421, -37.73376],
          [144.88335, -37.73358],
          [144.88306, -37.73351],
          [144.88277, -37.73345],
          [144.8825, -37.73338],
          [144.88212, -37.73327],
          [144.88186, -37.73319],
          [144.8816, -37.73311],
          [144.88127, -37.73301],
          [144.88083, -37.73287],
          [144.87966, -37.73242],
          [144.8764, -37.73116],
          [144.87582, -37.73095],
          [144.87519, -37.73075],
          [144.87463, -37.73059],
          [144.87399, -37.73043],
          [144.87225, -37.73002],
          [144.8714, -37.72982],
          [144.86987, -37.72948],
          [144.86883, -37.72926],
          [144.86764, -37.72902],
          [144.86707, -37.72892],
          [144.86578, -37.72876],
          [144.86466, -37.72863],
          [144.8644, -37.72861],
          [144.86413, -37.72859],
          [144.86378, -37.72857],
          [144.86368, -37.72856],
          [144.86356, -37.72855],
          [144.86346, -37.72855],
          [144.86337, -37.72855],
          [144.86328, -37.72855],
          [144.86304, -37.72857],
          [144.86289, -37.72858],
          [144.86272, -37.7286],
          [144.86246, -37.72864],
          [144.8617, -37.72881],
          [144.86027, -37.72912],
          [144.85971, -37.72924],
          [144.85901, -37.72937],
          [144.85865, -37.72943],
          [144.85835, -37.72951],
          [144.85812, -37.72958],
          [144.85791, -37.72965],
          [144.8577, -37.72973],
          [144.85752, -37.72982],
          [144.85729, -37.72993],
          [144.8571, -37.73004],
          [144.8565, -37.7304],
          [144.85609, -37.73069],
          [144.85504, -37.73152],
          [144.8545, -37.73208],
          [144.8542, -37.73245],
          [144.85399, -37.73276],
          [144.85378, -37.73311],
          [144.85353, -37.73357],
          [144.85337, -37.73391],
          [144.85319, -37.7344],
          [144.85296, -37.73509],
          [144.85279, -37.73555],
          [144.85262, -37.73602],
          [144.85245, -37.73644],
          [144.85225, -37.73683],
          [144.85214, -37.73702],
          [144.85198, -37.73728],
          [144.85124, -37.73826],
          [144.85046, -37.73927],
          [144.85006, -37.73973],
          [144.84957, -37.74026],
          [144.84906, -37.74071],
          [144.84846, -37.74125],
          [144.8479, -37.74166],
          [144.84757, -37.7419],
          [144.84719, -37.74215],
          [144.84684, -37.74238],
          [144.84642, -37.74264],
          [144.84566, -37.74306],
          [144.84476, -37.74353],
          [144.84195, -37.74495],
          [144.84043, -37.74571],
          [144.83892, -37.74655],
          [144.83622, -37.74824],
          [144.83592, -37.74844],
          [144.83492, -37.74903],
          [144.83262, -37.7505],
          [144.8305, -37.75196],
          [144.82841, -37.75345],
          [144.82652, -37.75484],
          [144.82556, -37.75552],
          [144.82461, -37.7562],
          [144.82416, -37.75659],
          [144.8237, -37.75701],
          [144.8232, -37.75751],
          [144.82277, -37.75801],
          [144.82257, -37.75829],
          [144.82237, -37.75862],
          [144.82222, -37.75888],
          [144.822, -37.75934],
          [144.82154, -37.76025],
          [144.82105, -37.76116],
          [144.82075, -37.76167],
          [144.82037, -37.76221],
          [144.81992, -37.76282],
          [144.81953, -37.7633],
          [144.81894, -37.76392],
          [144.81815, -37.7647],
          [144.81779, -37.76502],
          [144.81727, -37.7655],
          [144.81703, -37.7657],
          [144.81677, -37.76592],
          [144.81654, -37.76609],
          [144.81634, -37.76623],
          [144.81614, -37.76638],
          [144.81592, -37.76653],
          [144.81562, -37.76674],
          [144.81531, -37.76693],
          [144.81462, -37.76727],
          [144.81411, -37.76749],
          [144.8136, -37.76767],
          [144.81285, -37.76789],
          [144.81235, -37.76803],
          [144.81184, -37.76813],
          [144.81122, -37.76823],
          [144.81061, -37.76831],
          [144.80997, -37.76834],
          [144.80933, -37.76834],
          [144.80869, -37.76831],
          [144.80815, -37.76826],
          [144.8075, -37.76817],
          [144.80686, -37.76805],
          [144.80635, -37.76794],
          [144.80576, -37.76781],
          [144.80488, -37.7676],
          [144.80434, -37.76748],
          [144.80374, -37.7674],
          [144.8032, -37.76735],
          [144.8027, -37.76732],
          [144.802, -37.76733],
          [144.80135, -37.76738],
          [144.80068, -37.76748],
          [144.80047, -37.76752],
          [144.80042, -37.76753],
          [144.80006, -37.7676],
          [144.7995, -37.76775],
          [144.79895, -37.76794],
          [144.79853, -37.76809],
          [144.79806, -37.7683],
          [144.79763, -37.76854],
          [144.79729, -37.76874],
          [144.79681, -37.76908],
          [144.7964, -37.76939],
          [144.79606, -37.7697],
          [144.79575, -37.77001],
          [144.79552, -37.7703],
          [144.79533, -37.77053],
          [144.79509, -37.77089],
          [144.7949, -37.77122],
          [144.79472, -37.77153],
          [144.79462, -37.77174],
          [144.79446, -37.77214],
          [144.79431, -37.77251],
          [144.79423, -37.77282],
          [144.79416, -37.7732],
          [144.79412, -37.77359],
          [144.79413, -37.77409],
          [144.79415, -37.77451],
          [144.79421, -37.7751],
          [144.79427, -37.77572],
          [144.79427, -37.77632],
          [144.79421, -37.777],
          [144.79414, -37.7775],
          [144.79403, -37.77796],
          [144.79386, -37.77846],
          [144.79362, -37.77892],
          [144.79338, -37.77933],
          [144.79298, -37.77987],
          [144.79163, -37.78154],
          [144.79131, -37.78197],
          [144.79046, -37.78326],
          [144.79016, -37.78377],
          [144.78993, -37.78418],
          [144.78951, -37.78498],
          [144.78912, -37.78581],
          [144.78883, -37.78652],
          [144.78859, -37.78719],
          [144.78854, -37.78747],
          [144.78847, -37.78774],
          [144.78845, -37.78785],
          [144.78834, -37.78837],
          [144.7883, -37.78855],
          [144.78815, -37.78949],
          [144.78813, -37.78962],
          [144.78804, -37.79023],
          [144.78802, -37.79044],
          [144.78802, -37.79109],
          [144.78806, -37.79133],
          [144.78811, -37.79163],
          [144.78821, -37.79205],
          [144.78829, -37.79236],
          [144.78839, -37.79282],
          [144.78842, -37.79299],
          [144.78844, -37.79322],
          [144.78846, -37.79343],
          [144.78846, -37.79376],
          [144.78845, -37.79398],
          [144.78843, -37.7942],
          [144.7884, -37.79442],
          [144.78836, -37.79464],
          [144.78832, -37.79487],
          [144.78826, -37.79507],
          [144.78823, -37.79518],
          [144.7881, -37.79551],
          [144.78796, -37.79582],
          [144.7878, -37.79612],
          [144.78768, -37.79631],
          [144.78747, -37.79665],
          [144.78717, -37.79704],
          [144.78673, -37.79755],
          [144.78648, -37.7978],
          [144.78604, -37.79821],
          [144.78548, -37.79857],
          [144.7848, -37.79898],
          [144.78421, -37.79923],
          [144.78358, -37.79946],
          [144.7831, -37.7996],
          [144.78274, -37.7997],
          [144.78222, -37.79981],
          [144.78155, -37.7999],
          [144.78098, -37.79994],
          [144.78063, -37.79996],
          [144.78027, -37.79995],
          [144.77978, -37.79993],
          [144.77898, -37.79984],
          [144.77791, -37.79962],
          [144.77725, -37.79943],
          [144.77652, -37.79925],
          [144.7758, -37.79912],
          [144.77505, -37.799],
          [144.7742, -37.79886],
          [144.77277, -37.79869],
          [144.77209, -37.79863],
          [144.77182, -37.79858],
          [144.77079, -37.79849],
          [144.76813, -37.79828],
          [144.765, -37.79802],
          [144.75886, -37.79754],
          [144.75841, -37.7975],
          [144.75829, -37.79749],
          [144.75765, -37.79739],
          [144.75671, -37.79719],
          [144.75582, -37.79693],
          [144.75533, -37.79677],
          [144.75448, -37.79641],
          [144.75392, -37.79615],
          [144.75303, -37.79565],
          [144.75255, -37.79532],
          [144.75205, -37.79496],
          [144.75145, -37.79445],
          [144.75102, -37.79404],
          [144.75065, -37.79364],
          [144.75022, -37.79311],
          [144.74994, -37.79272],
          [144.74974, -37.79237],
          [144.74953, -37.79197],
          [144.74923, -37.79135],
          [144.74892, -37.79049],
          [144.74876, -37.78969],
          [144.74848, -37.78785],
          [144.74809, -37.78529],
          [144.74777, -37.78319],
          [144.74741, -37.7808],
          [144.74738, -37.7806],
          [144.74705, -37.77843],
          [144.74667, -37.77596],
          [144.7463, -37.77338],
          [144.74627, -37.77323],
          [144.74617, -37.77251],
          [144.74608, -37.77209],
          [144.74605, -37.77194],
          [144.74598, -37.77159],
          [144.74582, -37.77105],
          [144.7456, -37.77042],
          [144.74549, -37.77015],
          [144.74542, -37.76998],
          [144.74528, -37.76967],
          [144.74515, -37.76943],
          [144.74495, -37.76906],
          [144.74472, -37.76868],
          [144.74447, -37.76831],
          [144.74412, -37.76782],
          [144.74352, -37.76711],
          [144.74287, -37.76634],
          [144.74239, -37.76573],
          [144.74194, -37.76513],
          [144.74097, -37.76387],
          [144.74019, -37.76284],
          [144.73865, -37.76079],
          [144.73715, -37.75884],
          [144.73604, -37.75739],
          [144.73548, -37.75668],
          [144.73495, -37.75611],
          [144.73468, -37.75584],
          [144.73421, -37.75543],
          [144.73364, -37.75497],
          [144.73312, -37.7546],
          [144.73254, -37.75422],
          [144.7319, -37.75384],
          [144.73135, -37.75356],
          [144.73083, -37.7533],
          [144.73041, -37.75313],
          [144.72982, -37.75289],
          [144.72918, -37.75267],
          [144.72814, -37.75235],
          [144.72709, -37.75202],
          [144.72564, -37.75158],
          [144.72503, -37.75137],
          [144.72456, -37.75121],
          [144.72417, -37.75107],
          [144.72394, -37.751],
          [144.72371, -37.75093],
          [144.72328, -37.75082],
          [144.72303, -37.75076],
          [144.72238, -37.75064],
          [144.72093, -37.75036],
          [144.71931, -37.75006],
          [144.71769, -37.74976],
          [144.71647, -37.74953],
          [144.71557, -37.74934],
          [144.71349, -37.74896],
          [144.71207, -37.74869],
          [144.71078, -37.74845],
          [144.70972, -37.74825],
          [144.70887, -37.7481],
          [144.70709, -37.74777],
          [144.70655, -37.74767],
          [144.70613, -37.74757],
          [144.70572, -37.74748],
          [144.70545, -37.74741],
          [144.70513, -37.74731],
          [144.70488, -37.74723],
          [144.70443, -37.74706],
          [144.70341, -37.74667],
          [144.70257, -37.74633],
          [144.70075, -37.74563],
          [144.69979, -37.74525],
          [144.6992, -37.74502],
          [144.69782, -37.74448],
          [144.69461, -37.74323],
          [144.69275, -37.74249],
          [144.6913, -37.74194],
          [144.69092, -37.74178],
          [144.68945, -37.74118],
          [144.68798, -37.74063],
          [144.68727, -37.74036],
          [144.6868, -37.74019],
          [144.68636, -37.74005],
          [144.68581, -37.73989],
          [144.68533, -37.73975],
          [144.68359, -37.73926],
          [144.6831, -37.73911],
          [144.68234, -37.73886],
          [144.68113, -37.73847],
          [144.68003, -37.7381],
          [144.67924, -37.73784],
          [144.67756, -37.73728],
          [144.67625, -37.73683],
          [144.67582, -37.73669],
          [144.6748, -37.73637],
          [144.67465, -37.73632],
          [144.6729, -37.73572],
          [144.67193, -37.73542],
          [144.67175, -37.73535],
          [144.67124, -37.73516],
          [144.6709, -37.73501],
          [144.67048, -37.73481],
          [144.67, -37.73456],
          [144.66968, -37.73438],
          [144.66516, -37.73172],
          [144.66066, -37.72907],
          [144.65975, -37.72855],
          [144.6576, -37.72728],
          [144.65705, -37.72695],
          [144.65617, -37.72641],
          [144.65465, -37.7255],
          [144.65304, -37.72459],
          [144.65172, -37.72378],
          [144.64968, -37.72259],
          [144.64723, -37.72117],
          [144.64597, -37.72041],
          [144.64474, -37.71956],
          [144.64125, -37.71705],
          [144.6374, -37.7143],
          [144.63735, -37.71426],
          [144.63724, -37.71418],
          [144.6371, -37.71409],
          [144.63683, -37.71389],
          [144.63505, -37.7126],
          [144.63047, -37.7093],
          [144.62287, -37.7039],
          [144.62109, -37.70262],
          [144.61968, -37.70161],
          [144.61798, -37.70039],
          [144.61594, -37.69892],
          [144.61522, -37.69842],
          [144.61484, -37.69817],
          [144.61433, -37.69789],
          [144.61386, -37.69765],
          [144.61341, -37.69742],
          [144.61299, -37.69723],
          [144.61252, -37.69703],
          [144.612, -37.69683],
          [144.61155, -37.69667],
          [144.61123, -37.69657],
          [144.6109, -37.69647],
          [144.61051, -37.69635],
          [144.61012, -37.69626],
          [144.60979, -37.69618],
          [144.60947, -37.6961],
          [144.60907, -37.69602],
          [144.60876, -37.69597],
          [144.60844, -37.69591],
          [144.60811, -37.69586],
          [144.60784, -37.69583],
          [144.60738, -37.69577],
          [144.60664, -37.69571],
          [144.60626, -37.69569],
          [144.60572, -37.69567],
          [144.60503, -37.69564],
          [144.60391, -37.69559],
          [144.60267, -37.69555],
          [144.60159, -37.69551],
          [144.60077, -37.69548],
          [144.59972, -37.69545],
          [144.59568, -37.69531],
          [144.59278, -37.69522],
          [144.59095, -37.69515],
          [144.58979, -37.69508],
          [144.58876, -37.69499],
          [144.58798, -37.69489],
          [144.58724, -37.69477],
          [144.58659, -37.69465],
          [144.58596, -37.69451],
          [144.58546, -37.69439],
          [144.5851, -37.6943],
          [144.58427, -37.69407],
          [144.58367, -37.6939],
          [144.58306, -37.69371],
          [144.58263, -37.69354],
          [144.58195, -37.69329],
          [144.58114, -37.69297],
          [144.57981, -37.69251],
          [144.57868, -37.69215],
          [144.5772, -37.69174],
          [144.57511, -37.69135],
          [144.57392, -37.6912],
          [144.57315, -37.69111],
          [144.56811, -37.6905],
          [144.56649, -37.6903],
          [144.56486, -37.6901],
          [144.56179, -37.68979],
          [144.56141, -37.68975],
          [144.5591, -37.6895],
          [144.55486, -37.68908],
          [144.54651, -37.68826],
          [144.53897, -37.68749],
          [144.53512, -37.68709],
          [144.53397, -37.68697],
          [144.53279, -37.68691],
          [144.53228, -37.68688],
          [144.5317, -37.68685],
          [144.53052, -37.6869],
          [144.53001, -37.68697],
          [144.52935, -37.68705],
          [144.52857, -37.68717],
          [144.52799, -37.68728],
          [144.52727, -37.68744],
          [144.52686, -37.68753],
          [144.52606, -37.68778],
          [144.52563, -37.68794],
          [144.52507, -37.68814],
          [144.5245, -37.68837],
          [144.52388, -37.68862],
          [144.5233, -37.68884],
          [144.52284, -37.68899],
          [144.52232, -37.68915],
          [144.52182, -37.68929],
          [144.52126, -37.68941],
          [144.52055, -37.68954],
          [144.51968, -37.68966],
          [144.51784, -37.68987],
          [144.51595, -37.69008],
          [144.51433, -37.69024],
          [144.51383, -37.69029],
          [144.51315, -37.69033],
          [144.51249, -37.69034],
          [144.51177, -37.69029],
          [144.51132, -37.69025],
          [144.51038, -37.69011],
          [144.50932, -37.68989],
          [144.50813, -37.68954],
          [144.50757, -37.68933],
          [144.50715, -37.68915],
          [144.50614, -37.6887],
          [144.50513, -37.68825],
          [144.50392, -37.68773],
          [144.50287, -37.68725],
          [144.50244, -37.68707],
          [144.50196, -37.6869],
          [144.50088, -37.68653],
          [144.50019, -37.68634],
          [144.4994, -37.68614],
          [144.49886, -37.68602],
          [144.4983, -37.68592],
          [144.49785, -37.68585],
          [144.49687, -37.68574],
          [144.496, -37.68563],
          [144.49539, -37.68554],
          [144.49504, -37.6855],
          [144.49459, -37.68547],
          [144.4942, -37.68546],
          [144.49374, -37.68545],
          [144.49337, -37.68546],
          [144.49225, -37.68548],
          [144.49145, -37.68549],
          [144.49018, -37.68552],
          [144.48965, -37.68552],
          [144.48929, -37.68552],
          [144.48896, -37.68552],
          [144.4874, -37.68556],
          [144.48642, -37.68557],
          [144.48488, -37.68559],
          [144.48323, -37.6856],
          [144.4827, -37.68561],
          [144.48226, -37.68561],
          [144.48193, -37.68561],
          [144.48157, -37.6856],
          [144.48122, -37.68558],
          [144.48084, -37.68556],
          [144.48042, -37.68552],
          [144.47996, -37.68548],
          [144.47978, -37.68546],
          [144.47946, -37.68542],
          [144.47903, -37.68536],
          [144.47861, -37.6853],
          [144.47819, -37.68523],
          [144.47762, -37.68512],
          [144.47728, -37.68505],
          [144.47692, -37.68497],
          [144.47657, -37.68489],
          [144.47625, -37.6848],
          [144.47554, -37.68461],
          [144.47499, -37.68446],
          [144.4743, -37.68423],
          [144.4735, -37.68393],
          [144.47289, -37.6837],
          [144.47231, -37.68345],
          [144.47069, -37.68267],
          [144.46971, -37.68206],
          [144.46903, -37.68152],
          [144.46822, -37.68071],
          [144.4677, -37.68004],
          [144.46723, -37.67924],
          [144.4669, -37.67852],
          [144.46675, -37.67811],
          [144.46663, -37.67753],
          [144.46656, -37.67714],
          [144.4665, -37.67671],
          [144.46648, -37.67628],
          [144.46647, -37.67585],
          [144.46648, -37.67529],
          [144.46648, -37.67489],
          [144.46647, -37.67454],
          [144.46644, -37.67411],
          [144.46641, -37.6738],
          [144.46636, -37.67348],
          [144.46629, -37.67316],
          [144.4662, -37.67284],
          [144.46613, -37.67264],
          [144.46607, -37.67248],
          [144.466, -37.67232],
          [144.46592, -37.67213],
          [144.46575, -37.67183],
          [144.46563, -37.67158],
          [144.46534, -37.67115],
          [144.4652, -37.67089],
          [144.46498, -37.67068],
          [144.46457, -37.67019],
          [144.46418, -37.66979],
          [144.46363, -37.66931],
          [144.46284, -37.66878],
          [144.46225, -37.66842],
          [144.4618, -37.66816],
          [144.46137, -37.66796],
          [144.461, -37.66781],
          [144.46065, -37.66769],
          [144.46024, -37.66756],
          [144.45977, -37.66743],
          [144.45938, -37.66735],
          [144.45891, -37.66726],
          [144.45833, -37.66717],
          [144.45779, -37.6671],
          [144.45236, -37.6665],
          [144.45204, -37.66646],
          [144.45166, -37.6664],
          [144.45133, -37.66634],
          [144.45092, -37.66626],
          [144.45063, -37.66619],
          [144.45017, -37.66606],
          [144.44971, -37.66591],
          [144.44921, -37.66572],
          [144.44859, -37.66546],
          [144.44784, -37.66516],
          [144.44748, -37.66502],
          [144.44704, -37.66487],
          [144.44649, -37.66471],
          [144.44589, -37.66458],
          [144.44554, -37.66451],
          [144.44519, -37.66446],
          [144.44471, -37.66439],
          [144.44425, -37.66434],
          [144.44351, -37.66425],
          [144.43671, -37.66353],
          [144.43561, -37.66344],
          [144.4349, -37.6634],
          [144.43421, -37.66336],
          [144.43365, -37.66334],
          [144.43329, -37.66333],
          [144.43266, -37.66333],
          [144.43187, -37.66333],
          [144.43132, -37.66334],
          [144.43062, -37.66337],
          [144.43016, -37.66338],
          [144.42978, -37.6634],
          [144.42833, -37.6635],
          [144.42702, -37.66367],
          [144.42579, -37.66383],
          [144.42558, -37.66385],
          [144.42032, -37.66456],
          [144.41858, -37.66474],
          [144.41688, -37.66482],
          [144.4146, -37.6648],
          [144.41406, -37.6648],
          [144.41363, -37.66481],
          [144.41323, -37.66483],
          [144.41284, -37.66485],
          [144.41231, -37.66488],
          [144.41176, -37.66493],
          [144.41125, -37.66499],
          [144.41065, -37.66508],
          [144.40952, -37.66527],
          [144.40871, -37.66543],
          [144.40727, -37.66584],
          [144.40649, -37.66599],
          [144.40525, -37.66618],
          [144.40454, -37.66624],
          [144.40362, -37.66626],
          [144.40272, -37.66622],
          [144.40193, -37.66612],
          [144.40106, -37.66597],
          [144.4002, -37.66576],
          [144.3986, -37.66527],
          [144.3974, -37.66495],
          [144.39631, -37.6647],
          [144.39517, -37.66451],
          [144.39389, -37.66434],
          [144.39306, -37.66427],
          [144.39242, -37.66423],
          [144.39197, -37.66421],
          [144.39169, -37.6642],
          [144.3913, -37.6642],
          [144.3909, -37.6642],
          [144.39053, -37.66421],
          [144.3902, -37.66421],
          [144.38986, -37.66422],
          [144.38949, -37.66423],
          [144.38921, -37.66425],
          [144.38889, -37.66427],
          [144.38863, -37.6643],
          [144.38832, -37.66433],
          [144.38801, -37.66436],
          [144.38766, -37.6644],
          [144.38719, -37.66446],
          [144.38669, -37.66452],
          [144.38383, -37.66473],
          [144.38261, -37.66473],
          [144.3813, -37.66453],
          [144.37975, -37.66413],
          [144.37841, -37.6636],
          [144.37749, -37.66313],
          [144.37648, -37.66249],
          [144.37563, -37.66178],
          [144.37312, -37.65882],
          [144.36947, -37.65386],
          [144.36868, -37.65272],
          [144.36785, -37.6514],
          [144.36761, -37.65084],
          [144.36734, -37.65009],
          [144.36708, -37.64907],
          [144.36694, -37.64818],
          [144.36697, -37.6474],
          [144.36697, -37.64715],
          [144.36698, -37.64693],
          [144.36699, -37.64673],
          [144.36709, -37.64452],
          [144.367, -37.64343],
          [144.36685, -37.64262],
          [144.36659, -37.64182],
          [144.36625, -37.64101],
          [144.36572, -37.64005],
          [144.36501, -37.63906],
          [144.36436, -37.63828],
          [144.36426, -37.63818],
          [144.36402, -37.63795],
          [144.36373, -37.63769],
          [144.36352, -37.63751],
          [144.36328, -37.63732],
          [144.36308, -37.63717],
          [144.36256, -37.63681],
          [144.36162, -37.63625],
          [144.36099, -37.6359],
          [144.35988, -37.63542],
          [144.35852, -37.63493],
          [144.35695, -37.63445],
          [144.3548, -37.63381],
          [144.35305, -37.63326],
          [144.35201, -37.63294],
          [144.35138, -37.63271],
          [144.35, -37.6322],
          [144.34867, -37.63152],
          [144.34766, -37.63088],
          [144.34671, -37.63012],
          [144.3459, -37.6294],
          [144.34518, -37.6286],
          [144.34452, -37.62765],
          [144.34237, -37.62423],
          [144.34108, -37.62205],
          [144.34049, -37.62112],
          [144.33997, -37.62033],
          [144.33917, -37.61936],
          [144.33869, -37.6188],
          [144.33809, -37.61816],
          [144.33708, -37.61722],
          [144.33672, -37.6169],
          [144.33591, -37.61623],
          [144.33461, -37.6153],
          [144.33277, -37.6142],
          [144.33127, -37.61343],
          [144.32657, -37.61132],
          [144.32475, -37.61051],
          [144.32411, -37.61022],
          [144.32336, -37.6099],
          [144.32285, -37.60969],
          [144.3226, -37.6096],
          [144.32226, -37.60947],
          [144.32199, -37.60938],
          [144.32171, -37.60931],
          [144.32148, -37.60924],
          [144.32121, -37.60918],
          [144.32093, -37.60912],
          [144.32068, -37.60907],
          [144.32011, -37.60897],
          [144.31989, -37.60894],
          [144.31956, -37.60891],
          [144.3192, -37.60888],
          [144.31872, -37.60885],
          [144.31835, -37.60885],
          [144.31819, -37.60884],
          [144.31778, -37.60886],
          [144.31751, -37.60887],
          [144.3171, -37.60891],
          [144.31676, -37.60895],
          [144.31634, -37.60901],
          [144.31603, -37.60906],
          [144.31564, -37.60914],
          [144.3152, -37.60923],
          [144.31394, -37.60951],
          [144.3121, -37.60993],
          [144.30808, -37.61082],
          [144.30262, -37.61204],
          [144.30195, -37.61217],
          [144.30155, -37.61225],
          [144.30114, -37.61231],
          [144.30073, -37.61235],
          [144.30045, -37.61237],
          [144.30013, -37.61238],
          [144.29977, -37.61237],
          [144.29927, -37.61234],
          [144.29878, -37.61228],
          [144.29827, -37.61219],
          [144.29773, -37.61206],
          [144.2973, -37.61193],
          [144.29693, -37.61179],
          [144.29659, -37.61165],
          [144.29629, -37.6115],
          [144.29601, -37.61134],
          [144.29569, -37.61115],
          [144.29536, -37.61094],
          [144.29438, -37.61033],
          [144.29417, -37.61021],
          [144.29376, -37.60996],
          [144.29339, -37.60976],
          [144.29304, -37.60959],
          [144.29264, -37.6094],
          [144.29217, -37.60917],
          [144.29177, -37.60896],
          [144.2915, -37.6088],
          [144.29132, -37.60869],
          [144.291, -37.60847],
          [144.29078, -37.6083],
          [144.29063, -37.60819],
          [144.29037, -37.60798],
          [144.28885, -37.60679],
          [144.28842, -37.6065],
          [144.28811, -37.60631],
          [144.28783, -37.60617],
          [144.28743, -37.60598],
          [144.28695, -37.60576],
          [144.28661, -37.6056],
          [144.28631, -37.60543],
          [144.28605, -37.60527],
          [144.28575, -37.60507],
          [144.28547, -37.60488],
          [144.28492, -37.60451],
          [144.28458, -37.60429],
          [144.28429, -37.60412],
          [144.28404, -37.604],
          [144.28378, -37.6039],
          [144.2835, -37.6038],
          [144.28319, -37.60371],
          [144.28294, -37.60365],
          [144.28266, -37.6036],
          [144.28237, -37.60356],
          [144.28196, -37.60352],
          [144.2815, -37.60347],
          [144.28101, -37.60341],
          [144.28043, -37.6033],
          [144.27991, -37.60319],
          [144.27938, -37.60304],
          [144.27767, -37.60259],
          [144.27539, -37.60198],
          [144.27276, -37.60128],
          [144.27014, -37.60058],
          [144.26918, -37.60033],
          [144.26793, -37.6],
          [144.26676, -37.59968],
          [144.26435, -37.59901],
          [144.26247, -37.59851],
          [144.2587, -37.59751],
          [144.25739, -37.59717],
          [144.25542, -37.59664],
          [144.25453, -37.59636],
          [144.25347, -37.59598],
          [144.25302, -37.59578],
          [144.25193, -37.59531],
          [144.251, -37.59482],
          [144.24969, -37.59408],
          [144.24827, -37.59331],
          [144.24689, -37.59264],
          [144.2462, -37.59237],
          [144.24526, -37.59202],
          [144.24423, -37.5917],
          [144.24324, -37.59144],
          [144.24233, -37.59123],
          [144.24172, -37.59112],
          [144.24094, -37.591],
          [144.23964, -37.59084],
          [144.23906, -37.59078],
          [144.23811, -37.59068],
          [144.21969, -37.58869],
          [144.21732, -37.58849],
          [144.21534, -37.58837],
          [144.21375, -37.58831],
          [144.21226, -37.58831],
          [144.21174, -37.58831],
          [144.21082, -37.5883],
          [144.2085, -37.58837],
          [144.20689, -37.58848],
          [144.2044, -37.5887],
          [144.2027, -37.58889],
          [144.19863, -37.5893],
          [144.19645, -37.58951],
          [144.19458, -37.58972],
          [144.1897, -37.59023],
          [144.18203, -37.59103],
          [144.18187, -37.59105],
          [144.18166, -37.59107],
          [144.1794, -37.59131],
          [144.17908, -37.59134],
          [144.17628, -37.59163],
          [144.1749, -37.59174],
          [144.17342, -37.59176],
          [144.17172, -37.59163],
          [144.16583, -37.59101],
          [144.16324, -37.59072],
          [144.16208, -37.5905],
          [144.16111, -37.59023],
          [144.15983, -37.58982],
          [144.15866, -37.58932],
          [144.15766, -37.58883],
          [144.15501, -37.58717],
          [144.14806, -37.58285],
          [144.14657, -37.58191],
          [144.14572, -37.58144],
          [144.1447, -37.58092],
          [144.14356, -37.58038],
          [144.13998, -37.57895],
          [144.11727, -37.5699],
          [144.11585, -37.56942],
          [144.11485, -37.56917],
          [144.11374, -37.56897],
          [144.11283, -37.56889],
          [144.11144, -37.56883],
          [144.10825, -37.56887],
          [144.10694, -37.56882],
          [144.10586, -37.56869],
          [144.10472, -37.56844],
          [144.10338, -37.56807],
          [144.10172, -37.5674],
          [144.10063, -37.56687],
          [144.09143, -37.56257],
          [144.08795, -37.56094],
          [144.07808, -37.55545],
          [144.07566, -37.55416],
          [144.07453, -37.55362],
          [144.07355, -37.55318],
          [144.07151, -37.5524],
          [144.0701, -37.55195],
          [144.06871, -37.55155],
          [144.06717, -37.55116],
          [144.06573, -37.55084],
          [144.06428, -37.55057],
          [144.06289, -37.55037],
          [144.06143, -37.5502],
          [144.05988, -37.55009],
          [144.05802, -37.54998],
          [144.05742, -37.54998],
          [144.05561, -37.55],
          [144.04829, -37.55035],
          [144.04621, -37.55044],
          [144.04477, -37.55051],
          [144.04316, -37.55059],
          [144.04096, -37.55066],
          [144.0393, -37.55064],
          [144.03765, -37.55057],
          [144.03453, -37.55024],
          [144.03126, -37.54977],
          [144.02984, -37.54963],
          [144.02854, -37.54956],
          [144.02732, -37.54954],
          [144.02453, -37.54973],
          [144.02083, -37.55022],
          [144.01624, -37.55105],
          [144.01231, -37.55204],
          [144.01087, -37.55245],
          [144.01015, -37.55263],
          [144.00936, -37.5528],
          [144.00847, -37.55296],
          [144.00771, -37.55305],
          [144.00708, -37.55311],
          [144.00635, -37.55314],
          [144.00572, -37.55317],
          [144.00511, -37.55316],
          [144.00427, -37.55315],
          [144.00364, -37.55311],
          [144.00281, -37.55303],
          [144.00235, -37.55298],
          [143.99972, -37.55265],
          [143.99792, -37.55247],
          [143.9972, -37.55241],
          [143.99627, -37.55234],
          [143.99476, -37.55228],
          [143.99344, -37.55223],
          [143.99215, -37.55223],
          [143.9909, -37.55224],
          [143.98985, -37.55225],
          [143.98851, -37.5523],
          [143.98728, -37.55237],
          [143.98659, -37.55241],
          [143.98529, -37.55253],
          [143.98394, -37.55265],
          [143.98274, -37.5528],
          [143.9817, -37.55294],
          [143.98048, -37.55311],
          [143.97894, -37.55338],
          [143.97721, -37.55371],
          [143.97555, -37.55407],
          [143.97274, -37.55471],
          [143.96926, -37.5555],
          [143.96846, -37.55569],
          [143.96728, -37.55595],
          [143.96713, -37.55599],
          [143.96357, -37.55686],
          [143.96059, -37.55759],
          [143.95848, -37.55812],
          [143.9582, -37.55819],
          [143.95743, -37.55838],
          [143.95347, -37.55936],
          [143.9499, -37.56025],
          [143.94468, -37.56153],
          [143.9408, -37.56249],
          [143.93843, -37.56309],
          [143.9373, -37.56338],
          [143.9372, -37.56341],
          [143.93701, -37.56345],
          [143.93618, -37.56365],
          [143.93591, -37.56371],
          [143.93552, -37.56379],
          [143.93523, -37.56383],
          [143.93491, -37.56387],
          [143.9346, -37.56388],
          [143.93434, -37.56389],
          [143.93413, -37.56389],
          [143.93395, -37.56388],
          [143.93374, -37.56387],
          [143.93351, -37.56384],
          [143.93327, -37.56382],
          [143.93304, -37.56378],
          [143.93281, -37.56374],
          [143.93263, -37.5637],
          [143.93237, -37.56364],
          [143.93212, -37.56357],
          [143.93185, -37.56349],
          [143.9316, -37.56342],
          [143.9313, -37.56332],
          [143.93086, -37.56319],
          [143.92996, -37.56292],
          [143.92581, -37.56166],
          [143.92566, -37.56162],
          [143.92518, -37.56147],
          [143.92399, -37.56112],
          [143.92296, -37.5608],
          [143.92231, -37.56061],
          [143.92175, -37.56044],
          [143.92133, -37.56032],
          [143.92048, -37.56009],
          [143.91962, -37.55987],
          [143.91921, -37.55976],
          [143.91887, -37.55967],
          [143.91851, -37.55956],
          [143.91827, -37.55947],
          [143.91807, -37.55939],
          [143.91786, -37.5593],
          [143.91766, -37.5592],
          [143.91746, -37.5591],
          [143.91724, -37.55897],
          [143.917, -37.55883],
          [143.91683, -37.55871],
          [143.91663, -37.55857],
          [143.91554, -37.55778],
          [143.91418, -37.55678],
          [143.91331, -37.55607],
          [143.91297, -37.55577],
          [143.91253, -37.55535],
          [143.9121, -37.55493],
          [143.91147, -37.55419],
          [143.91114, -37.55377],
          [143.91084, -37.55335],
          [143.91014, -37.55247],
          [143.90974, -37.552],
          [143.90931, -37.55155],
          [143.90899, -37.55126],
          [143.90853, -37.55091],
          [143.90805, -37.55055],
          [143.90754, -37.55022],
          [143.90729, -37.55009],
          [143.90689, -37.54988],
          [143.90627, -37.54957],
          [143.90493, -37.54906],
          [143.90406, -37.54882],
          [143.90356, -37.5487],
          [143.90315, -37.54863],
          [143.90226, -37.54848],
          [143.9002, -37.54817],
          [143.89929, -37.548],
          [143.89851, -37.54782],
          [143.8977, -37.5476],
          [143.89696, -37.54736],
          [143.89599, -37.54698],
          [143.89502, -37.54658],
          [143.89404, -37.54607],
          [143.89309, -37.54553],
          [143.89154, -37.54453],
          [143.89037, -37.54377],
          [143.88954, -37.54322],
          [143.88835, -37.54254],
          [143.88776, -37.54223],
          [143.88477, -37.5407],
          [143.88241, -37.53948],
          [143.88024, -37.53817],
          [143.87883, -37.53721],
          [143.87741, -37.53616],
          [143.87647, -37.5354],
          [143.87566, -37.53468],
          [143.87393, -37.53308],
          [143.87149, -37.53039],
          [143.86999, -37.52859],
          [143.86974, -37.52826],
          [143.86945, -37.52789],
          [143.86882, -37.52722],
          [143.86797, -37.52641],
          [143.86715, -37.5257],
          [143.86615, -37.52497],
          [143.86497, -37.5242],
          [143.86347, -37.52338],
          [143.86238, -37.52288],
          [143.86092, -37.52233],
          [143.85908, -37.52177],
          [143.85824, -37.52158],
          [143.85754, -37.52143],
          [143.85683, -37.5213],
          [143.85613, -37.52119],
          [143.85548, -37.52112],
          [143.85484, -37.52106],
          [143.85344, -37.52101],
          [143.85168, -37.52103],
          [143.85106, -37.52105],
          [143.85065, -37.52106],
          [143.85013, -37.52107],
          [143.84934, -37.52113],
          [143.84785, -37.52122],
          [143.84755, -37.52126],
          [143.84709, -37.52137],
          [143.84671, -37.52152],
          [143.84637, -37.5217],
          [143.84609, -37.5219],
          [143.8458, -37.52215],
          [143.84542, -37.52246],
          [143.84519, -37.52274],
          [143.84511, -37.52291],
          [143.84507, -37.52307],
          [143.84508, -37.52322],
          [143.84515, -37.52344],
          [143.84526, -37.5238],
          [143.84539, -37.52401],
          [143.84581, -37.52455],
          [143.84625, -37.52502],
          [143.84782, -37.52654],
          [143.84831, -37.52698],
          [143.84855, -37.52728],
          [143.84865, -37.52736],
          [143.84877, -37.52743],
          [143.84882, -37.52744],
          [143.84889, -37.52748],
          [143.84891, -37.5275],
          [143.84898, -37.52759],
          [143.84897, -37.52768],
          [143.84897, -37.52771],
          [143.84894, -37.52777],
          [143.84906, -37.52821],
          [143.84923, -37.52874],
          [143.84929, -37.5291],
          [143.84927, -37.52959],
          [143.84931, -37.5307],
          [143.84932, -37.53244],
          [143.84933, -37.53332],
          [143.84933, -37.53388],
          [143.84933, -37.53414],
          [143.84934, -37.53532],
          [143.84985, -37.53537],
          [143.85033, -37.53543],
          [143.85054, -37.53545],
        ],
        type: 'MultiPolygon',
      },
    },
    {
      type: 'Feature',
      properties: {
        fill: 'none',
        time: '1h 35m',
        address: 'Bendigo',
      },
      geometry: {
        coordinates: [
          [144.2855, -36.7985],
          [144.28558, -36.79823],
          [144.28562, -36.79784],
          [144.28558, -36.79751],
          [144.28555, -36.79717],
          [144.28562, -36.79708],
          [144.28539, -36.79658],
          [144.28525, -36.79621],
          [144.28505, -36.79565],
          [144.28494, -36.7953],
          [144.28391, -36.79548],
          [144.28378, -36.79552],
          [144.28366, -36.79557],
          [144.28311, -36.79599],
          [144.28197, -36.79684],
          [144.28168, -36.79705],
          [144.28146, -36.79717],
          [144.28122, -36.79728],
          [144.28076, -36.79739],
          [144.28042, -36.79745],
          [144.28009, -36.79754],
          [144.27982, -36.79765],
          [144.27962, -36.79775],
          [144.27899, -36.79812],
          [144.27791, -36.79876],
          [144.27723, -36.79911],
          [144.27604, -36.79984],
          [144.27533, -36.80027],
          [144.27515, -36.80033],
          [144.27503, -36.80035],
          [144.27479, -36.80033],
          [144.2738, -36.80015],
          [144.27266, -36.8],
          [144.27216, -36.79997],
          [144.27173, -36.79996],
          [144.27145, -36.79996],
          [144.27127, -36.79999],
          [144.27111, -36.80004],
          [144.26985, -36.80085],
          [144.26966, -36.801],
          [144.26959, -36.80108],
          [144.26941, -36.80169],
          [144.2693, -36.8021],
          [144.26921, -36.80225],
          [144.26909, -36.80235],
          [144.26894, -36.8024],
          [144.26878, -36.80242],
          [144.26841, -36.80243],
          [144.26827, -36.80238],
          [144.2681, -36.8023],
          [144.26803, -36.80218],
          [144.26781, -36.8016],
          [144.2675, -36.80107],
          [144.26716, -36.8006],
          [144.26658, -36.8001],
          [144.26607, -36.79969],
          [144.26581, -36.7995],
          [144.2656, -36.79939],
          [144.26534, -36.79929],
          [144.26536, -36.79919],
          [144.26558, -36.79746],
          [144.26571, -36.79618],
          [144.26571, -36.79572],
          [144.26566, -36.79535],
          [144.26548, -36.79467],
          [144.26491, -36.79284],
          [144.26454, -36.79288],
          [144.26402, -36.79294],
          [144.26382, -36.79296],
          [144.26364, -36.79297],
          [144.26349, -36.79295],
          [144.26338, -36.7929],
          [144.26319, -36.79251],
          [144.26296, -36.79197],
          [144.26286, -36.79154],
          [144.26276, -36.7911],
          [144.26269, -36.79086],
          [144.26253, -36.7906],
          [144.26209, -36.79002],
          [144.26185, -36.78966],
          [144.26162, -36.78975],
          [144.26121, -36.79003],
          [144.26092, -36.79025],
          [144.26036, -36.79073],
          [144.26004, -36.791],
          [144.25985, -36.79116],
          [144.25968, -36.79126],
          [144.25937, -36.79141],
          [144.2589, -36.7916],
          [144.25831, -36.79174],
          [144.25806, -36.79184],
          [144.25776, -36.79196],
          [144.25725, -36.79214],
          [144.25677, -36.79227],
          [144.25587, -36.79246],
          [144.25496, -36.79266],
          [144.25477, -36.79269],
          [144.25436, -36.79277],
          [144.25378, -36.79284],
          [144.25295, -36.79293],
          [144.25264, -36.79293],
          [144.25224, -36.79288],
          [144.25062, -36.79255],
          [144.25043, -36.79254],
          [144.25027, -36.79255],
          [144.25026, -36.79256],
          [144.25021, -36.79257],
          [144.25016, -36.79257],
          [144.25011, -36.79255],
          [144.25007, -36.79252],
          [144.25006, -36.7925],
          [144.24994, -36.79245],
          [144.2498, -36.79241],
          [144.24955, -36.79236],
          [144.24913, -36.79228],
          [144.24894, -36.79303],
          [144.24852, -36.79443],
          [144.24841, -36.79484],
          [144.24836, -36.79505],
          [144.2483, -36.7952],
          [144.24826, -36.79523],
          [144.24822, -36.79526],
          [144.24813, -36.79529],
          [144.24794, -36.79527],
          [144.24715, -36.79514],
          [144.24702, -36.79512],
          [144.24619, -36.79501],
          [144.24579, -36.79495],
          [144.24521, -36.79487],
          [144.24446, -36.79477],
          [144.24436, -36.79476],
          [144.24382, -36.79468],
          [144.24377, -36.79467],
          [144.24365, -36.79466],
          [144.24329, -36.79461],
          [144.24293, -36.79455],
          [144.24288, -36.79455],
          [144.24226, -36.79445],
          [144.24201, -36.79441],
          [144.24185, -36.79439],
          [144.24183, -36.79439],
          [144.24152, -36.79435],
          [144.24111, -36.79431],
          [144.24097, -36.7943],
          [144.24071, -36.7943],
          [144.24019, -36.79431],
          [144.23999, -36.79429],
          [144.23977, -36.79424],
          [144.2387, -36.79396],
          [144.23851, -36.79391],
          [144.23838, -36.79389],
          [144.23827, -36.79393],
          [144.23817, -36.79399],
          [144.23813, -36.79404],
          [144.2381, -36.7941],
          [144.23812, -36.79419],
          [144.23849, -36.79474],
          [144.23918, -36.79572],
          [144.23932, -36.79592],
          [144.23939, -36.79609],
          [144.23945, -36.7961],
          [144.2395, -36.79612],
          [144.23953, -36.79617],
          [144.23954, -36.79621],
          [144.23954, -36.79625],
          [144.23952, -36.79628],
          [144.23946, -36.79633],
          [144.23942, -36.79634],
          [144.23937, -36.79635],
          [144.23929, -36.79632],
          [144.23925, -36.79629],
          [144.2391, -36.79627],
          [144.23828, -36.79626],
          [144.23691, -36.79628],
          [144.2368, -36.79629],
          [144.2367, -36.7963],
          [144.23506, -36.79665],
          [144.23397, -36.79688],
          [144.23202, -36.79729],
          [144.23054, -36.79763],
          [144.23018, -36.79772],
          [144.22891, -36.79801],
          [144.22748, -36.79834],
          [144.22725, -36.79839],
          [144.22655, -36.79855],
          [144.2258, -36.79871],
          [144.22526, -36.79884],
          [144.22468, -36.79902],
          [144.22441, -36.79914],
          [144.22413, -36.79929],
          [144.22372, -36.79957],
          [144.2234, -36.79989],
          [144.22311, -36.80017],
          [144.22126, -36.80204],
          [144.22105, -36.80225],
          [144.21799, -36.80536],
          [144.21761, -36.80575],
          [144.2168, -36.80655],
          [144.21675, -36.80659],
          [144.21457, -36.80841],
          [144.21429, -36.80865],
          [144.21316, -36.8096],
          [144.21271, -36.80995],
          [144.21245, -36.81011],
          [144.2122, -36.81026],
          [144.21163, -36.8105],
          [144.21101, -36.81065],
          [144.21022, -36.81069],
          [144.20908, -36.81059],
          [144.20779, -36.81049],
          [144.20645, -36.81036],
          [144.20417, -36.81015],
          [144.20382, -36.81011],
          [144.20253, -36.80995],
          [144.20181, -36.80985],
          [144.20003, -36.80963],
          [144.19939, -36.80961],
          [144.1992, -36.80961],
          [144.19842, -36.80965],
          [144.19756, -36.80965],
          [144.19676, -36.80956],
          [144.19579, -36.80938],
          [144.19489, -36.80918],
          [144.19406, -36.80915],
          [144.1932, -36.80914],
          [144.19069, -36.80914],
          [144.18984, -36.80913],
          [144.18724, -36.80911],
          [144.18476, -36.80912],
          [144.18389, -36.80915],
          [144.18298, -36.8092],
          [144.18212, -36.80926],
          [144.18105, -36.80932],
          [144.18027, -36.80945],
          [144.17952, -36.80965],
          [144.17925, -36.80973],
          [144.17822, -36.81002],
          [144.17763, -36.8102],
          [144.17548, -36.811],
          [144.17509, -36.81113],
          [144.1747, -36.81124],
          [144.17424, -36.81133],
          [144.1739, -36.81136],
          [144.17351, -36.81137],
          [144.17311, -36.81137],
          [144.17238, -36.81129],
          [144.17153, -36.81117],
          [144.1698, -36.81095],
          [144.16946, -36.81091],
          [144.16572, -36.81046],
          [144.16485, -36.81035],
          [144.16055, -36.8098],
          [144.16011, -36.80977],
          [144.15947, -36.80969],
          [144.15915, -36.8097],
          [144.15898, -36.80972],
          [144.15894, -36.80974],
          [144.15884, -36.80976],
          [144.15879, -36.80979],
          [144.15874, -36.80984],
          [144.15863, -36.81031],
          [144.1574, -36.81694],
          [144.15669, -36.82077],
          [144.15598, -36.82421],
          [144.1554, -36.82736],
          [144.15535, -36.82774],
          [144.15531, -36.82815],
          [144.15523, -36.82982],
          [144.15518, -36.83155],
          [144.15509, -36.83405],
          [144.15507, -36.8347],
          [144.15508, -36.83519],
          [144.15516, -36.83588],
          [144.15546, -36.83735],
          [144.15569, -36.83851],
          [144.15598, -36.83991],
          [144.15613, -36.84049],
          [144.15621, -36.8409],
          [144.15643, -36.84184],
          [144.15649, -36.84204],
          [144.1566, -36.84234],
          [144.15679, -36.84267],
          [144.15698, -36.84293],
          [144.1575, -36.84356],
          [144.15834, -36.84434],
          [144.1574, -36.84506],
          [144.15712, -36.84531],
          [144.15686, -36.8456],
          [144.15666, -36.8459],
          [144.1564, -36.84636],
          [144.15626, -36.84662],
          [144.15565, -36.84779],
          [144.15549, -36.84816],
          [144.15541, -36.84839],
          [144.15533, -36.84868],
          [144.15483, -36.85095],
          [144.15475, -36.85136],
          [144.15436, -36.85344],
          [144.15336, -36.8587],
          [144.15323, -36.8592],
          [144.15306, -36.85971],
          [144.15254, -36.86116],
          [144.15098, -36.86553],
          [144.15081, -36.866],
          [144.15043, -36.86696],
          [144.14984, -36.86861],
          [144.14849, -36.8724],
          [144.14662, -36.87754],
          [144.14583, -36.87969],
          [144.14497, -36.88206],
          [144.14463, -36.8829],
          [144.14426, -36.88385],
          [144.1441, -36.88435],
          [144.1439, -36.88486],
          [144.1428, -36.88791],
          [144.14038, -36.89456],
          [144.14003, -36.89548],
          [144.1398, -36.8961],
          [144.1391, -36.89806],
          [144.13863, -36.89946],
          [144.13836, -36.90086],
          [144.1382, -36.90183],
          [144.13791, -36.90335],
          [144.13775, -36.90433],
          [144.13738, -36.90631],
          [144.1373, -36.90683],
          [144.13691, -36.90886],
          [144.1365, -36.91165],
          [144.13595, -36.91448],
          [144.13576, -36.91548],
          [144.13458, -36.92255],
          [144.13448, -36.92306],
          [144.13433, -36.92356],
          [144.13413, -36.92405],
          [144.13391, -36.92451],
          [144.13318, -36.92596],
          [144.13295, -36.92645],
          [144.12961, -36.93308],
          [144.12957, -36.93317],
          [144.12941, -36.93356],
          [144.12928, -36.93403],
          [144.129, -36.93548],
          [144.12893, -36.93598],
          [144.12873, -36.93698],
          [144.12846, -36.93848],
          [144.1283, -36.93948],
          [144.12801, -36.94101],
          [144.12785, -36.94205],
          [144.12711, -36.94611],
          [144.1267, -36.9487],
          [144.12651, -36.94975],
          [144.1261, -36.95231],
          [144.1256, -36.9553],
          [144.12511, -36.95805],
          [144.12501, -36.9587],
          [144.12495, -36.95918],
          [144.12476, -36.96006],
          [144.12461, -36.96048],
          [144.12438, -36.96088],
          [144.12406, -36.96125],
          [144.12366, -36.96156],
          [144.12321, -36.96181],
          [144.12246, -36.96208],
          [144.1214, -36.96243],
          [144.12085, -36.9626],
          [144.12072, -36.96264],
          [144.12026, -36.96275],
          [144.1194, -36.96288],
          [144.11853, -36.96296],
          [144.11676, -36.96321],
          [144.11618, -36.96331],
          [144.1156, -36.96346],
          [144.11505, -36.96366],
          [144.11451, -36.96388],
          [144.114, -36.96411],
          [144.11346, -36.96433],
          [144.11295, -36.96456],
          [144.11191, -36.965],
          [144.11145, -36.96526],
          [144.11106, -36.96556],
          [144.10861, -36.96806],
          [144.10828, -36.96843],
          [144.108, -36.96883],
          [144.10778, -36.96928],
          [144.10711, -36.97113],
          [144.1061, -36.97383],
          [144.10571, -36.9747],
          [144.10548, -36.9751],
          [144.1052, -36.97548],
          [144.10455, -36.97625],
          [144.1028, -36.97818],
          [144.10248, -36.9786],
          [144.10235, -36.9788],
          [144.10221, -36.97901],
          [144.10201, -36.97946],
          [144.1019, -36.97991],
          [144.10176, -36.98035],
          [144.10164, -36.98068],
          [144.10149, -36.98099],
          [144.10124, -36.98132],
          [144.10086, -36.98178],
          [144.10031, -36.98234],
          [144.09998, -36.98259],
          [144.09933, -36.98297],
          [144.0988, -36.9833],
          [144.09847, -36.98354],
          [144.09805, -36.98391],
          [144.09776, -36.98423],
          [144.09739, -36.98477],
          [144.09721, -36.98507],
          [144.09661, -36.98609],
          [144.09639, -36.98646],
          [144.09595, -36.98722],
          [144.0957, -36.98761],
          [144.09534, -36.98797],
          [144.09488, -36.98825],
          [144.09417, -36.98861],
          [144.09272, -36.98923],
          [144.08852, -36.99114],
          [144.08825, -36.99124],
          [144.08776, -36.99141],
          [144.0828, -36.99279],
          [144.08254, -36.99286],
          [144.0816, -36.99311],
          [144.08113, -36.99323],
          [144.08081, -36.99329],
          [144.08056, -36.99332],
          [144.08034, -36.99334],
          [144.08016, -36.99334],
          [144.07996, -36.99333],
          [144.07952, -36.99331],
          [144.07857, -36.99325],
          [144.07736, -36.99317],
          [144.07693, -36.99314],
          [144.0756, -36.99302],
          [144.075, -36.99296],
          [144.07495, -36.99296],
          [144.07455, -36.99292],
          [144.07438, -36.9929],
          [144.07419, -36.9929],
          [144.07402, -36.99289],
          [144.07386, -36.9929],
          [144.07374, -36.99291],
          [144.07361, -36.99294],
          [144.07351, -36.99297],
          [144.07337, -36.99302],
          [144.07329, -36.99304],
          [144.07324, -36.99307],
          [144.07314, -36.9931],
          [144.07271, -36.99328],
          [144.07152, -36.99376],
          [144.07137, -36.99383],
          [144.07125, -36.99388],
          [144.07119, -36.99392],
          [144.07113, -36.99397],
          [144.07108, -36.99402],
          [144.07104, -36.99407],
          [144.07099, -36.99415],
          [144.07095, -36.99422],
          [144.07092, -36.99431],
          [144.07075, -36.99489],
          [144.07073, -36.99496],
          [144.07056, -36.99546],
          [144.07051, -36.99562],
          [144.07049, -36.99569],
          [144.07046, -36.99576],
          [144.07042, -36.99585],
          [144.07041, -36.99587],
          [144.07038, -36.99592],
          [144.07034, -36.99598],
          [144.07028, -36.99606],
          [144.07012, -36.99624],
          [144.06992, -36.99644],
          [144.06951, -36.99684],
          [144.06911, -36.99724],
          [144.06907, -36.99728],
          [144.06904, -36.99732],
          [144.06902, -36.99737],
          [144.06887, -36.99772],
          [144.06884, -36.99778],
          [144.0688, -36.99783],
          [144.06876, -36.99786],
          [144.06869, -36.99789],
          [144.06858, -36.99789],
          [144.06859, -36.99803],
          [144.0686, -36.9982],
          [144.06859, -36.99831],
          [144.06858, -36.99838],
          [144.06849, -36.99898],
          [144.06847, -36.9991],
          [144.06839, -36.99955],
          [144.06828, -37.0001],
          [144.06805, -37.0014],
          [144.06789, -37.00226],
          [144.06779, -37.00282],
          [144.06774, -37.00293],
          [144.06739, -37.00332],
          [144.0673, -37.00343],
          [144.06689, -37.00386],
          [144.06667, -37.00416],
          [144.06647, -37.00445],
          [144.06634, -37.00465],
          [144.06628, -37.00484],
          [144.06628, -37.00491],
          [144.06628, -37.00499],
          [144.06623, -37.00582],
          [144.06616, -37.00641],
          [144.066, -37.00701],
          [144.06563, -37.00809],
          [144.06546, -37.00857],
          [144.06501, -37.00985],
          [144.06495, -37.01023],
          [144.06516, -37.01228],
          [144.06516, -37.01262],
          [144.06504, -37.013],
          [144.06385, -37.01561],
          [144.06374, -37.01615],
          [144.0632, -37.01969],
          [144.06307, -37.02018],
          [144.06182, -37.02277],
          [144.06156, -37.02333],
          [144.06167, -37.02503],
          [144.06157, -37.02608],
          [144.06113, -37.02931],
          [144.06082, -37.03009],
          [144.06034, -37.03126],
          [144.06029, -37.0314],
          [144.0591, -37.03291],
          [144.05792, -37.0346],
          [144.05692, -37.03612],
          [144.05651, -37.03674],
          [144.05132, -37.04555],
          [144.05117, -37.04579],
          [144.05106, -37.04614],
          [144.05097, -37.04655],
          [144.05086, -37.04921],
          [144.05083, -37.0501],
          [144.05077, -37.05124],
          [144.05066, -37.0519],
          [144.0505, -37.05253],
          [144.05027, -37.05299],
          [144.04987, -37.05359],
          [144.04948, -37.05411],
          [144.04727, -37.05643],
          [144.04702, -37.05692],
          [144.04686, -37.05735],
          [144.04675, -37.05778],
          [144.04568, -37.06191],
          [144.04558, -37.06245],
          [144.04552, -37.06304],
          [144.04551, -37.06628],
          [144.04549, -37.06892],
          [144.0455, -37.06943],
          [144.0455, -37.06958],
          [144.0455, -37.07003],
          [144.04556, -37.07064],
          [144.04571, -37.07114],
          [144.04586, -37.0715],
          [144.046, -37.07179],
          [144.04624, -37.07218],
          [144.04664, -37.0728],
          [144.04704, -37.07339],
          [144.04729, -37.07371],
          [144.04763, -37.07412],
          [144.05357, -37.08048],
          [144.05626, -37.08347],
          [144.0584, -37.08498],
          [144.06099, -37.08627],
          [144.06222, -37.08725],
          [144.06434, -37.08954],
          [144.06587, -37.09148],
          [144.06687, -37.09294],
          [144.06714, -37.09399],
          [144.06721, -37.09534],
          [144.06723, -37.0957],
          [144.06707, -37.09754],
          [144.06673, -37.09854],
          [144.06441, -37.1023],
          [144.06386, -37.103],
          [144.06345, -37.10327],
          [144.0632, -37.10344],
          [144.06243, -37.10382],
          [144.06223, -37.10392],
          [144.06172, -37.1041],
          [144.06138, -37.10439],
          [144.06129, -37.10478],
          [144.06135, -37.10504],
          [144.06239, -37.10633],
          [144.0621, -37.10644],
          [144.0611, -37.10694],
          [144.06005, -37.10747],
          [144.05976, -37.10764],
          [144.05937, -37.10783],
          [144.05909, -37.10795],
          [144.05878, -37.10808],
          [144.05842, -37.1082],
          [144.05813, -37.10828],
          [144.05787, -37.10834],
          [144.0575, -37.10843],
          [144.0571, -37.1085],
          [144.05666, -37.10855],
          [144.05635, -37.10857],
          [144.05593, -37.10858],
          [144.05542, -37.10857],
          [144.05512, -37.10856],
          [144.05511, -37.10862],
          [144.05511, -37.10866],
          [144.05511, -37.10869],
          [144.05514, -37.10874],
          [144.05517, -37.10881],
          [144.05529, -37.10898],
          [144.0558, -37.10961],
          [144.05644, -37.1104],
          [144.05723, -37.11139],
          [144.05714, -37.11143],
          [144.05635, -37.11181],
          [144.05598, -37.11199],
          [144.05483, -37.11257],
          [144.05473, -37.11262],
          [144.0544, -37.11279],
          [144.05424, -37.11287],
          [144.05407, -37.11296],
          [144.05393, -37.11303],
          [144.05373, -37.11315],
          [144.05367, -37.11319],
          [144.05359, -37.11324],
          [144.0535, -37.1133],
          [144.05339, -37.11339],
          [144.0533, -37.11347],
          [144.05319, -37.11356],
          [144.05303, -37.11373],
          [144.05291, -37.11388],
          [144.05279, -37.11403],
          [144.05269, -37.11421],
          [144.0526, -37.11438],
          [144.05252, -37.11456],
          [144.05246, -37.11474],
          [144.05238, -37.11504],
          [144.05235, -37.11521],
          [144.05214, -37.11638],
          [144.05207, -37.11669],
          [144.05203, -37.11688],
          [144.05199, -37.11705],
          [144.05192, -37.11725],
          [144.05183, -37.11749],
          [144.05179, -37.11759],
          [144.05164, -37.11791],
          [144.05147, -37.11816],
          [144.05129, -37.11839],
          [144.05111, -37.11861],
          [144.04992, -37.1196],
          [144.04968, -37.11976],
          [144.04758, -37.12142],
          [144.04418, -37.12648],
          [144.04412, -37.12667],
          [144.04389, -37.12742],
          [144.04272, -37.13394],
          [144.04107, -37.14278],
          [144.04091, -37.14341],
          [144.04067, -37.14435],
          [144.04046, -37.14538],
          [144.04042, -37.14583],
          [144.04047, -37.14652],
          [144.04266, -37.15761],
          [144.04265, -37.15865],
          [144.04232, -37.16146],
          [144.04229, -37.16176],
          [144.04213, -37.16347],
          [144.04201, -37.1642],
          [144.04177, -37.16483],
          [144.04132, -37.16563],
          [144.04084, -37.16632],
          [144.0404, -37.16682],
          [144.04027, -37.16697],
          [144.0388, -37.16822],
          [144.03818, -37.16868],
          [144.03406, -37.17172],
          [144.03349, -37.17216],
          [144.03295, -37.17268],
          [144.03276, -37.17294],
          [144.02947, -37.17748],
          [144.02831, -37.17915],
          [144.0277, -37.18067],
          [144.02729, -37.18212],
          [144.02726, -37.18307],
          [144.02721, -37.18694],
          [144.02702, -37.18786],
          [144.02693, -37.18813],
          [144.02655, -37.18867],
          [144.01749, -37.19739],
          [144.01688, -37.19793],
          [144.01616, -37.19843],
          [144.01557, -37.19878],
          [144.01501, -37.19902],
          [144.01386, -37.19937],
          [144.01291, -37.19954],
          [144.01219, -37.19961],
          [144.01188, -37.19962],
          [144.01073, -37.19962],
          [144.0081, -37.19953],
          [144.00758, -37.19949],
          [144.00709, -37.1994],
          [144.0066, -37.19922],
          [144.00576, -37.1989],
          [144.00463, -37.19848],
          [144.00325, -37.19795],
          [144.0018, -37.19748],
          [144.0009, -37.19733],
          [144.00002, -37.19723],
          [143.98964, -37.19603],
          [143.9889, -37.1959],
          [143.98765, -37.19555],
          [143.98684, -37.1953],
          [143.9859, -37.19502],
          [143.98494, -37.19483],
          [143.98421, -37.19477],
          [143.98359, -37.19478],
          [143.98313, -37.19483],
          [143.98276, -37.1949],
          [143.98258, -37.19495],
          [143.98233, -37.19501],
          [143.9816, -37.19522],
          [143.97964, -37.19581],
          [143.97703, -37.19657],
          [143.97472, -37.19723],
          [143.97416, -37.19733],
          [143.97383, -37.19737],
          [143.97355, -37.19738],
          [143.97304, -37.19739],
          [143.97221, -37.19735],
          [143.96848, -37.19719],
          [143.95624, -37.19669],
          [143.95612, -37.19668],
          [143.95589, -37.19667],
          [143.95553, -37.19663],
          [143.95468, -37.19652],
          [143.95324, -37.19637],
          [143.95294, -37.19637],
          [143.9525, -37.1964],
          [143.95198, -37.19648],
          [143.95174, -37.19653],
          [143.95123, -37.19669],
          [143.95076, -37.19687],
          [143.95032, -37.19709],
          [143.9498, -37.19743],
          [143.94913, -37.19789],
          [143.94885, -37.19808],
          [143.94854, -37.19829],
          [143.94823, -37.19853],
          [143.94803, -37.1987],
          [143.94783, -37.19889],
          [143.94757, -37.19917],
          [143.94744, -37.19935],
          [143.94732, -37.19958],
          [143.94723, -37.19975],
          [143.94714, -37.2],
          [143.94706, -37.20022],
          [143.94697, -37.20054],
          [143.94691, -37.20084],
          [143.94683, -37.20134],
          [143.94648, -37.20327],
          [143.94643, -37.20348],
          [143.94642, -37.20359],
          [143.94607, -37.20543],
          [143.94605, -37.20555],
          [143.94569, -37.20757],
          [143.94569, -37.20759],
          [143.94471, -37.21307],
          [143.9437, -37.21873],
          [143.94351, -37.21974],
          [143.94333, -37.22082],
          [143.94259, -37.22484],
          [143.94245, -37.22572],
          [143.94177, -37.22942],
          [143.94135, -37.23162],
          [143.94115, -37.23268],
          [143.94035, -37.23683],
          [143.94002, -37.23853],
          [143.93988, -37.23931],
          [143.93957, -37.24086],
          [143.93938, -37.24228],
          [143.93877, -37.24733],
          [143.93878, -37.24838],
          [143.93896, -37.2494],
          [143.93956, -37.25047],
          [143.94025, -37.25129],
          [143.94074, -37.25176],
          [143.94824, -37.25884],
          [143.94869, -37.25919],
          [143.94913, -37.25951],
          [143.94962, -37.2598],
          [143.95026, -37.26014],
          [143.95091, -37.26041],
          [143.95148, -37.2606],
          [143.95189, -37.26071],
          [143.95216, -37.26078],
          [143.95356, -37.26111],
          [143.95463, -37.26139],
          [143.95516, -37.26154],
          [143.95569, -37.26175],
          [143.95623, -37.262],
          [143.95667, -37.26226],
          [143.95702, -37.2625],
          [143.95747, -37.26285],
          [143.95792, -37.26325],
          [143.95817, -37.26353],
          [143.95844, -37.26389],
          [143.95878, -37.26443],
          [143.95892, -37.2647],
          [143.95903, -37.26495],
          [143.95919, -37.26543],
          [143.95927, -37.26577],
          [143.95934, -37.26612],
          [143.95936, -37.26647],
          [143.95934, -37.26754],
          [143.95934, -37.26784],
          [143.95928, -37.26995],
          [143.95925, -37.27027],
          [143.95775, -37.2791],
          [143.95773, -37.27929],
          [143.95687, -37.28466],
          [143.95316, -37.30658],
          [143.95311, -37.30687],
          [143.95222, -37.30935],
          [143.95157, -37.31041],
          [143.94986, -37.31198],
          [143.94928, -37.31302],
          [143.94734, -37.31982],
          [143.94728, -37.32061],
          [143.94887, -37.33107],
          [143.94885, -37.33158],
          [143.94872, -37.3323],
          [143.94852, -37.33361],
          [143.94821, -37.33567],
          [143.94804, -37.33604],
          [143.9465, -37.33842],
          [143.94525, -37.34036],
          [143.9451, -37.34059],
          [143.94472, -37.34118],
          [143.94463, -37.34133],
          [143.94414, -37.3422],
          [143.94384, -37.34312],
          [143.94381, -37.34329],
          [143.94362, -37.34443],
          [143.94348, -37.34525],
          [143.94102, -37.35966],
          [143.94072, -37.36052],
          [143.94064, -37.36084],
          [143.94052, -37.36136],
          [143.93954, -37.36713],
          [143.93925, -37.36774],
          [143.93917, -37.36791],
          [143.93909, -37.36804],
          [143.93897, -37.36821],
          [143.93881, -37.36844],
          [143.93863, -37.36864],
          [143.93842, -37.36887],
          [143.93825, -37.36903],
          [143.93805, -37.3692],
          [143.93779, -37.36938],
          [143.93755, -37.36954],
          [143.93728, -37.3697],
          [143.93704, -37.36982],
          [143.93679, -37.36993],
          [143.93644, -37.37005],
          [143.93611, -37.37015],
          [143.93579, -37.37024],
          [143.9356, -37.37028],
          [143.93536, -37.37032],
          [143.93511, -37.37035],
          [143.93485, -37.37038],
          [143.93458, -37.37039],
          [143.93433, -37.37039],
          [143.93405, -37.37039],
          [143.93383, -37.37037],
          [143.92693, -37.36962],
          [143.92439, -37.36934],
          [143.92273, -37.36916],
          [143.92267, -37.36916],
          [143.92263, -37.36916],
          [143.92257, -37.36917],
          [143.92251, -37.36918],
          [143.92244, -37.36919],
          [143.92238, -37.36921],
          [143.92231, -37.36922],
          [143.92226, -37.36924],
          [143.92221, -37.36926],
          [143.92215, -37.36929],
          [143.92209, -37.36933],
          [143.92204, -37.36937],
          [143.92198, -37.36941],
          [143.92194, -37.36945],
          [143.9219, -37.3695],
          [143.92186, -37.36954],
          [143.92183, -37.36958],
          [143.92182, -37.36962],
          [143.9218, -37.36967],
          [143.92178, -37.36976],
          [143.92163, -37.37067],
          [143.92159, -37.37093],
          [143.92145, -37.37172],
          [143.92127, -37.37281],
          [143.92094, -37.37468],
          [143.92006, -37.37991],
          [143.91907, -37.38568],
          [143.91889, -37.38633],
          [143.91828, -37.38685],
          [143.91805, -37.387],
          [143.91789, -37.3871],
          [143.9174, -37.38718],
          [143.91678, -37.38716],
          [143.91296, -37.38676],
          [143.91178, -37.3866],
          [143.91114, -37.38658],
          [143.91063, -37.38662],
          [143.91009, -37.38669],
          [143.90993, -37.38671],
          [143.90936, -37.38693],
          [143.90725, -37.38767],
          [143.90422, -37.38874],
          [143.90308, -37.38951],
          [143.90231, -37.39025],
          [143.90179, -37.39131],
          [143.90124, -37.39228],
          [143.90112, -37.39248],
          [143.90046, -37.39322],
          [143.89808, -37.39477],
          [143.89585, -37.3963],
          [143.89336, -37.3992],
          [143.89303, -37.39976],
          [143.89289, -37.4001],
          [143.89265, -37.40111],
          [143.8922, -37.40389],
          [143.89193, -37.40492],
          [143.89154, -37.40564],
          [143.89105, -37.4062],
          [143.8896, -37.40788],
          [143.88911, -37.40823],
          [143.88898, -37.40832],
          [143.88848, -37.40857],
          [143.88843, -37.4086],
          [143.8884, -37.40871],
          [143.88837, -37.40882],
          [143.88836, -37.40896],
          [143.88893, -37.40961],
          [143.88963, -37.41044],
          [143.89053, -37.4116],
          [143.89074, -37.41188],
          [143.89098, -37.41221],
          [143.89119, -37.41256],
          [143.89151, -37.41321],
          [143.89205, -37.41419],
          [143.89212, -37.41432],
          [143.89278, -37.41567],
          [143.89311, -37.41628],
          [143.89341, -37.41672],
          [143.89376, -37.41717],
          [143.8938, -37.41723],
          [143.89392, -37.41741],
          [143.89401, -37.41755],
          [143.89402, -37.41757],
          [143.89407, -37.41766],
          [143.89418, -37.41787],
          [143.8943, -37.41817],
          [143.89445, -37.4186],
          [143.89454, -37.41895],
          [143.89464, -37.41908],
          [143.8947, -37.41915],
          [143.89474, -37.41917],
          [143.89478, -37.41919],
          [143.89482, -37.41924],
          [143.89483, -37.4193],
          [143.89481, -37.41935],
          [143.89477, -37.41939],
          [143.89469, -37.41952],
          [143.89466, -37.41971],
          [143.89463, -37.42015],
          [143.89485, -37.42174],
          [143.89485, -37.4218],
          [143.89487, -37.4219],
          [143.89493, -37.4223],
          [143.89497, -37.42254],
          [143.89496, -37.42287],
          [143.89493, -37.42313],
          [143.89489, -37.42333],
          [143.89484, -37.42354],
          [143.89476, -37.42376],
          [143.89466, -37.42397],
          [143.89457, -37.42414],
          [143.89436, -37.42454],
          [143.89424, -37.42476],
          [143.89418, -37.42488],
          [143.89411, -37.42505],
          [143.89407, -37.42518],
          [143.89403, -37.42538],
          [143.89401, -37.42554],
          [143.89399, -37.42589],
          [143.89395, -37.42691],
          [143.89395, -37.42696],
          [143.89392, -37.42729],
          [143.89391, -37.42739],
          [143.89387, -37.42761],
          [143.89379, -37.42791],
          [143.89368, -37.42822],
          [143.89354, -37.42849],
          [143.89333, -37.42892],
          [143.89286, -37.42986],
          [143.8927, -37.43019],
          [143.89258, -37.43043],
          [143.89237, -37.43088],
          [143.89227, -37.43112],
          [143.89221, -37.4313],
          [143.89219, -37.43137],
          [143.89215, -37.43163],
          [143.8921, -37.43187],
          [143.89204, -37.43218],
          [143.89189, -37.43317],
          [143.89186, -37.4334],
          [143.89183, -37.43365],
          [143.89183, -37.43384],
          [143.89183, -37.43424],
          [143.89188, -37.43478],
          [143.89192, -37.43525],
          [143.89195, -37.43553],
          [143.89202, -37.43638],
          [143.89205, -37.43709],
          [143.89208, -37.43745],
          [143.89209, -37.43764],
          [143.89211, -37.4382],
          [143.89211, -37.43853],
          [143.89207, -37.43902],
          [143.89201, -37.43948],
          [143.8919, -37.43993],
          [143.8917, -37.44054],
          [143.89142, -37.44122],
          [143.89105, -37.44187],
          [143.8907, -37.44236],
          [143.89022, -37.44295],
          [143.88993, -37.44323],
          [143.88965, -37.4435],
          [143.88916, -37.44392],
          [143.88885, -37.44417],
          [143.88852, -37.44441],
          [143.88716, -37.44539],
          [143.88662, -37.44574],
          [143.884, -37.44746],
          [143.88303, -37.4481],
          [143.88073, -37.44958],
          [143.87927, -37.45029],
          [143.87684, -37.45109],
          [143.87667, -37.45115],
          [143.87398, -37.45211],
          [143.87123, -37.4531],
          [143.87106, -37.45316],
          [143.86732, -37.45483],
          [143.86471, -37.45627],
          [143.86404, -37.45665],
          [143.86112, -37.45826],
          [143.85534, -37.46145],
          [143.85523, -37.46151],
          [143.85475, -37.46177],
          [143.8533, -37.46256],
          [143.8531, -37.46268],
          [143.85263, -37.46297],
          [143.85234, -37.46317],
          [143.85205, -37.46338],
          [143.85179, -37.4636],
          [143.85151, -37.46383],
          [143.85094, -37.46439],
          [143.85051, -37.4649],
          [143.85027, -37.46524],
          [143.85003, -37.46559],
          [143.84985, -37.4659],
          [143.8497, -37.4662],
          [143.84956, -37.4665],
          [143.84945, -37.4668],
          [143.84935, -37.46705],
          [143.84927, -37.46729],
          [143.84901, -37.46819],
          [143.84864, -37.46949],
          [143.84804, -37.47173],
          [143.84785, -37.47245],
          [143.8467, -37.47663],
          [143.84655, -37.47715],
          [143.84643, -37.47764],
          [143.84636, -37.47802],
          [143.84632, -37.4784],
          [143.8463, -37.47863],
          [143.84631, -37.47939],
          [143.84639, -37.47997],
          [143.84648, -37.48051],
          [143.84663, -37.48106],
          [143.8468, -37.48163],
          [143.84733, -37.48353],
          [143.84804, -37.486],
          [143.84813, -37.48632],
          [143.84821, -37.48667],
          [143.84825, -37.487],
          [143.8483, -37.48734],
          [143.8483, -37.48768],
          [143.8483, -37.48803],
          [143.84828, -37.48835],
          [143.84825, -37.48865],
          [143.84815, -37.48927],
          [143.84792, -37.4905],
          [143.84746, -37.49296],
          [143.84653, -37.49791],
          [143.84532, -37.50427],
          [143.84526, -37.50468],
          [143.84523, -37.50507],
          [143.84521, -37.50541],
          [143.84523, -37.50578],
          [143.84529, -37.50616],
          [143.84562, -37.50768],
          [143.84609, -37.50982],
          [143.84632, -37.51092],
          [143.84642, -37.51146],
          [143.8465, -37.51207],
          [143.84651, -37.51265],
          [143.84651, -37.51278],
          [143.84652, -37.5129],
          [143.8465, -37.51321],
          [143.84647, -37.51352],
          [143.84632, -37.51422],
          [143.84615, -37.51475],
          [143.84609, -37.51489],
          [143.84596, -37.5152],
          [143.84581, -37.51551],
          [143.84572, -37.51576],
          [143.84545, -37.51626],
          [143.84507, -37.51693],
          [143.84476, -37.51748],
          [143.84467, -37.51771],
          [143.84456, -37.51799],
          [143.84455, -37.51805],
          [143.84456, -37.51813],
          [143.84458, -37.51814],
          [143.84464, -37.5182],
          [143.84467, -37.51826],
          [143.84467, -37.51833],
          [143.84465, -37.5184],
          [143.8446, -37.51846],
          [143.84452, -37.5185],
          [143.84443, -37.51857],
          [143.84434, -37.5187],
          [143.84427, -37.51908],
          [143.84421, -37.51932],
          [143.84413, -37.51968],
          [143.84413, -37.51981],
          [143.84413, -37.52027],
          [143.84414, -37.52058],
          [143.84418, -37.52091],
          [143.84422, -37.52123],
          [143.84429, -37.52171],
          [143.84441, -37.52214],
          [143.84464, -37.52269],
          [143.84482, -37.52308],
          [143.84484, -37.52312],
          [143.84499, -37.52338],
          [143.84526, -37.5238],
          [143.84539, -37.52401],
          [143.84581, -37.52455],
          [143.84625, -37.52502],
          [143.84782, -37.52654],
          [143.84831, -37.52698],
          [143.84855, -37.52728],
          [143.84865, -37.52736],
          [143.84877, -37.52743],
          [143.84882, -37.52744],
          [143.84889, -37.52748],
          [143.84891, -37.5275],
          [143.84898, -37.52759],
          [143.84897, -37.52768],
          [143.84897, -37.52771],
          [143.84894, -37.52777],
          [143.84906, -37.52821],
          [143.84923, -37.52874],
          [143.84929, -37.5291],
          [143.84927, -37.52959],
          [143.84931, -37.5307],
          [143.84932, -37.53244],
          [143.84933, -37.53332],
          [143.84933, -37.53388],
          [143.84933, -37.53414],
          [143.84934, -37.53532],
          [143.84985, -37.53537],
          [143.85033, -37.53543],
          [143.85061, -37.53546],
        ],
        type: 'MultiPolygon',
      },
    },

    {
      type: 'Feature',
      properties: {
        fill: 'none',
        address: 'Daylesford',
        time: '41m',
      },
      geometry: {
        coordinates: [
          [144.13493, -37.36957],
          [144.13552, -37.36881],
          [144.13611, -37.36778],
          [144.13632, -37.36676],
          [144.1363, -37.36591],
          [144.13631, -37.36583],
          [144.1365, -37.36374],
          [144.13652, -37.36306],
          [144.13653, -37.36292],
          [144.13656, -37.36279],
          [144.13665, -37.36232],
          [144.13674, -37.36178],
          [144.13683, -37.36138],
          [144.13689, -37.36115],
          [144.13695, -37.36094],
          [144.13704, -37.36072],
          [144.13721, -37.36043],
          [144.13749, -37.36014],
          [144.13787, -37.35981],
          [144.13836, -37.35944],
          [144.1385, -37.35934],
          [144.13858, -37.35928],
          [144.13879, -37.35914],
          [144.13936, -37.35883],
          [144.13985, -37.35854],
          [144.13992, -37.3585],
          [144.13994, -37.35849],
          [144.14023, -37.35832],
          [144.1405, -37.3581],
          [144.14074, -37.35785],
          [144.14093, -37.35759],
          [144.14104, -37.35733],
          [144.14108, -37.35707],
          [144.14105, -37.35669],
          [144.141, -37.35629],
          [144.14094, -37.35604],
          [144.1408, -37.3555],
          [144.14065, -37.35497],
          [144.14057, -37.35446],
          [144.14043, -37.35342],
          [144.1404, -37.35311],
          [144.1404, -37.35289],
          [144.14043, -37.35266],
          [144.14046, -37.3524],
          [144.14047, -37.35228],
          [144.14053, -37.35189],
          [144.14056, -37.35175],
          [144.14058, -37.3516],
          [144.14068, -37.35131],
          [144.1407, -37.35128],
          [144.14076, -37.35114],
          [144.14141, -37.34984],
          [144.14153, -37.34961],
          [144.14204, -37.34856],
          [144.14214, -37.34833],
          [144.14219, -37.34816],
          [144.14219, -37.34805],
          [144.1422, -37.34799],
          [144.14219, -37.34784],
          [144.14216, -37.34769],
          [144.14212, -37.34755],
          [144.14188, -37.34666],
          [144.14183, -37.34642],
          [144.14183, -37.3463],
          [144.14183, -37.34616],
          [144.14187, -37.34592],
          [144.14193, -37.34563],
          [144.1421, -37.34472],
          [144.14219, -37.34404],
          [144.1422, -37.34381],
          [144.14218, -37.34367],
          [144.14215, -37.34364],
          [144.14214, -37.3436],
          [144.14214, -37.34357],
          [144.14215, -37.34355],
          [144.14218, -37.34352],
          [144.1422, -37.34351],
          [144.14222, -37.3435],
          [144.14225, -37.3434],
          [144.14228, -37.34327],
          [144.14249, -37.34231],
          [144.14256, -37.34174],
          [144.14258, -37.34161],
          [144.14256, -37.3416],
          [144.14246, -37.34157],
          [144.14208, -37.34149],
          [144.14196, -37.34148],
          [144.14125, -37.34137],
          [144.13979, -37.34114],
          [144.13862, -37.34098],
          [144.13804, -37.34089],
          [144.13651, -37.3407],
          [144.13598, -37.34062],
          [144.13572, -37.34054],
          [144.13549, -37.34043],
          [144.13523, -37.34026],
          [144.13441, -37.33963],
          [144.13399, -37.33931],
          [144.13388, -37.33925],
          [144.13377, -37.3392],
          [144.13358, -37.33912],
          [144.1334, -37.33907],
          [144.13312, -37.33902],
          [144.13285, -37.339],
          [144.13258, -37.339],
          [144.13236, -37.33903],
          [144.13217, -37.33907],
          [144.1319, -37.33915],
          [144.13177, -37.3392],
          [144.13167, -37.33926],
          [144.13146, -37.33941],
          [144.13131, -37.33955],
          [144.13118, -37.33969],
          [144.1311, -37.33979],
          [144.13102, -37.33991],
          [144.13093, -37.34006],
          [144.13071, -37.34047],
          [144.13056, -37.34069],
          [144.13041, -37.34087],
          [144.13028, -37.341],
          [144.13011, -37.34113],
          [144.12991, -37.34127],
          [144.12975, -37.34139],
          [144.12936, -37.34169],
          [144.12925, -37.34176],
          [144.12817, -37.34257],
          [144.12799, -37.34275],
          [144.12786, -37.34292],
          [144.12775, -37.34314],
          [144.1277, -37.34337],
          [144.12769, -37.34358],
          [144.12769, -37.34383],
          [144.12775, -37.34429],
          [144.12774, -37.3445],
          [144.12772, -37.34473],
          [144.12767, -37.345],
          [144.12756, -37.34538],
          [144.12742, -37.34568],
          [144.12657, -37.34723],
          [144.12645, -37.34745],
          [144.12628, -37.34773],
          [144.12622, -37.34785],
          [144.12596, -37.34833],
          [144.12588, -37.34847],
          [144.12575, -37.34869],
          [144.12559, -37.34891],
          [144.12544, -37.3491],
          [144.12527, -37.34925],
          [144.12483, -37.34953],
          [144.12432, -37.34976],
          [144.12314, -37.35016],
          [144.12276, -37.35032],
          [144.12243, -37.35049],
          [144.12214, -37.35069],
          [144.12174, -37.35102],
          [144.12159, -37.35115],
          [144.12153, -37.3512],
          [144.12116, -37.35149],
          [144.12087, -37.3516],
          [144.12051, -37.35169],
          [144.12009, -37.35172],
          [144.11977, -37.35169],
          [144.11934, -37.35156],
          [144.11881, -37.35139],
          [144.11823, -37.35121],
          [144.11768, -37.35101],
          [144.11728, -37.3508],
          [144.1168, -37.35055],
          [144.11649, -37.35044],
          [144.11624, -37.35036],
          [144.11581, -37.35032],
          [144.11546, -37.35034],
          [144.11505, -37.35044],
          [144.11473, -37.3506],
          [144.1144, -37.35082],
          [144.11418, -37.35108],
          [144.11402, -37.35144],
          [144.11356, -37.35249],
          [144.11345, -37.35288],
          [144.11339, -37.35331],
          [144.1134, -37.3539],
          [144.11341, -37.35451],
          [144.11339, -37.35472],
          [144.11326, -37.35507],
          [144.11313, -37.35527],
          [144.11297, -37.35544],
          [144.1126, -37.35568],
          [144.11218, -37.35591],
          [144.11132, -37.35641],
          [144.11104, -37.35654],
          [144.11066, -37.35667],
          [144.11027, -37.35673],
          [144.10994, -37.35675],
          [144.10955, -37.35673],
          [144.10915, -37.35668],
          [144.10881, -37.35657],
          [144.10857, -37.35645],
          [144.10827, -37.35628],
          [144.10799, -37.35603],
          [144.10718, -37.35526],
          [144.10693, -37.35505],
          [144.10666, -37.35489],
          [144.10637, -37.35478],
          [144.10599, -37.35467],
          [144.10562, -37.3546],
          [144.10531, -37.35457],
          [144.10506, -37.35456],
          [144.10471, -37.35458],
          [144.10431, -37.35462],
          [144.1036, -37.35476],
          [144.10182, -37.35509],
          [144.09836, -37.35574],
          [144.09783, -37.35585],
          [144.0962, -37.35615],
          [144.09566, -37.35628],
          [144.09499, -37.35648],
          [144.09451, -37.35667],
          [144.09407, -37.35688],
          [144.09213, -37.35795],
          [144.0915, -37.35824],
          [144.09101, -37.35842],
          [144.09054, -37.35854],
          [144.09008, -37.35864],
          [144.08958, -37.35872],
          [144.08901, -37.35876],
          [144.08859, -37.35877],
          [144.0882, -37.35876],
          [144.08768, -37.35873],
          [144.08704, -37.35867],
          [144.08454, -37.35847],
          [144.08405, -37.35843],
          [144.08323, -37.35831],
          [144.08258, -37.35824],
          [144.08178, -37.3582],
          [144.08131, -37.35816],
          [144.08082, -37.35812],
          [144.07865, -37.35784],
          [144.07783, -37.35775],
          [144.07708, -37.3577],
          [144.07648, -37.35771],
          [144.07564, -37.35776],
          [144.07504, -37.35784],
          [144.07443, -37.35799],
          [144.07375, -37.35817],
          [144.07114, -37.35898],
          [144.07065, -37.35909],
          [144.07005, -37.35918],
          [144.06953, -37.35922],
          [144.06903, -37.35921],
          [144.06846, -37.35916],
          [144.06819, -37.3591],
          [144.06738, -37.35891],
          [144.06561, -37.35844],
          [144.06495, -37.35834],
          [144.06433, -37.35831],
          [144.06375, -37.3583],
          [144.06054, -37.35859],
          [144.05998, -37.35867],
          [144.05956, -37.35876],
          [144.05911, -37.35889],
          [144.05857, -37.35909],
          [144.05795, -37.35936],
          [144.05683, -37.35989],
          [144.05589, -37.36036],
          [144.04979, -37.36342],
          [144.04175, -37.36744],
          [144.04133, -37.36765],
          [144.03871, -37.36895],
          [144.03824, -37.36918],
          [144.02911, -37.37375],
          [144.02755, -37.37449],
          [144.02403, -37.37619],
          [144.02381, -37.37629],
          [144.02313, -37.37662],
          [144.02241, -37.37698],
          [144.01805, -37.37917],
          [144.01265, -37.38193],
          [144.00654, -37.38506],
          [144.00575, -37.3855],
          [144.00545, -37.38573],
          [144.0053, -37.38584],
          [144.0052, -37.38594],
          [144.00495, -37.38617],
          [144.0045, -37.38663],
          [144.00375, -37.38764],
          [143.99908, -37.39412],
          [143.99832, -37.39518],
          [143.99775, -37.39598],
          [143.9974, -37.39655],
          [143.99695, -37.3973],
          [143.99618, -37.39862],
          [143.99594, -37.39901],
          [143.99582, -37.39924],
          [143.99556, -37.39969],
          [143.99533, -37.40011],
          [143.99454, -37.40149],
          [143.99418, -37.40217],
          [143.99411, -37.40231],
          [143.9939, -37.40269],
          [143.9938, -37.40288],
          [143.99361, -37.40322],
          [143.99328, -37.40369],
          [143.99003, -37.40694],
          [143.98789, -37.40904],
          [143.98773, -37.40919],
          [143.98748, -37.40947],
          [143.98724, -37.40975],
          [143.9871, -37.40992],
          [143.9869, -37.41018],
          [143.98653, -37.41065],
          [143.98616, -37.41114],
          [143.98565, -37.4118],
          [143.98547, -37.41203],
          [143.98541, -37.41214],
          [143.98538, -37.41219],
          [143.98537, -37.41223],
          [143.98531, -37.41234],
          [143.98532, -37.41235],
          [143.98533, -37.4124],
          [143.98532, -37.41244],
          [143.98529, -37.41247],
          [143.98526, -37.4125],
          [143.98521, -37.41252],
          [143.98517, -37.41252],
          [143.98513, -37.41252],
          [143.9851, -37.41251],
          [143.98506, -37.41249],
          [143.98504, -37.41247],
          [143.98502, -37.41243],
          [143.98496, -37.4124],
          [143.9849, -37.41236],
          [143.9848, -37.41232],
          [143.98461, -37.41226],
          [143.98441, -37.41222],
          [143.98339, -37.41199],
          [143.98208, -37.41172],
          [143.9806, -37.41142],
          [143.97665, -37.41057],
          [143.97624, -37.41047],
          [143.97569, -37.41038],
          [143.97527, -37.41033],
          [143.97484, -37.41027],
          [143.97358, -37.41015],
          [143.97302, -37.41007],
          [143.9725, -37.41],
          [143.97196, -37.40991],
          [143.97143, -37.4098],
          [143.97038, -37.40952],
          [143.96826, -37.40892],
          [143.96428, -37.40779],
          [143.96395, -37.40769],
          [143.96333, -37.40759],
          [143.96273, -37.40753],
          [143.96212, -37.40748],
          [143.96151, -37.40749],
          [143.96098, -37.40755],
          [143.96041, -37.40762],
          [143.95345, -37.40906],
          [143.94471, -37.41018],
          [143.94386, -37.41036],
          [143.94325, -37.41054],
          [143.94267, -37.41075],
          [143.94217, -37.41097],
          [143.94165, -37.41123],
          [143.94068, -37.41186],
          [143.93816, -37.41357],
          [143.93762, -37.41389],
          [143.93709, -37.41414],
          [143.93653, -37.41437],
          [143.93598, -37.41455],
          [143.93538, -37.41471],
          [143.93513, -37.41476],
          [143.93477, -37.41483],
          [143.93418, -37.4149],
          [143.9335, -37.41495],
          [143.93308, -37.41495],
          [143.93275, -37.41494],
          [143.93242, -37.41492],
          [143.93196, -37.41488],
          [143.93168, -37.41485],
          [143.93141, -37.41481],
          [143.93111, -37.41474],
          [143.93083, -37.41468],
          [143.92941, -37.41429],
          [143.92823, -37.41397],
          [143.92773, -37.41385],
          [143.92732, -37.41376],
          [143.9269, -37.41368],
          [143.9253, -37.41339],
          [143.92466, -37.41327],
          [143.92422, -37.41321],
          [143.92391, -37.41318],
          [143.92354, -37.41315],
          [143.92189, -37.4131],
          [143.9208, -37.41306],
          [143.91913, -37.413],
          [143.91699, -37.41292],
          [143.91683, -37.41292],
          [143.91636, -37.41291],
          [143.91577, -37.41294],
          [143.91535, -37.41297],
          [143.91492, -37.41303],
          [143.91446, -37.41311],
          [143.91383, -37.41326],
          [143.91262, -37.41355],
          [143.91182, -37.41376],
          [143.91028, -37.4142],
          [143.90887, -37.41461],
          [143.90653, -37.4153],
          [143.9055, -37.41563],
          [143.90525, -37.41572],
          [143.90508, -37.4158],
          [143.90493, -37.41588],
          [143.90477, -37.41598],
          [143.90462, -37.4161],
          [143.90447, -37.41622],
          [143.90431, -37.41639],
          [143.90342, -37.4174],
          [143.90314, -37.41768],
          [143.9029, -37.4179],
          [143.90266, -37.41807],
          [143.90238, -37.41823],
          [143.90208, -37.41839],
          [143.90182, -37.41848],
          [143.90146, -37.41857],
          [143.90111, -37.41863],
          [143.90102, -37.41864],
          [143.90081, -37.41867],
          [143.89737, -37.41892],
          [143.89575, -37.41903],
          [143.89559, -37.41905],
          [143.89545, -37.41907],
          [143.89516, -37.41916],
          [143.89496, -37.41924],
          [143.89483, -37.4193],
          [143.89481, -37.41935],
          [143.89477, -37.41939],
          [143.89469, -37.41952],
          [143.89466, -37.41971],
          [143.89463, -37.42015],
          [143.89485, -37.42174],
          [143.89485, -37.4218],
          [143.89487, -37.4219],
          [143.89493, -37.4223],
          [143.89497, -37.42254],
          [143.89496, -37.42287],
          [143.89493, -37.42313],
          [143.89489, -37.42333],
          [143.89484, -37.42354],
          [143.89476, -37.42376],
          [143.89466, -37.42397],
          [143.89457, -37.42414],
          [143.89436, -37.42454],
          [143.89424, -37.42476],
          [143.89418, -37.42488],
          [143.89411, -37.42505],
          [143.89407, -37.42518],
          [143.89403, -37.42538],
          [143.89401, -37.42554],
          [143.89399, -37.42589],
          [143.89395, -37.42691],
          [143.89395, -37.42696],
          [143.89392, -37.42729],
          [143.89391, -37.42739],
          [143.89387, -37.42761],
          [143.89379, -37.42791],
          [143.89368, -37.42822],
          [143.89354, -37.42849],
          [143.89333, -37.42892],
          [143.89286, -37.42986],
          [143.8927, -37.43019],
          [143.89258, -37.43043],
          [143.89237, -37.43088],
          [143.89227, -37.43112],
          [143.89221, -37.4313],
          [143.89219, -37.43137],
          [143.89215, -37.43163],
          [143.8921, -37.43187],
          [143.89204, -37.43218],
          [143.89189, -37.43317],
          [143.89186, -37.4334],
          [143.89183, -37.43365],
          [143.89183, -37.43384],
          [143.89183, -37.43424],
          [143.89188, -37.43478],
          [143.89192, -37.43525],
          [143.89195, -37.43553],
          [143.89202, -37.43638],
          [143.89205, -37.43709],
          [143.89208, -37.43745],
          [143.89209, -37.43764],
          [143.89211, -37.4382],
          [143.89211, -37.43853],
          [143.89207, -37.43902],
          [143.89201, -37.43948],
          [143.8919, -37.43993],
          [143.8917, -37.44054],
          [143.89142, -37.44122],
          [143.89105, -37.44187],
          [143.8907, -37.44236],
          [143.89022, -37.44295],
          [143.88993, -37.44323],
          [143.88965, -37.4435],
          [143.88916, -37.44392],
          [143.88885, -37.44417],
          [143.88852, -37.44441],
          [143.88716, -37.44539],
          [143.88662, -37.44574],
          [143.884, -37.44746],
          [143.88303, -37.4481],
          [143.88073, -37.44958],
          [143.87927, -37.45029],
          [143.87684, -37.45109],
          [143.87667, -37.45115],
          [143.87398, -37.45211],
          [143.87123, -37.4531],
          [143.87106, -37.45316],
          [143.86732, -37.45483],
          [143.86471, -37.45627],
          [143.86404, -37.45665],
          [143.86112, -37.45826],
          [143.85534, -37.46145],
          [143.85523, -37.46151],
          [143.85475, -37.46177],
          [143.8533, -37.46256],
          [143.8531, -37.46268],
          [143.85263, -37.46297],
          [143.85234, -37.46317],
          [143.85205, -37.46338],
          [143.85179, -37.4636],
          [143.85151, -37.46383],
          [143.85094, -37.46439],
          [143.85051, -37.4649],
          [143.85027, -37.46524],
          [143.85003, -37.46559],
          [143.84985, -37.4659],
          [143.8497, -37.4662],
          [143.84956, -37.4665],
          [143.84945, -37.4668],
          [143.84935, -37.46705],
          [143.84927, -37.46729],
          [143.84901, -37.46819],
          [143.84864, -37.46949],
          [143.84804, -37.47173],
          [143.84785, -37.47245],
          [143.8467, -37.47663],
          [143.84655, -37.47715],
          [143.84643, -37.47764],
          [143.84636, -37.47802],
          [143.84632, -37.4784],
          [143.8463, -37.47863],
          [143.84631, -37.47939],
          [143.84639, -37.47997],
          [143.84648, -37.48051],
          [143.84663, -37.48106],
          [143.8468, -37.48163],
          [143.84733, -37.48353],
          [143.84804, -37.486],
          [143.84813, -37.48632],
          [143.84821, -37.48667],
          [143.84825, -37.487],
          [143.8483, -37.48734],
          [143.8483, -37.48768],
          [143.8483, -37.48803],
          [143.84828, -37.48835],
          [143.84825, -37.48865],
          [143.84815, -37.48927],
          [143.84792, -37.4905],
          [143.84746, -37.49296],
          [143.84653, -37.49791],
          [143.84532, -37.50427],
          [143.84526, -37.50468],
          [143.84523, -37.50507],
          [143.84521, -37.50541],
          [143.84523, -37.50578],
          [143.84529, -37.50616],
          [143.84562, -37.50768],
          [143.84609, -37.50982],
          [143.84632, -37.51092],
          [143.84642, -37.51146],
          [143.8465, -37.51207],
          [143.84651, -37.51265],
          [143.84651, -37.51278],
          [143.84652, -37.5129],
          [143.8465, -37.51321],
          [143.84647, -37.51352],
          [143.84632, -37.51422],
          [143.84615, -37.51475],
          [143.84609, -37.51489],
          [143.84596, -37.5152],
          [143.84581, -37.51551],
          [143.84572, -37.51576],
          [143.84545, -37.51626],
          [143.84507, -37.51693],
          [143.84476, -37.51748],
          [143.84467, -37.51771],
          [143.84456, -37.51799],
          [143.84455, -37.51805],
          [143.84456, -37.51813],
          [143.84458, -37.51814],
          [143.84464, -37.5182],
          [143.84467, -37.51826],
          [143.84467, -37.51833],
          [143.84465, -37.5184],
          [143.8446, -37.51846],
          [143.84452, -37.5185],
          [143.84443, -37.51857],
          [143.84434, -37.5187],
          [143.84427, -37.51908],
          [143.84421, -37.51932],
          [143.84413, -37.51968],
          [143.84413, -37.51981],
          [143.84413, -37.52027],
          [143.84414, -37.52058],
          [143.84418, -37.52091],
          [143.84422, -37.52123],
          [143.84429, -37.52171],
          [143.84441, -37.52214],
          [143.84464, -37.52269],
          [143.84482, -37.52308],
          [143.84484, -37.52312],
          [143.84499, -37.52338],
          [143.84526, -37.5238],
          [143.84539, -37.52401],
          [143.84581, -37.52455],
          [143.84625, -37.52502],
          [143.84782, -37.52654],
          [143.84831, -37.52698],
          [143.84855, -37.52728],
          [143.84865, -37.52736],
          [143.84877, -37.52743],
          [143.84882, -37.52744],
          [143.84889, -37.52748],
          [143.84891, -37.5275],
          [143.84898, -37.52759],
          [143.84897, -37.52768],
          [143.84897, -37.52771],
          [143.84894, -37.52777],
          [143.84906, -37.52821],
          [143.84923, -37.52874],
          [143.84929, -37.5291],
          [143.84927, -37.52959],
          [143.84931, -37.5307],
          [143.84932, -37.53244],
          [143.84933, -37.53332],
          [143.84933, -37.53388],
          [143.84933, -37.53414],
          [143.84934, -37.53532],
          [143.84985, -37.53537],
          [143.85033, -37.53543],
          [143.8507, -37.53547],
        ],
        type: 'MultiPolygon',
      },
    },
    {
      type: 'Feature',
      properties: {
        fill: 'none',
        address: 'Geelong',
        time: '1h 10m',
      },
      geometry: {
        coordinates: [
          [144.35628, -38.1455],
          [144.35619, -38.1455],
          [144.35609, -38.14551],
          [144.35599, -38.14552],
          [144.35587, -38.14555],
          [144.35577, -38.14557],
          [144.3557, -38.14559],
          [144.35555, -38.1456],
          [144.35547, -38.14559],
          [144.35537, -38.14558],
          [144.35528, -38.14556],
          [144.35486, -38.14545],
          [144.3542, -38.14526],
          [144.35386, -38.14514],
          [144.3536, -38.14508],
          [144.35349, -38.14507],
          [144.35342, -38.14508],
          [144.35338, -38.14508],
          [144.35334, -38.14509],
          [144.3533, -38.14512],
          [144.35325, -38.14515],
          [144.35322, -38.1452],
          [144.35317, -38.14528],
          [144.35299, -38.1462],
          [144.35296, -38.14638],
          [144.35281, -38.14712],
          [144.35278, -38.14728],
          [144.35277, -38.14736],
          [144.35276, -38.14744],
          [144.35261, -38.14742],
          [144.35227, -38.14738],
          [144.35142, -38.14728],
          [144.35111, -38.14724],
          [144.3507, -38.14716],
          [144.35052, -38.14714],
          [144.3499, -38.14706],
          [144.34714, -38.14674],
          [144.34642, -38.14666],
          [144.34415, -38.14639],
          [144.34178, -38.14611],
          [144.34154, -38.14608],
          [144.34133, -38.14606],
          [144.34022, -38.14593],
          [144.33943, -38.14584],
          [144.33838, -38.14571],
          [144.33835, -38.14571],
          [144.33639, -38.14548],
          [144.33612, -38.14545],
          [144.33591, -38.14543],
          [144.33582, -38.14542],
          [144.33547, -38.14537],
          [144.33491, -38.14531],
          [144.33458, -38.14526],
          [144.33456, -38.14526],
          [144.33403, -38.1452],
          [144.33232, -38.14499],
          [144.33135, -38.14487],
          [144.33089, -38.14481],
          [144.33083, -38.14481],
          [144.32963, -38.14466],
          [144.3294, -38.14463],
          [144.32908, -38.14459],
          [144.32865, -38.14454],
          [144.32818, -38.14449],
          [144.32808, -38.14447],
          [144.32712, -38.14436],
          [144.32661, -38.1443],
          [144.32617, -38.14425],
          [144.32591, -38.14422],
          [144.32556, -38.14417],
          [144.32526, -38.14414],
          [144.32521, -38.14413],
          [144.32472, -38.14407],
          [144.32447, -38.14404],
          [144.32427, -38.14402],
          [144.32408, -38.144],
          [144.32391, -38.14396],
          [144.32378, -38.14393],
          [144.32365, -38.14387],
          [144.32354, -38.14377],
          [144.32345, -38.14365],
          [144.32336, -38.14351],
          [144.32325, -38.14326],
          [144.32315, -38.14306],
          [144.32272, -38.14214],
          [144.32264, -38.142],
          [144.32252, -38.14188],
          [144.32237, -38.14182],
          [144.32172, -38.14165],
          [144.32118, -38.14157],
          [144.32063, -38.14154],
          [144.32035, -38.14155],
          [144.32004, -38.1416],
          [144.31925, -38.14188],
          [144.31833, -38.14229],
          [144.31801, -38.1425],
          [144.31755, -38.14292],
          [144.31732, -38.14308],
          [144.31685, -38.14329],
          [144.3165, -38.14343],
          [144.31607, -38.14353],
          [144.31565, -38.14354],
          [144.31533, -38.14348],
          [144.31513, -38.14342],
          [144.31484, -38.14326],
          [144.31453, -38.143],
          [144.31368, -38.1423],
          [144.31264, -38.14145],
          [144.3122, -38.14128],
          [144.31177, -38.14116],
          [144.3116, -38.14115],
          [144.31142, -38.14115],
          [144.31124, -38.14115],
          [144.31064, -38.14127],
          [144.31027, -38.14147],
          [144.31015, -38.14154],
          [144.30955, -38.14189],
          [144.30947, -38.14192],
          [144.30909, -38.14209],
          [144.3086, -38.14226],
          [144.30816, -38.14238],
          [144.30805, -38.14241],
          [144.30794, -38.14244],
          [144.30776, -38.14248],
          [144.30764, -38.1425],
          [144.30743, -38.14252],
          [144.30709, -38.14254],
          [144.306, -38.14254],
          [144.30513, -38.14252],
          [144.30441, -38.14248],
          [144.30164, -38.14211],
          [144.30034, -38.142],
          [144.2991, -38.14184],
          [144.29847, -38.14178],
          [144.29799, -38.14174],
          [144.29743, -38.14167],
          [144.29638, -38.14154],
          [144.29629, -38.14153],
          [144.29567, -38.14145],
          [144.29472, -38.14133],
          [144.29455, -38.14131],
          [144.2942, -38.14127],
          [144.29331, -38.14116],
          [144.29268, -38.14107],
          [144.2922, -38.14098],
          [144.29172, -38.14086],
          [144.29133, -38.14073],
          [144.29089, -38.14056],
          [144.29057, -38.14041],
          [144.29007, -38.14013],
          [144.2885, -38.13919],
          [144.28842, -38.13915],
          [144.28843, -38.13907],
          [144.28842, -38.13901],
          [144.2884, -38.13893],
          [144.28836, -38.13884],
          [144.28831, -38.13874],
          [144.28658, -38.13627],
          [144.2864, -38.136],
          [144.28622, -38.13574],
          [144.28606, -38.13547],
          [144.28592, -38.13522],
          [144.28579, -38.13496],
          [144.285, -38.13316],
          [144.28488, -38.13287],
          [144.28477, -38.13261],
          [144.28461, -38.13227],
          [144.28446, -38.132],
          [144.28434, -38.13178],
          [144.28419, -38.13156],
          [144.28404, -38.13136],
          [144.28386, -38.13114],
          [144.2837, -38.13095],
          [144.28349, -38.13075],
          [144.2832, -38.13049],
          [144.27371, -38.12301],
          [144.27088, -38.12078],
          [144.27063, -38.12058],
          [144.27033, -38.12034],
          [144.27004, -38.1201],
          [144.26978, -38.11984],
          [144.26945, -38.11949],
          [144.26905, -38.11903],
          [144.2686, -38.11845],
          [144.26528, -38.11411],
          [144.26464, -38.11328],
          [144.26435, -38.11292],
          [144.26328, -38.11167],
          [144.25921, -38.10643],
          [144.25302, -38.09846],
          [144.25286, -38.09825],
          [144.2514, -38.09639],
          [144.24931, -38.0937],
          [144.24902, -38.09332],
          [144.24684, -38.0905],
          [144.24652, -38.09009],
          [144.24613, -38.08962],
          [144.24337, -38.08635],
          [144.23865, -38.08079],
          [144.23856, -38.08072],
          [144.2385, -38.08067],
          [144.23839, -38.08053],
          [144.23828, -38.08036],
          [144.23815, -38.08022],
          [144.23805, -38.08018],
          [144.23799, -38.08012],
          [144.23796, -38.08005],
          [144.23795, -38.07997],
          [144.23779, -38.07979],
          [144.23749, -38.07954],
          [144.2372, -38.07935],
          [144.23693, -38.07914],
          [144.2366, -38.07881],
          [144.23571, -38.07805],
          [144.23394, -38.07664],
          [144.23385, -38.07657],
          [144.23289, -38.07578],
          [144.23183, -38.07492],
          [144.2313, -38.07448],
          [144.23103, -38.07426],
          [144.22751, -38.07138],
          [144.22448, -38.06893],
          [144.22318, -38.06789],
          [144.2178, -38.0634],
          [144.21284, -38.05931],
          [144.2026, -38.05095],
          [144.20142, -38.04999],
          [144.20124, -38.04989],
          [144.20074, -38.04949],
          [144.20058, -38.04936],
          [144.20053, -38.04932],
          [144.2004, -38.0492],
          [144.20029, -38.0491],
          [144.20021, -38.04903],
          [144.20012, -38.04898],
          [144.20001, -38.04894],
          [144.19991, -38.04892],
          [144.19977, -38.04893],
          [144.19972, -38.04893],
          [144.19958, -38.04891],
          [144.19946, -38.04884],
          [144.19941, -38.04875],
          [144.1994, -38.04869],
          [144.19927, -38.04854],
          [144.19914, -38.04837],
          [144.19901, -38.04824],
          [144.19894, -38.04816],
          [144.19878, -38.04798],
          [144.1984, -38.04763],
          [144.19831, -38.04755],
          [144.19797, -38.04723],
          [144.19732, -38.0467],
          [144.19157, -38.04203],
          [144.18784, -38.03899],
          [144.18773, -38.0389],
          [144.18447, -38.03623],
          [144.17894, -38.0318],
          [144.17554, -38.02908],
          [144.17455, -38.02828],
          [144.17443, -38.02818],
          [144.17306, -38.02705],
          [144.17137, -38.0257],
          [144.17043, -38.02493],
          [144.16738, -38.02246],
          [144.16446, -38.02007],
          [144.1641, -38.01975],
          [144.16374, -38.01938],
          [144.16346, -38.01906],
          [144.16317, -38.01873],
          [144.16291, -38.01836],
          [144.16272, -38.01805],
          [144.16259, -38.01784],
          [144.1618, -38.01613],
          [144.16108, -38.01442],
          [144.1601, -38.01218],
          [144.15911, -38.00989],
          [144.15731, -38.0057],
          [144.15708, -38.00518],
          [144.15065, -37.99051],
          [144.14834, -37.98517],
          [144.14666, -37.98128],
          [144.14518, -37.97798],
          [144.1448, -37.97712],
          [144.14417, -37.97567],
          [144.14295, -37.97266],
          [144.14256, -37.9718],
          [144.14121, -37.96869],
          [144.13967, -37.96518],
          [144.13949, -37.96475],
          [144.1387, -37.96288],
          [144.13839, -37.96215],
          [144.13827, -37.9619],
          [144.13824, -37.96184],
          [144.13802, -37.9615],
          [144.13783, -37.9612],
          [144.13681, -37.95967],
          [144.13581, -37.95814],
          [144.13537, -37.95748],
          [144.13536, -37.95746],
          [144.13387, -37.95523],
          [144.13171, -37.95201],
          [144.12988, -37.94923],
          [144.12636, -37.94393],
          [144.12588, -37.94318],
          [144.12583, -37.94311],
          [144.11765, -37.93076],
          [144.11508, -37.92683],
          [144.11418, -37.92558],
          [144.11319, -37.92473],
          [144.10843, -37.92177],
          [144.10579, -37.92014],
          [144.10441, -37.91926],
          [144.10423, -37.91913],
          [144.10413, -37.91904],
          [144.10392, -37.91887],
          [144.09628, -37.91153],
          [144.09478, -37.91008],
          [144.0947, -37.91],
          [144.08899, -37.9045],
          [144.08557, -37.9009],
          [144.08448, -37.89975],
          [144.0804, -37.89522],
          [144.07967, -37.8944],
          [144.07924, -37.8939],
          [144.07885, -37.89333],
          [144.07857, -37.89277],
          [144.07843, -37.89235],
          [144.07832, -37.89184],
          [144.07825, -37.891],
          [144.07808, -37.88898],
          [144.078, -37.88678],
          [144.07783, -37.88417],
          [144.07763, -37.8807],
          [144.07751, -37.87695],
          [144.07727, -37.86828],
          [144.07717, -37.86438],
          [144.07712, -37.86267],
          [144.07708, -37.86228],
          [144.07704, -37.86196],
          [144.07699, -37.86166],
          [144.07692, -37.86139],
          [144.07678, -37.86092],
          [144.07652, -37.85996],
          [144.07627, -37.85901],
          [144.07607, -37.85801],
          [144.07601, -37.85764],
          [144.07598, -37.85739],
          [144.07596, -37.85728],
          [144.07594, -37.85697],
          [144.07595, -37.85672],
          [144.07596, -37.85648],
          [144.07604, -37.85539],
          [144.07624, -37.85246],
          [144.07628, -37.85205],
          [144.07652, -37.84898],
          [144.07654, -37.84878],
          [144.0767, -37.84681],
          [144.07687, -37.84561],
          [144.07702, -37.84469],
          [144.07706, -37.84444],
          [144.07725, -37.84329],
          [144.07743, -37.8421],
          [144.07746, -37.8419],
          [144.07747, -37.84176],
          [144.07747, -37.84156],
          [144.07745, -37.84148],
          [144.07742, -37.84136],
          [144.07733, -37.84121],
          [144.07722, -37.84111],
          [144.07705, -37.84098],
          [144.07689, -37.8409],
          [144.07667, -37.84082],
          [144.07644, -37.84079],
          [144.07499, -37.84064],
          [144.07232, -37.84043],
          [144.07112, -37.84036],
          [144.07042, -37.84023],
          [144.06937, -37.83977],
          [144.06919, -37.83967],
          [144.06903, -37.83957],
          [144.06819, -37.83903],
          [144.06598, -37.83762],
          [144.06444, -37.83668],
          [144.05992, -37.83388],
          [144.05717, -37.83211],
          [144.05479, -37.83071],
          [144.05349, -37.82991],
          [144.05226, -37.8289],
          [144.05176, -37.82834],
          [144.05064, -37.82601],
          [144.05018, -37.8249],
          [144.04878, -37.8215],
          [144.04746, -37.81825],
          [144.04697, -37.81704],
          [144.04689, -37.81685],
          [144.04657, -37.81604],
          [144.04629, -37.81545],
          [144.04603, -37.81498],
          [144.04563, -37.81445],
          [144.04501, -37.81375],
          [144.04189, -37.81039],
          [144.03739, -37.80552],
          [144.03364, -37.80154],
          [144.03269, -37.80032],
          [144.03237, -37.79969],
          [144.03223, -37.79923],
          [144.03181, -37.79732],
          [144.03116, -37.79436],
          [144.03084, -37.79299],
          [144.03077, -37.79262],
          [144.03074, -37.79242],
          [144.03073, -37.79236],
          [144.03071, -37.79206],
          [144.03039, -37.7864],
          [144.03035, -37.78582],
          [144.03031, -37.78526],
          [144.03027, -37.785],
          [144.03015, -37.7844],
          [144.02998, -37.78354],
          [144.02983, -37.78277],
          [144.02974, -37.78235],
          [144.02916, -37.77945],
          [144.02878, -37.77828],
          [144.02858, -37.77768],
          [144.02852, -37.77748],
          [144.0284, -37.77713],
          [144.02824, -37.77661],
          [144.02812, -37.77633],
          [144.02795, -37.776],
          [144.02783, -37.77582],
          [144.02754, -37.77549],
          [144.02745, -37.77539],
          [144.02742, -37.77537],
          [144.02718, -37.77511],
          [144.02712, -37.77506],
          [144.02684, -37.77476],
          [144.02683, -37.77475],
          [144.02653, -37.77444],
          [144.02599, -37.77387],
          [144.02536, -37.77321],
          [144.02484, -37.77265],
          [144.02334, -37.77108],
          [144.02307, -37.77081],
          [144.02274, -37.77052],
          [144.02246, -37.77028],
          [144.02208, -37.77],
          [144.02163, -37.76969],
          [144.02109, -37.76937],
          [144.02067, -37.76915],
          [144.02015, -37.76892],
          [144.01816, -37.76808],
          [144.01695, -37.76758],
          [144.01657, -37.76742],
          [144.01622, -37.76725],
          [144.01584, -37.76706],
          [144.01557, -37.7669],
          [144.01526, -37.76672],
          [144.01485, -37.76643],
          [144.01444, -37.76613],
          [144.01418, -37.76592],
          [144.014, -37.76575],
          [144.0138, -37.76557],
          [144.01329, -37.76506],
          [144.01252, -37.76423],
          [144.01012, -37.76173],
          [144.0079, -37.75939],
          [144.0055, -37.75687],
          [144.00494, -37.75628],
          [144.00448, -37.75576],
          [144.00396, -37.75509],
          [144.00342, -37.75434],
          [144.00295, -37.75366],
          [144.00232, -37.75275],
          [144.00201, -37.75231],
          [144.00161, -37.75173],
          [143.99926, -37.74833],
          [143.9978, -37.7462],
          [143.99581, -37.7433],
          [143.99458, -37.74146],
          [143.99415, -37.74081],
          [143.99321, -37.73907],
          [143.99278, -37.73835],
          [143.99237, -37.73781],
          [143.99114, -37.73629],
          [143.98941, -37.73415],
          [143.98804, -37.73249],
          [143.98635, -37.73045],
          [143.986, -37.72998],
          [143.98571, -37.72954],
          [143.98549, -37.72911],
          [143.98532, -37.72871],
          [143.98506, -37.72801],
          [143.98438, -37.72605],
          [143.98343, -37.72318],
          [143.98264, -37.72078],
          [143.98247, -37.72013],
          [143.98238, -37.71956],
          [143.98238, -37.71906],
          [143.98241, -37.71859],
          [143.9825, -37.71815],
          [143.98263, -37.71773],
          [143.98271, -37.71755],
          [143.9828, -37.71732],
          [143.98301, -37.71689],
          [143.98331, -37.71631],
          [143.98358, -37.71582],
          [143.98384, -37.71532],
          [143.98403, -37.71493],
          [143.98418, -37.71456],
          [143.9843, -37.71418],
          [143.98439, -37.71381],
          [143.98446, -37.71331],
          [143.98447, -37.71296],
          [143.98446, -37.71258],
          [143.98443, -37.71222],
          [143.98433, -37.7118],
          [143.98426, -37.71154],
          [143.98398, -37.71085],
          [143.98369, -37.71026],
          [143.98296, -37.70877],
          [143.98259, -37.708],
          [143.98238, -37.70756],
          [143.982, -37.7067],
          [143.98114, -37.70499],
          [143.98015, -37.70341],
          [143.97966, -37.70267],
          [143.97889, -37.70153],
          [143.97877, -37.70134],
          [143.97711, -37.69909],
          [143.97638, -37.6981],
          [143.97603, -37.69763],
          [143.97578, -37.6973],
          [143.97475, -37.69592],
          [143.97413, -37.69508],
          [143.97318, -37.69407],
          [143.97286, -37.69373],
          [143.97008, -37.69128],
          [143.96769, -37.68919],
          [143.96672, -37.68838],
          [143.96571, -37.68786],
          [143.96426, -37.68723],
          [143.96346, -37.68693],
          [143.9619, -37.68636],
          [143.96077, -37.68585],
          [143.9596, -37.6852],
          [143.95846, -37.68418],
          [143.95778, -37.68348],
          [143.95712, -37.68248],
          [143.95668, -37.68176],
          [143.95532, -37.67943],
          [143.95354, -37.67633],
          [143.95298, -37.67547],
          [143.9525, -37.67488],
          [143.95177, -37.67433],
          [143.95146, -37.67409],
          [143.9501, -37.67347],
          [143.94943, -37.67322],
          [143.94869, -37.67302],
          [143.94789, -37.67289],
          [143.94735, -37.67284],
          [143.94424, -37.67268],
          [143.9402, -37.67251],
          [143.93925, -37.67245],
          [143.93831, -37.67231],
          [143.93698, -37.67203],
          [143.93434, -37.67118],
          [143.93261, -37.67065],
          [143.92995, -37.66984],
          [143.92953, -37.66976],
          [143.92913, -37.66973],
          [143.92832, -37.66971],
          [143.92661, -37.66967],
          [143.92564, -37.66961],
          [143.92499, -37.66951],
          [143.92408, -37.66933],
          [143.92325, -37.66909],
          [143.92244, -37.66876],
          [143.92088, -37.66792],
          [143.91995, -37.66721],
          [143.91938, -37.66666],
          [143.91876, -37.66607],
          [143.91721, -37.66415],
          [143.91581, -37.66242],
          [143.91519, -37.66165],
          [143.91457, -37.66098],
          [143.91387, -37.66026],
          [143.91346, -37.6599],
          [143.913, -37.65951],
          [143.91237, -37.65904],
          [143.9117, -37.65858],
          [143.91151, -37.65846],
          [143.91125, -37.65829],
          [143.91112, -37.65822],
          [143.91068, -37.65795],
          [143.91061, -37.65792],
          [143.91023, -37.65771],
          [143.90986, -37.65751],
          [143.90928, -37.65723],
          [143.90887, -37.65704],
          [143.9087, -37.65696],
          [143.90601, -37.65568],
          [143.90306, -37.6543],
          [143.90243, -37.65401],
          [143.90194, -37.65381],
          [143.90148, -37.65363],
          [143.90114, -37.65349],
          [143.90145, -37.65157],
          [143.90159, -37.65127],
          [143.9018, -37.65099],
          [143.90201, -37.65083],
          [143.90229, -37.65066],
          [143.90929, -37.64756],
          [143.91005, -37.64717],
          [143.91096, -37.64663],
          [143.91123, -37.6465],
          [143.91153, -37.64639],
          [143.91201, -37.64627],
          [143.91237, -37.64616],
          [143.9127, -37.64602],
          [143.91312, -37.64582],
          [143.9129, -37.6449],
          [143.91268, -37.64381],
          [143.91294, -37.64334],
          [143.91356, -37.64232],
          [143.91389, -37.64217],
          [143.91445, -37.64207],
          [143.91453, -37.64205],
          [143.91504, -37.6392],
          [143.91506, -37.63902],
          [143.91507, -37.63883],
          [143.91505, -37.63864],
          [143.91502, -37.63844],
          [143.91496, -37.63824],
          [143.91488, -37.63804],
          [143.91476, -37.63783],
          [143.91463, -37.63762],
          [143.9137, -37.63622],
          [143.91333, -37.63561],
          [143.91323, -37.63536],
          [143.91317, -37.63512],
          [143.91301, -37.63418],
          [143.91293, -37.63192],
          [143.91294, -37.63148],
          [143.91301, -37.63093],
          [143.91305, -37.63064],
          [143.91311, -37.63035],
          [143.91319, -37.63002],
          [143.9133, -37.62967],
          [143.91342, -37.62931],
          [143.9135, -37.629],
          [143.91356, -37.62869],
          [143.91359, -37.62845],
          [143.91358, -37.62817],
          [143.91356, -37.62788],
          [143.91352, -37.62759],
          [143.91346, -37.62733],
          [143.91337, -37.62705],
          [143.91325, -37.62678],
          [143.91259, -37.62574],
          [143.91176, -37.62468],
          [143.91036, -37.62279],
          [143.91019, -37.62252],
          [143.91006, -37.62228],
          [143.90992, -37.622],
          [143.90982, -37.62175],
          [143.90976, -37.62158],
          [143.90974, -37.62151],
          [143.90969, -37.62126],
          [143.90963, -37.62098],
          [143.9096, -37.62072],
          [143.90959, -37.62046],
          [143.90957, -37.62009],
          [143.9096, -37.61962],
          [143.91025, -37.61646],
          [143.91151, -37.60926],
          [143.91214, -37.60564],
          [143.91249, -37.60367],
          [143.91273, -37.60201],
          [143.91274, -37.60198],
          [143.91274, -37.60195],
          [143.91306, -37.60021],
          [143.91343, -37.59822],
          [143.91407, -37.59445],
          [143.91429, -37.59319],
          [143.91455, -37.59165],
          [143.91509, -37.58877],
          [143.91553, -37.58629],
          [143.91579, -37.5848],
          [143.91586, -37.58439],
          [143.91591, -37.58419],
          [143.91597, -37.58404],
          [143.91606, -37.58392],
          [143.91616, -37.58383],
          [143.91625, -37.58375],
          [143.91639, -37.58368],
          [143.91632, -37.58359],
          [143.91624, -37.58349],
          [143.91614, -37.58334],
          [143.91605, -37.58317],
          [143.91597, -37.583],
          [143.9159, -37.5828],
          [143.91582, -37.58258],
          [143.91572, -37.5822],
          [143.91561, -37.5818],
          [143.91545, -37.58123],
          [143.91535, -37.58089],
          [143.91523, -37.58051],
          [143.91513, -37.58026],
          [143.91501, -37.58004],
          [143.91494, -37.57993],
          [143.91481, -37.57975],
          [143.9147, -37.57961],
          [143.91479, -37.57957],
          [143.91489, -37.57954],
          [143.91496, -37.57953],
          [143.91518, -37.57954],
          [143.9179, -37.57985],
          [143.9181, -37.57986],
          [143.91829, -37.57984],
          [143.9185, -37.57979],
          [143.91867, -37.57973],
          [143.91881, -37.57965],
          [143.91897, -37.57953],
          [143.91911, -37.57937],
          [143.91961, -37.57859],
          [143.92026, -37.57742],
          [143.92056, -37.57691],
          [143.92074, -37.57661],
          [143.92085, -37.57647],
          [143.92105, -37.57628],
          [143.92124, -37.57614],
          [143.92145, -37.57601],
          [143.92168, -37.57589],
          [143.92193, -37.5758],
          [143.92223, -37.57572],
          [143.92372, -37.57537],
          [143.92476, -37.57513],
          [143.92508, -37.57505],
          [143.92529, -37.57499],
          [143.92605, -37.57465],
          [143.92961, -37.57274],
          [143.93075, -37.57214],
          [143.93139, -37.57181],
          [143.93178, -37.57159],
          [143.93194, -37.5715],
          [143.93339, -37.57],
          [143.93404, -37.56934],
          [143.93416, -37.56919],
          [143.93424, -37.56907],
          [143.93429, -37.56891],
          [143.93428, -37.56878],
          [143.93428, -37.56868],
          [143.93428, -37.56859],
          [143.9343, -37.56849],
          [143.93434, -37.56842],
          [143.9344, -37.56836],
          [143.93449, -37.56832],
          [143.93461, -37.56826],
          [143.93457, -37.56818],
          [143.93451, -37.56808],
          [143.93441, -37.56789],
          [143.93434, -37.56772],
          [143.93427, -37.56751],
          [143.93419, -37.56725],
          [143.93411, -37.56692],
          [143.93399, -37.56635],
          [143.93393, -37.56609],
          [143.93386, -37.56582],
          [143.93378, -37.56564],
          [143.93369, -37.56547],
          [143.93359, -37.56528],
          [143.93347, -37.5651],
          [143.93338, -37.56496],
          [143.93327, -37.56483],
          [143.93308, -37.56464],
          [143.9328, -37.56439],
          [143.93273, -37.56432],
          [143.93267, -37.56424],
          [143.93264, -37.56418],
          [143.93262, -37.56412],
          [143.9326, -37.56401],
          [143.9326, -37.56394],
          [143.93262, -37.56381],
          [143.93263, -37.5637],
          [143.93237, -37.56364],
          [143.93212, -37.56357],
          [143.93185, -37.56349],
          [143.9316, -37.56342],
          [143.9313, -37.56332],
          [143.93086, -37.56319],
          [143.92996, -37.56292],
          [143.92581, -37.56166],
          [143.92566, -37.56162],
          [143.92518, -37.56147],
          [143.92399, -37.56112],
          [143.92296, -37.5608],
          [143.92231, -37.56061],
          [143.92175, -37.56044],
          [143.92133, -37.56032],
          [143.92048, -37.56009],
          [143.91962, -37.55987],
          [143.91921, -37.55976],
          [143.91887, -37.55967],
          [143.91851, -37.55956],
          [143.91827, -37.55947],
          [143.91807, -37.55939],
          [143.91786, -37.5593],
          [143.91766, -37.5592],
          [143.91746, -37.5591],
          [143.91724, -37.55897],
          [143.917, -37.55883],
          [143.91683, -37.55871],
          [143.91663, -37.55857],
          [143.91554, -37.55778],
          [143.91418, -37.55678],
          [143.91331, -37.55607],
          [143.91297, -37.55577],
          [143.91253, -37.55535],
          [143.9121, -37.55493],
          [143.91147, -37.55419],
          [143.91114, -37.55377],
          [143.91084, -37.55335],
          [143.91014, -37.55247],
          [143.90974, -37.552],
          [143.90931, -37.55155],
          [143.90899, -37.55126],
          [143.90853, -37.55091],
          [143.90805, -37.55055],
          [143.90754, -37.55022],
          [143.90729, -37.55009],
          [143.90689, -37.54988],
          [143.90627, -37.54957],
          [143.90493, -37.54906],
          [143.90406, -37.54882],
          [143.90356, -37.5487],
          [143.90315, -37.54863],
          [143.90226, -37.54848],
          [143.9002, -37.54817],
          [143.89929, -37.548],
          [143.89851, -37.54782],
          [143.8977, -37.5476],
          [143.89696, -37.54736],
          [143.89599, -37.54698],
          [143.89502, -37.54658],
          [143.89404, -37.54607],
          [143.89309, -37.54553],
          [143.89154, -37.54453],
          [143.89037, -37.54377],
          [143.88954, -37.54322],
          [143.88835, -37.54254],
          [143.88776, -37.54223],
          [143.88477, -37.5407],
          [143.88241, -37.53948],
          [143.88024, -37.53817],
          [143.87883, -37.53721],
          [143.87741, -37.53616],
          [143.87647, -37.5354],
          [143.87566, -37.53468],
          [143.87393, -37.53308],
          [143.87149, -37.53039],
          [143.86999, -37.52859],
          [143.86974, -37.52826],
          [143.86945, -37.52789],
          [143.86882, -37.52722],
          [143.86797, -37.52641],
          [143.86715, -37.5257],
          [143.86615, -37.52497],
          [143.86497, -37.5242],
          [143.86347, -37.52338],
          [143.86238, -37.52288],
          [143.86092, -37.52233],
          [143.85908, -37.52177],
          [143.85824, -37.52158],
          [143.85754, -37.52143],
          [143.85683, -37.5213],
          [143.85613, -37.52119],
          [143.85548, -37.52112],
          [143.85484, -37.52106],
          [143.85344, -37.52101],
          [143.85168, -37.52103],
          [143.85106, -37.52105],
          [143.85065, -37.52106],
          [143.85013, -37.52107],
          [143.84934, -37.52113],
          [143.84785, -37.52122],
          [143.84755, -37.52126],
          [143.84709, -37.52137],
          [143.84671, -37.52152],
          [143.84637, -37.5217],
          [143.84609, -37.5219],
          [143.8458, -37.52215],
          [143.84542, -37.52246],
          [143.84519, -37.52274],
          [143.84511, -37.52291],
          [143.84507, -37.52307],
          [143.84508, -37.52322],
          [143.84515, -37.52344],
          [143.84526, -37.5238],
          [143.84539, -37.52401],
          [143.84581, -37.52455],
          [143.84625, -37.52502],
          [143.84782, -37.52654],
          [143.84831, -37.52698],
          [143.84855, -37.52728],
          [143.84865, -37.52736],
          [143.84877, -37.52743],
          [143.84882, -37.52744],
          [143.84889, -37.52748],
          [143.84891, -37.5275],
          [143.84898, -37.52759],
          [143.84897, -37.52768],
          [143.84897, -37.52771],
          [143.84894, -37.52777],
          [143.84906, -37.52821],
          [143.84923, -37.52874],
          [143.84929, -37.5291],
          [143.84927, -37.52959],
          [143.84931, -37.5307],
          [143.84932, -37.53244],
          [143.84933, -37.53332],
          [143.84933, -37.53388],
          [143.84933, -37.53414],
          [143.84934, -37.53532],
          [143.84985, -37.53537],
          [143.85033, -37.53543],
          [143.85051, -37.53545],
        ],
        type: 'MultiPolygon',
      },
    },
    {
      type: 'Feature',
      properties: {
        fill: 'none',
        address: 'Warrnambool',
        time: '2h 00m',
      },
      geometry: {
        coordinates: [
          [142.47267, -38.38734],
          [142.47265, -38.38732],
          [142.47258, -38.38725],
          [142.47249, -38.3872],
          [142.47241, -38.38716],
          [142.47222, -38.38712],
          [142.47186, -38.38706],
          [142.47153, -38.38701],
          [142.4713, -38.38697],
          [142.47119, -38.38696],
          [142.47113, -38.38696],
          [142.47096, -38.38696],
          [142.47094, -38.38686],
          [142.47091, -38.38675],
          [142.47087, -38.38665],
          [142.47082, -38.38653],
          [142.47075, -38.3864],
          [142.47064, -38.38621],
          [142.47033, -38.38567],
          [142.47026, -38.38554],
          [142.47022, -38.38545],
          [142.4702, -38.38536],
          [142.4702, -38.38527],
          [142.4702, -38.38521],
          [142.47022, -38.38511],
          [142.47024, -38.38503],
          [142.47027, -38.38494],
          [142.47031, -38.38483],
          [142.47038, -38.38464],
          [142.47043, -38.38449],
          [142.47046, -38.38438],
          [142.47048, -38.38429],
          [142.47047, -38.38423],
          [142.47084, -38.3842],
          [142.47154, -38.38415],
          [142.47174, -38.38414],
          [142.47195, -38.38413],
          [142.47237, -38.38412],
          [142.47242, -38.38412],
          [142.47346, -38.38409],
          [142.47363, -38.38409],
          [142.47371, -38.38409],
          [142.47396, -38.38411],
          [142.47399, -38.38411],
          [142.4741, -38.38413],
          [142.47417, -38.38415],
          [142.47429, -38.38419],
          [142.47445, -38.38427],
          [142.4753, -38.38466],
          [142.47643, -38.38519],
          [142.47812, -38.38594],
          [142.47874, -38.38622],
          [142.47896, -38.38631],
          [142.47909, -38.38635],
          [142.47921, -38.38638],
          [142.47933, -38.38641],
          [142.47944, -38.38642],
          [142.47956, -38.38643],
          [142.47967, -38.38644],
          [142.47978, -38.38645],
          [142.4799, -38.38645],
          [142.47999, -38.38646],
          [142.48008, -38.38647],
          [142.48017, -38.38649],
          [142.48026, -38.38652],
          [142.48034, -38.38655],
          [142.48039, -38.38657],
          [142.48044, -38.3866],
          [142.4805, -38.38664],
          [142.48057, -38.38669],
          [142.48063, -38.38675],
          [142.4807, -38.38684],
          [142.48075, -38.38691],
          [142.48078, -38.38695],
          [142.48081, -38.38699],
          [142.48084, -38.38702],
          [142.48087, -38.38704],
          [142.4809, -38.38705],
          [142.48091, -38.38706],
          [142.48095, -38.38707],
          [142.48098, -38.38708],
          [142.48099, -38.38709],
          [142.481, -38.3871],
          [142.48101, -38.38713],
          [142.48104, -38.38713],
          [142.48112, -38.38715],
          [142.48118, -38.3872],
          [142.48121, -38.38724],
          [142.48124, -38.38728],
          [142.48128, -38.38731],
          [142.48132, -38.38733],
          [142.48136, -38.38736],
          [142.48141, -38.38737],
          [142.48148, -38.3874],
          [142.48154, -38.38743],
          [142.4816, -38.38746],
          [142.48189, -38.38757],
          [142.48212, -38.38767],
          [142.48292, -38.38803],
          [142.48302, -38.38807],
          [142.4831, -38.3881],
          [142.48316, -38.38812],
          [142.4832, -38.38812],
          [142.48324, -38.38813],
          [142.48332, -38.38816],
          [142.48334, -38.38815],
          [142.48338, -38.38811],
          [142.48342, -38.38808],
          [142.48346, -38.38803],
          [142.48376, -38.38762],
          [142.48416, -38.38707],
          [142.48446, -38.38667],
          [142.4845, -38.38662],
          [142.48451, -38.38659],
          [142.48452, -38.38656],
          [142.48456, -38.38651],
          [142.48456, -38.3865],
          [142.48457, -38.38647],
          [142.48458, -38.38645],
          [142.4846, -38.38644],
          [142.48462, -38.38642],
          [142.48463, -38.38642],
          [142.48465, -38.38641],
          [142.48477, -38.3863],
          [142.48489, -38.38613],
          [142.48518, -38.38573],
          [142.48584, -38.38481],
          [142.48587, -38.38473],
          [142.48586, -38.38469],
          [142.48587, -38.38467],
          [142.48589, -38.38464],
          [142.4859, -38.38462],
          [142.48593, -38.3846],
          [142.48597, -38.38459],
          [142.48599, -38.38459],
          [142.48601, -38.38459],
          [142.48609, -38.38453],
          [142.48668, -38.3837],
          [142.48713, -38.38305],
          [142.48717, -38.383],
          [142.48721, -38.38291],
          [142.4872, -38.38289],
          [142.4872, -38.38288],
          [142.48721, -38.38284],
          [142.48724, -38.3828],
          [142.48726, -38.38279],
          [142.48728, -38.38278],
          [142.4873, -38.38277],
          [142.48733, -38.38277],
          [142.48745, -38.38265],
          [142.48751, -38.38256],
          [142.48761, -38.38241],
          [142.48791, -38.38195],
          [142.48792, -38.38192],
          [142.48797, -38.38185],
          [142.4882, -38.38153],
          [142.48824, -38.38147],
          [142.48854, -38.38107],
          [142.48882, -38.38065],
          [142.48886, -38.38059],
          [142.48889, -38.38054],
          [142.48892, -38.38051],
          [142.48922, -38.38012],
          [142.4893, -38.38001],
          [142.48972, -38.37951],
          [142.48984, -38.37935],
          [142.49126, -38.37738],
          [142.49179, -38.37671],
          [142.49186, -38.37656],
          [142.49191, -38.37639],
          [142.49195, -38.37615],
          [142.49195, -38.3761],
          [142.49204, -38.37551],
          [142.49218, -38.37464],
          [142.49224, -38.37427],
          [142.49225, -38.37415],
          [142.49225, -38.37407],
          [142.49226, -38.374],
          [142.49224, -38.37396],
          [142.49223, -38.37386],
          [142.49221, -38.37385],
          [142.49218, -38.37382],
          [142.49216, -38.37379],
          [142.49215, -38.37376],
          [142.49214, -38.37373],
          [142.49215, -38.37372],
          [142.49215, -38.37369],
          [142.49217, -38.37366],
          [142.49218, -38.37364],
          [142.49221, -38.37362],
          [142.49223, -38.3736],
          [142.49225, -38.3736],
          [142.49232, -38.37355],
          [142.49238, -38.37349],
          [142.49243, -38.37343],
          [142.49246, -38.37337],
          [142.49248, -38.37332],
          [142.49251, -38.37317],
          [142.49256, -38.37294],
          [142.49262, -38.37259],
          [142.49265, -38.3724],
          [142.49277, -38.37164],
          [142.49281, -38.37142],
          [142.49304, -38.37],
          [142.49306, -38.36984],
          [142.49307, -38.36977],
          [142.49309, -38.36972],
          [142.49327, -38.36842],
          [142.49328, -38.36833],
          [142.49329, -38.36822],
          [142.49336, -38.36785],
          [142.49339, -38.36767],
          [142.49351, -38.36731],
          [142.49363, -38.36706],
          [142.49368, -38.36694],
          [142.49373, -38.36684],
          [142.49384, -38.36666],
          [142.49394, -38.36653],
          [142.49424, -38.36612],
          [142.49431, -38.36603],
          [142.49452, -38.36579],
          [142.49461, -38.36567],
          [142.49521, -38.36492],
          [142.49593, -38.36402],
          [142.49597, -38.36396],
          [142.49613, -38.36376],
          [142.4962, -38.36369],
          [142.49636, -38.36349],
          [142.49673, -38.36308],
          [142.49773, -38.36179],
          [142.49801, -38.36144],
          [142.49812, -38.36128],
          [142.49879, -38.36038],
          [142.50093, -38.35763],
          [142.50142, -38.35694],
          [142.50151, -38.35683],
          [142.50307, -38.35494],
          [142.50534, -38.35212],
          [142.50551, -38.35183],
          [142.50555, -38.35173],
          [142.50564, -38.35151],
          [142.50577, -38.35114],
          [142.50592, -38.3505],
          [142.50609, -38.3498],
          [142.50615, -38.34965],
          [142.50624, -38.34942],
          [142.50647, -38.34902],
          [142.5066, -38.34882],
          [142.50675, -38.34863],
          [142.50688, -38.34849],
          [142.50708, -38.3483],
          [142.50809, -38.34721],
          [142.51, -38.34519],
          [142.51193, -38.34316],
          [142.51313, -38.34185],
          [142.5132, -38.34175],
          [142.51326, -38.34166],
          [142.51329, -38.34159],
          [142.51333, -38.34152],
          [142.51335, -38.34147],
          [142.51339, -38.34134],
          [142.51342, -38.34116],
          [142.51341, -38.34103],
          [142.51333, -38.34066],
          [142.51209, -38.33831],
          [142.5108, -38.33606],
          [142.51028, -38.33524],
          [142.50971, -38.33431],
          [142.50898, -38.3328],
          [142.50792, -38.33033],
          [142.50652, -38.32735],
          [142.50637, -38.32702],
          [142.50566, -38.32541],
          [142.50558, -38.32515],
          [142.50555, -38.32493],
          [142.50555, -38.32468],
          [142.50557, -38.32446],
          [142.50562, -38.32424],
          [142.5057, -38.32399],
          [142.50579, -38.32375],
          [142.506, -38.32345],
          [142.50628, -38.32309],
          [142.50648, -38.32291],
          [142.50674, -38.3227],
          [142.50692, -38.32257],
          [142.50712, -38.32247],
          [142.50751, -38.32229],
          [142.50815, -38.32201],
          [142.51431, -38.31947],
          [142.52439, -38.31532],
          [142.53092, -38.31262],
          [142.54535, -38.30663],
          [142.55977, -38.30069],
          [142.56353, -38.29913],
          [142.57103, -38.29602],
          [142.58127, -38.29181],
          [142.59144, -38.2876],
          [142.59564, -38.28589],
          [142.59983, -38.2842],
          [142.6012, -38.28364],
          [142.60314, -38.28287],
          [142.60744, -38.28112],
          [142.60815, -38.28083],
          [142.60869, -38.28066],
          [142.60905, -38.28059],
          [142.60926, -38.28056],
          [142.60968, -38.28053],
          [142.60983, -38.28054],
          [142.61005, -38.28054],
          [142.61156, -38.28085],
          [142.6132, -38.28126],
          [142.61403, -38.28145],
          [142.6147, -38.2816],
          [142.61503, -38.28166],
          [142.61536, -38.28167],
          [142.61567, -38.28167],
          [142.61608, -38.28164],
          [142.61648, -38.28161],
          [142.61674, -38.28155],
          [142.61718, -38.28143],
          [142.61763, -38.28127],
          [142.61859, -38.28085],
          [142.619, -38.28067],
          [142.62002, -38.28022],
          [142.62032, -38.28009],
          [142.62048, -38.28001],
          [142.62302, -38.27889],
          [142.62409, -38.27842],
          [142.62473, -38.27812],
          [142.62485, -38.27805],
          [142.62505, -38.27793],
          [142.62531, -38.27777],
          [142.62563, -38.27751],
          [142.62596, -38.2772],
          [142.62623, -38.2769],
          [142.62649, -38.27658],
          [142.62707, -38.27586],
          [142.62739, -38.2755],
          [142.62764, -38.27526],
          [142.6278, -38.27512],
          [142.62799, -38.27497],
          [142.62824, -38.27482],
          [142.62856, -38.27464],
          [142.62902, -38.27441],
          [142.62943, -38.27427],
          [142.62994, -38.27414],
          [142.63006, -38.27412],
          [142.63054, -38.27405],
          [142.63177, -38.27396],
          [142.63351, -38.27386],
          [142.63511, -38.27372],
          [142.63603, -38.27359],
          [142.63656, -38.27343],
          [142.63705, -38.27325],
          [142.63748, -38.27305],
          [142.63779, -38.27284],
          [142.63816, -38.27256],
          [142.63846, -38.27233],
          [142.63886, -38.27192],
          [142.63925, -38.27155],
          [142.64117, -38.26973],
          [142.64152, -38.26935],
          [142.64178, -38.26904],
          [142.64205, -38.26866],
          [142.64229, -38.26819],
          [142.64319, -38.26653],
          [142.64556, -38.26205],
          [142.64637, -38.26055],
          [142.64838, -38.25685],
          [142.65101, -38.25339],
          [142.6533, -38.25037],
          [142.6537, -38.2497],
          [142.65407, -38.24893],
          [142.6547, -38.24738],
          [142.65606, -38.24416],
          [142.65618, -38.24381],
          [142.65627, -38.24345],
          [142.65633, -38.24312],
          [142.65635, -38.24266],
          [142.65626, -38.24199],
          [142.65603, -38.24053],
          [142.65586, -38.23948],
          [142.65583, -38.23901],
          [142.65586, -38.23858],
          [142.6559, -38.23832],
          [142.65642, -38.23643],
          [142.65708, -38.23411],
          [142.6579, -38.23093],
          [142.65802, -38.2304],
          [142.65804, -38.22957],
          [142.6578, -38.22611],
          [142.6576, -38.22299],
          [142.65762, -38.22239],
          [142.65783, -38.2204],
          [142.6581, -38.21774],
          [142.65812, -38.21728],
          [142.6581, -38.21688],
          [142.65803, -38.21608],
          [142.65788, -38.21545],
          [142.65766, -38.2149],
          [142.6573, -38.21417],
          [142.65565, -38.2116],
          [142.65547, -38.21112],
          [142.65532, -38.21043],
          [142.65466, -38.20696],
          [142.65457, -38.20625],
          [142.65439, -38.20522],
          [142.65389, -38.20243],
          [142.65356, -38.2006],
          [142.65334, -38.19937],
          [142.65302, -38.19762],
          [142.65272, -38.19592],
          [142.65249, -38.19457],
          [142.65233, -38.19369],
          [142.65222, -38.19309],
          [142.65212, -38.19261],
          [142.65203, -38.19223],
          [142.65189, -38.19182],
          [142.65162, -38.19103],
          [142.6515, -38.19074],
          [142.65134, -38.19019],
          [142.6512, -38.18969],
          [142.6511, -38.18929],
          [142.65097, -38.18859],
          [142.65089, -38.18808],
          [142.6507, -38.18697],
          [142.65045, -38.18556],
          [142.65041, -38.18512],
          [142.6504, -38.18481],
          [142.65043, -38.18452],
          [142.65046, -38.18435],
          [142.65055, -38.184],
          [142.65072, -38.18355],
          [142.65092, -38.18317],
          [142.65121, -38.18273],
          [142.65154, -38.18229],
          [142.6526, -38.18085],
          [142.65378, -38.17924],
          [142.65501, -38.17755],
          [142.65518, -38.17734],
          [142.65542, -38.17705],
          [142.65571, -38.17674],
          [142.65594, -38.17652],
          [142.65644, -38.1761],
          [142.65667, -38.17591],
          [142.65733, -38.17539],
          [142.65823, -38.17471],
          [142.661, -38.17256],
          [142.66369, -38.17049],
          [142.6651, -38.16939],
          [142.66626, -38.16847],
          [142.66675, -38.16803],
          [142.66714, -38.16764],
          [142.66748, -38.16727],
          [142.66786, -38.16681],
          [142.66807, -38.16655],
          [142.66832, -38.16619],
          [142.66861, -38.16572],
          [142.66887, -38.1653],
          [142.66915, -38.16485],
          [142.67017, -38.16315],
          [142.67113, -38.16153],
          [142.67223, -38.15969],
          [142.67323, -38.15803],
          [142.67364, -38.15734],
          [142.67389, -38.15692],
          [142.67444, -38.15602],
          [142.67489, -38.15527],
          [142.67551, -38.15453],
          [142.67623, -38.15389],
          [142.67812, -38.15275],
          [142.6802, -38.15151],
          [142.68058, -38.15133],
          [142.6813, -38.15098],
          [142.6819, -38.15073],
          [142.68274, -38.15039],
          [142.68334, -38.15015],
          [142.68399, -38.14988],
          [142.6842, -38.1498],
          [142.68471, -38.14958],
          [142.68543, -38.14928],
          [142.68593, -38.14906],
          [142.68663, -38.14878],
          [142.68727, -38.14851],
          [142.68773, -38.14832],
          [142.6883, -38.1481],
          [142.68921, -38.14777],
          [142.68998, -38.14751],
          [142.69049, -38.14732],
          [142.6924, -38.14664],
          [142.69358, -38.14622],
          [142.69489, -38.14577],
          [142.69607, -38.14534],
          [142.69664, -38.14513],
          [142.69693, -38.145],
          [142.69718, -38.14486],
          [142.6974, -38.14473],
          [142.69765, -38.14456],
          [142.6979, -38.14436],
          [142.69823, -38.14406],
          [142.69844, -38.14384],
          [142.69874, -38.1435],
          [142.69898, -38.14321],
          [142.69988, -38.14212],
          [142.70064, -38.14122],
          [142.70158, -38.1401],
          [142.70367, -38.13762],
          [142.70497, -38.13606],
          [142.70554, -38.13539],
          [142.70582, -38.13507],
          [142.70618, -38.1347],
          [142.70651, -38.1344],
          [142.7068, -38.13415],
          [142.70709, -38.13393],
          [142.70821, -38.13311],
          [142.7098, -38.13196],
          [142.71195, -38.13039],
          [142.71251, -38.13],
          [142.71292, -38.12973],
          [142.71336, -38.12946],
          [142.71382, -38.12919],
          [142.7143, -38.12893],
          [142.71583, -38.12814],
          [142.71798, -38.12702],
          [142.72311, -38.12437],
          [142.72609, -38.12285],
          [142.72836, -38.12167],
          [142.73106, -38.12029],
          [142.73144, -38.12012],
          [142.73175, -38.11999],
          [142.73275, -38.11961],
          [142.7342, -38.11908],
          [142.73543, -38.11862],
          [142.73711, -38.11798],
          [142.73785, -38.11771],
          [142.74026, -38.1168],
          [142.74291, -38.11582],
          [142.74519, -38.11496],
          [142.7475, -38.11411],
          [142.75012, -38.11314],
          [142.7513, -38.11271],
          [142.75325, -38.11198],
          [142.75484, -38.11138],
          [142.75703, -38.11057],
          [142.7588, -38.1099],
          [142.75967, -38.1096],
          [142.76193, -38.10875],
          [142.76442, -38.10782],
          [142.76538, -38.10749],
          [142.76602, -38.1073],
          [142.76657, -38.10716],
          [142.76701, -38.10706],
          [142.76802, -38.10683],
          [142.76869, -38.10667],
          [142.76967, -38.10645],
          [142.77017, -38.10632],
          [142.77065, -38.10617],
          [142.77104, -38.10602],
          [142.77141, -38.10587],
          [142.77187, -38.10563],
          [142.77219, -38.10545],
          [142.77247, -38.10525],
          [142.77272, -38.10506],
          [142.77301, -38.10482],
          [142.77322, -38.10463],
          [142.77392, -38.10399],
          [142.7764, -38.10168],
          [142.77828, -38.09993],
          [142.78001, -38.09833],
          [142.78245, -38.09605],
          [142.78269, -38.0958],
          [142.78297, -38.09547],
          [142.78331, -38.09504],
          [142.78356, -38.09464],
          [142.78402, -38.09389],
          [142.786, -38.09057],
          [142.78679, -38.08927],
          [142.78703, -38.0889],
          [142.78725, -38.08859],
          [142.78749, -38.08828],
          [142.79071, -38.08447],
          [142.79087, -38.08429],
          [142.79424, -38.08032],
          [142.79458, -38.07993],
          [142.79497, -38.07948],
          [142.79507, -38.07938],
          [142.79514, -38.0793],
          [142.79528, -38.07917],
          [142.79538, -38.07921],
          [142.79547, -38.07925],
          [142.79559, -38.0793],
          [142.79571, -38.07935],
          [142.79584, -38.07939],
          [142.79597, -38.07942],
          [142.79612, -38.07946],
          [142.7964, -38.07951],
          [142.7981, -38.07976],
          [142.80017, -38.08007],
          [142.80111, -38.08021],
          [142.80183, -38.08027],
          [142.80278, -38.08037],
          [142.80539, -38.08077],
          [142.80763, -38.08108],
          [142.80776, -38.08108],
          [142.8078, -38.08104],
          [142.80784, -38.081],
          [142.8079, -38.08098],
          [142.80794, -38.08098],
          [142.80799, -38.08097],
          [142.80807, -38.08099],
          [142.80814, -38.08104],
          [142.80819, -38.0811],
          [142.80832, -38.08104],
          [142.809, -38.08066],
          [142.80971, -38.08024],
          [142.80986, -38.08015],
          [142.80996, -38.0801],
          [142.81186, -38.07905],
          [142.81305, -38.07838],
          [142.81408, -38.07781],
          [142.81743, -38.07607],
          [142.81806, -38.07574],
          [142.81892, -38.07531],
          [142.81951, -38.07501],
          [142.8201, -38.07471],
          [142.82078, -38.07435],
          [142.82259, -38.07341],
          [142.82388, -38.07275],
          [142.83489, -38.06707],
          [142.83498, -38.06702],
          [142.83535, -38.06683],
          [142.84044, -38.06422],
          [142.84081, -38.06406],
          [142.84111, -38.06395],
          [142.84134, -38.06387],
          [142.84156, -38.0638],
          [142.84179, -38.06373],
          [142.84226, -38.06361],
          [142.84688, -38.06247],
          [142.85372, -38.06079],
          [142.86105, -38.059],
          [142.88461, -38.0532],
          [142.88892, -38.05217],
          [142.89107, -38.05202],
          [142.89687, -38.05257],
          [142.89927, -38.05282],
          [142.90108, -38.05282],
          [142.90271, -38.05251],
          [142.91004, -38.04966],
          [142.91014, -38.04962],
          [142.91537, -38.04746],
          [142.92179, -38.04489],
          [142.92821, -38.04225],
          [142.93106, -38.04134],
          [142.94155, -38.03854],
          [142.94481, -38.03759],
          [142.94663, -38.03695],
          [142.94955, -38.03564],
          [142.96553, -38.0285],
          [142.9848, -38.0199],
          [143.00173, -38.0122],
          [143.00558, -38.01055],
          [143.00729, -38.00991],
          [143.00921, -38.00938],
          [143.00953, -38.00931],
          [143.00972, -38.00927],
          [143.01239, -38.00883],
          [143.01332, -38.00865],
          [143.01427, -38.00842],
          [143.02569, -38.00482],
          [143.02708, -38.00439],
          [143.03536, -38.00148],
          [143.03588, -38.00129],
          [143.03887, -38.00025],
          [143.03944, -38.00006],
          [143.03986, -37.99995],
          [143.04005, -37.99991],
          [143.04024, -37.99987],
          [143.04085, -37.99978],
          [143.04223, -37.99963],
          [143.04304, -37.99955],
          [143.04392, -37.99944],
          [143.04443, -37.99934],
          [143.04473, -37.99926],
          [143.04506, -37.99915],
          [143.04532, -37.99904],
          [143.04558, -37.9989],
          [143.04585, -37.99874],
          [143.04706, -37.99797],
          [143.0479, -37.99748],
          [143.04827, -37.99729],
          [143.04853, -37.99719],
          [143.04883, -37.99712],
          [143.04926, -37.99705],
          [143.04959, -37.99703],
          [143.04999, -37.99705],
          [143.05148, -37.99722],
          [143.05283, -37.99737],
          [143.05514, -37.99765],
          [143.05538, -37.99768],
          [143.05816, -37.998],
          [143.06005, -37.99821],
          [143.06815, -37.9989],
          [143.06893, -37.99898],
          [143.06948, -37.99904],
          [143.06994, -37.99913],
          [143.07043, -37.99925],
          [143.07094, -37.99942],
          [143.07403, -38.00048],
          [143.07451, -38.00064],
          [143.07517, -38.00081],
          [143.07583, -38.00094],
          [143.07634, -38.00102],
          [143.07687, -38.00105],
          [143.07773, -38.00106],
          [143.07836, -38.00103],
          [143.07903, -38.00095],
          [143.07961, -38.00083],
          [143.08032, -38.00064],
          [143.08082, -38.00047],
          [143.08143, -38.00024],
          [143.09318, -37.99506],
          [143.10332, -37.99053],
          [143.1206, -37.98283],
          [143.12708, -37.97992],
          [143.12918, -37.979],
          [143.13245, -37.97747],
          [143.13502, -37.97617],
          [143.13768, -37.97479],
          [143.14429, -37.97126],
          [143.1475, -37.96957],
          [143.14837, -37.96915],
          [143.1493, -37.96874],
          [143.15568, -37.96596],
          [143.15628, -37.96567],
          [143.15666, -37.96545],
          [143.15704, -37.96521],
          [143.15747, -37.96489],
          [143.1597, -37.96295],
          [143.16179, -37.9611],
          [143.16307, -37.95996],
          [143.16363, -37.95955],
          [143.16408, -37.95925],
          [143.16469, -37.95892],
          [143.16521, -37.9587],
          [143.16592, -37.95841],
          [143.17395, -37.95539],
          [143.17561, -37.95478],
          [143.17639, -37.95453],
          [143.17713, -37.95432],
          [143.17865, -37.95397],
          [143.18011, -37.95362],
          [143.18103, -37.95336],
          [143.18355, -37.95253],
          [143.18727, -37.95134],
          [143.18779, -37.95121],
          [143.18852, -37.95108],
          [143.18975, -37.95095],
          [143.19023, -37.9509],
          [143.19903, -37.95023],
          [143.19968, -37.95016],
          [143.20061, -37.95003],
          [143.20156, -37.94986],
          [143.20239, -37.94971],
          [143.20333, -37.94956],
          [143.20421, -37.94948],
          [143.20568, -37.94937],
          [143.20852, -37.94913],
          [143.20878, -37.94911],
          [143.21138, -37.9489],
          [143.21211, -37.94885],
          [143.21293, -37.94882],
          [143.21314, -37.94882],
          [143.2133, -37.94882],
          [143.21467, -37.94886],
          [143.21525, -37.94888],
          [143.21587, -37.94887],
          [143.21627, -37.94885],
          [143.21674, -37.94881],
          [143.21828, -37.94867],
          [143.22001, -37.94853],
          [143.22098, -37.94846],
          [143.22261, -37.94833],
          [143.22349, -37.94826],
          [143.22409, -37.94822],
          [143.22519, -37.94813],
          [143.22583, -37.94808],
          [143.22673, -37.94801],
          [143.22731, -37.94796],
          [143.22741, -37.94796],
          [143.22865, -37.94785],
          [143.23449, -37.94739],
          [143.23579, -37.9473],
          [143.2372, -37.94728],
          [143.23748, -37.94728],
          [143.23765, -37.94728],
          [143.24573, -37.94746],
          [143.24907, -37.94749],
          [143.25035, -37.94745],
          [143.25574, -37.94706],
          [143.25981, -37.94678],
          [143.26563, -37.94639],
          [143.26812, -37.9462],
          [143.2698, -37.9462],
          [143.27201, -37.94645],
          [143.28222, -37.94773],
          [143.2861, -37.94823],
          [143.28842, -37.94836],
          [143.29313, -37.94857],
          [143.29675, -37.94874],
          [143.29785, -37.9488],
          [143.29883, -37.94896],
          [143.30494, -37.95009],
          [143.30663, -37.95042],
          [143.30852, -37.95062],
          [143.31173, -37.95084],
          [143.31604, -37.95112],
          [143.3165, -37.95116],
          [143.31688, -37.95118],
          [143.32637, -37.95189],
          [143.33148, -37.95252],
          [143.33292, -37.9527],
          [143.33368, -37.9528],
          [143.33463, -37.95292],
          [143.33556, -37.95302],
          [143.33592, -37.95305],
          [143.33604, -37.95304],
          [143.33625, -37.95303],
          [143.33651, -37.953],
          [143.33708, -37.95288],
          [143.33838, -37.95263],
          [143.3385, -37.95261],
          [143.33883, -37.9526],
          [143.33897, -37.95261],
          [143.33911, -37.95263],
          [143.33939, -37.95268],
          [143.3411, -37.95304],
          [143.34147, -37.95311],
          [143.34202, -37.95322],
          [143.34365, -37.95355],
          [143.34577, -37.95399],
          [143.34599, -37.95402],
          [143.34616, -37.95403],
          [143.34634, -37.95401],
          [143.34648, -37.95397],
          [143.34662, -37.95391],
          [143.34674, -37.95383],
          [143.34687, -37.95372],
          [143.34736, -37.95309],
          [143.34742, -37.95301],
          [143.3478, -37.95254],
          [143.348, -37.9523],
          [143.3482, -37.95206],
          [143.34846, -37.95176],
          [143.34879, -37.95147],
          [143.34913, -37.95125],
          [143.34959, -37.95105],
          [143.35006, -37.95088],
          [143.35231, -37.95022],
          [143.35338, -37.9499],
          [143.35399, -37.94973],
          [143.35453, -37.9496],
          [143.35529, -37.94949],
          [143.35583, -37.94946],
          [143.35635, -37.94948],
          [143.36322, -37.95024],
          [143.36428, -37.95036],
          [143.3721, -37.95124],
          [143.37261, -37.9513],
          [143.37406, -37.95144],
          [143.37478, -37.95154],
          [143.37565, -37.9517],
          [143.3787, -37.95225],
          [143.37957, -37.9524],
          [143.3808, -37.95262],
          [143.38318, -37.9531],
          [143.38382, -37.95325],
          [143.38463, -37.95346],
          [143.38539, -37.95364],
          [143.38921, -37.95433],
          [143.38969, -37.95435],
          [143.38994, -37.95434],
          [143.39011, -37.9543],
          [143.3902, -37.95427],
          [143.39028, -37.95424],
          [143.39035, -37.95421],
          [143.39049, -37.95414],
          [143.39068, -37.95403],
          [143.39083, -37.9539],
          [143.39167, -37.95335],
          [143.39323, -37.95212],
          [143.39601, -37.94994],
          [143.39881, -37.94774],
          [143.40313, -37.94436],
          [143.4078, -37.9407],
          [143.42684, -37.92579],
          [143.42701, -37.92564],
          [143.44682, -37.9101],
          [143.44688, -37.91006],
          [143.44757, -37.9095],
          [143.44791, -37.90916],
          [143.44822, -37.90877],
          [143.44841, -37.90846],
          [143.44915, -37.90704],
          [143.44934, -37.90673],
          [143.44957, -37.9064],
          [143.44988, -37.90605],
          [143.45041, -37.90558],
          [143.46244, -37.89648],
          [143.46265, -37.89632],
          [143.47025, -37.89059],
          [143.48149, -37.88207],
          [143.49043, -37.87533],
          [143.51167, -37.8593],
          [143.5135, -37.85795],
          [143.51509, -37.85673],
          [143.52006, -37.85321],
          [143.52029, -37.85304],
          [143.52704, -37.84822],
          [143.53883, -37.83966],
          [143.54622, -37.83429],
          [143.55549, -37.82756],
          [143.5624, -37.82253],
          [143.56315, -37.82166],
          [143.56467, -37.81881],
          [143.56553, -37.81732],
          [143.56597, -37.81675],
          [143.56644, -37.81632],
          [143.56693, -37.81596],
          [143.56756, -37.8156],
          [143.56806, -37.81537],
          [143.5687, -37.81516],
          [143.5698, -37.81485],
          [143.57419, -37.81361],
          [143.57478, -37.81339],
          [143.57528, -37.81315],
          [143.57589, -37.8128],
          [143.57652, -37.81237],
          [143.58215, -37.80826],
          [143.58233, -37.80808],
          [143.5825, -37.8079],
          [143.58259, -37.80777],
          [143.58279, -37.80741],
          [143.58285, -37.8073],
          [143.58352, -37.80741],
          [143.58406, -37.8075],
          [143.58439, -37.80757],
          [143.58482, -37.80768],
          [143.5851, -37.80777],
          [143.58538, -37.80788],
          [143.58574, -37.80805],
          [143.58606, -37.80821],
          [143.58651, -37.80846],
          [143.58835, -37.80942],
          [143.58895, -37.80968],
          [143.58954, -37.8099],
          [143.59028, -37.81013],
          [143.59276, -37.81082],
          [143.5932, -37.81098],
          [143.5937, -37.81121],
          [143.5943, -37.81151],
          [143.59458, -37.81167],
          [143.59543, -37.81205],
          [143.59593, -37.81217],
          [143.59637, -37.81223],
          [143.59649, -37.81224],
          [143.59696, -37.81226],
          [143.59759, -37.8122],
          [143.59814, -37.81208],
          [143.59866, -37.81188],
          [143.59877, -37.81183],
          [143.59954, -37.81133],
          [143.60196, -37.8085],
          [143.60452, -37.80551],
          [143.60491, -37.805],
          [143.60515, -37.8046],
          [143.60534, -37.80412],
          [143.60547, -37.80348],
          [143.60572, -37.80189],
          [143.60702, -37.79371],
          [143.60709, -37.79335],
          [143.60723, -37.79286],
          [143.60736, -37.79254],
          [143.6075, -37.79226],
          [143.60765, -37.79202],
          [143.60791, -37.79165],
          [143.60818, -37.79139],
          [143.60842, -37.79118],
          [143.61133, -37.78861],
          [143.61155, -37.78836],
          [143.61183, -37.7881],
          [143.6119, -37.78802],
          [143.61211, -37.78767],
          [143.61261, -37.78692],
          [143.61284, -37.78657],
          [143.61332, -37.78584],
          [143.61549, -37.78238],
          [143.61587, -37.78192],
          [143.61634, -37.78156],
          [143.62376, -37.77568],
          [143.62406, -37.77536],
          [143.62431, -37.77503],
          [143.62448, -37.7747],
          [143.62461, -37.7744],
          [143.62465, -37.77426],
          [143.62475, -37.77396],
          [143.62499, -37.77316],
          [143.62525, -37.77215],
          [143.62533, -37.77177],
          [143.62537, -37.77145],
          [143.62538, -37.77111],
          [143.62533, -37.77073],
          [143.62527, -37.77041],
          [143.62525, -37.77033],
          [143.62516, -37.76985],
          [143.62368, -37.76348],
          [143.62329, -37.76202],
          [143.62323, -37.76159],
          [143.62324, -37.76109],
          [143.62327, -37.76045],
          [143.62347, -37.75763],
          [143.62353, -37.75713],
          [143.62364, -37.75668],
          [143.62375, -37.75629],
          [143.62424, -37.75497],
          [143.62555, -37.75137],
          [143.62577, -37.75076],
          [143.62603, -37.75022],
          [143.62635, -37.74977],
          [143.62682, -37.74923],
          [143.62726, -37.74885],
          [143.6279, -37.74837],
          [143.63138, -37.74622],
          [143.63258, -37.74548],
          [143.63456, -37.74425],
          [143.6353, -37.74377],
          [143.63583, -37.74338],
          [143.63636, -37.74285],
          [143.63761, -37.74136],
          [143.638, -37.7409],
          [143.63808, -37.74082],
          [143.63909, -37.73971],
          [143.64168, -37.73734],
          [143.64522, -37.73099],
          [143.64593, -37.72972],
          [143.64707, -37.72879],
          [143.65293, -37.72537],
          [143.65388, -37.72421],
          [143.65438, -37.72361],
          [143.65468, -37.72294],
          [143.65485, -37.72257],
          [143.655, -37.72146],
          [143.65485, -37.71944],
          [143.655, -37.71774],
          [143.65522, -37.71716],
          [143.65555, -37.71633],
          [143.65652, -37.71462],
          [143.65692, -37.7134],
          [143.6567, -37.7118],
          [143.65555, -37.7085],
          [143.65456, -37.70564],
          [143.65456, -37.70429],
          [143.65492, -37.70325],
          [143.65584, -37.70181],
          [143.65614, -37.70112],
          [143.65628, -37.70043],
          [143.65628, -37.69915],
          [143.65618, -37.69792],
          [143.65584, -37.69322],
          [143.65584, -37.69269],
          [143.65575, -37.69105],
          [143.65552, -37.6869],
          [143.65549, -37.6864],
          [143.65533, -37.68418],
          [143.6553, -37.68388],
          [143.65513, -37.68121],
          [143.65497, -37.6786],
          [143.65486, -37.67763],
          [143.65464, -37.67286],
          [143.65457, -37.67175],
          [143.65452, -37.67072],
          [143.65446, -37.66973],
          [143.65444, -37.66947],
          [143.65438, -37.66932],
          [143.65431, -37.66922],
          [143.65403, -37.66902],
          [143.65419, -37.66887],
          [143.65445, -37.66861],
          [143.65472, -37.66828],
          [143.65492, -37.66802],
          [143.65498, -37.66793],
          [143.65522, -37.6676],
          [143.65592, -37.66647],
          [143.65675, -37.66516],
          [143.65706, -37.66465],
          [143.65793, -37.66327],
          [143.65953, -37.66072],
          [143.66011, -37.65991],
          [143.66091, -37.65904],
          [143.66197, -37.65794],
          [143.66307, -37.65681],
          [143.66436, -37.6555],
          [143.66474, -37.65519],
          [143.66514, -37.65489],
          [143.667, -37.65372],
          [143.66743, -37.65347],
          [143.66874, -37.65274],
          [143.66934, -37.65249],
          [143.67045, -37.65204],
          [143.67072, -37.65193],
          [143.67396, -37.6507],
          [143.67512, -37.65025],
          [143.67616, -37.64998],
          [143.67743, -37.6497],
          [143.67887, -37.64947],
          [143.67966, -37.64936],
          [143.68025, -37.64928],
          [143.68053, -37.64925],
          [143.68078, -37.6492],
          [143.68113, -37.6491],
          [143.68131, -37.64901],
          [143.68152, -37.64891],
          [143.68178, -37.64876],
          [143.68196, -37.64862],
          [143.68222, -37.64841],
          [143.68233, -37.6483],
          [143.68247, -37.6481],
          [143.68261, -37.64788],
          [143.6827, -37.64767],
          [143.68279, -37.64741],
          [143.68284, -37.6472],
          [143.68298, -37.64676],
          [143.68308, -37.64652],
          [143.68318, -37.64632],
          [143.68331, -37.64614],
          [143.68348, -37.64596],
          [143.68395, -37.64552],
          [143.68406, -37.64541],
          [143.68417, -37.64528],
          [143.68423, -37.6452],
          [143.6844, -37.64497],
          [143.68508, -37.64403],
          [143.6852, -37.64386],
          [143.68538, -37.64365],
          [143.68548, -37.64353],
          [143.68572, -37.64329],
          [143.68613, -37.64296],
          [143.68937, -37.64021],
          [143.68957, -37.64004],
          [143.69183, -37.63816],
          [143.69254, -37.63739],
          [143.6935, -37.63618],
          [143.69383, -37.6358],
          [143.69587, -37.63353],
          [143.70077, -37.62783],
          [143.70232, -37.62607],
          [143.70588, -37.62184],
          [143.70651, -37.62128],
          [143.70902, -37.61917],
          [143.71012, -37.61843],
          [143.71116, -37.61782],
          [143.71204, -37.61738],
          [143.71396, -37.61656],
          [143.71431, -37.61639],
          [143.71486, -37.61617],
          [143.71555, -37.61585],
          [143.71619, -37.6156],
          [143.71698, -37.61535],
          [143.71788, -37.61512],
          [143.71877, -37.61497],
          [143.71968, -37.61489],
          [143.721, -37.61488],
          [143.72213, -37.61493],
          [143.72327, -37.61488],
          [143.72432, -37.61471],
          [143.7252, -37.61435],
          [143.72613, -37.61394],
          [143.72693, -37.61327],
          [143.72777, -37.61252],
          [143.72824, -37.61206],
          [143.72875, -37.61165],
          [143.72931, -37.61129],
          [143.72998, -37.61093],
          [143.73041, -37.61076],
          [143.73105, -37.61056],
          [143.73142, -37.61048],
          [143.73209, -37.61036],
          [143.7345, -37.61011],
          [143.73465, -37.61009],
          [143.73502, -37.61005],
          [143.73866, -37.60918],
          [143.73979, -37.6089],
          [143.74044, -37.60875],
          [143.74167, -37.60844],
          [143.74253, -37.60806],
          [143.74476, -37.60695],
          [143.74557, -37.60656],
          [143.74625, -37.60625],
          [143.74681, -37.60605],
          [143.74742, -37.60594],
          [143.74801, -37.60583],
          [143.74837, -37.60581],
          [143.74887, -37.60579],
          [143.74959, -37.60581],
          [143.75023, -37.6059],
          [143.75221, -37.60633],
          [143.75301, -37.60644],
          [143.75394, -37.60647],
          [143.75466, -37.60641],
          [143.75538, -37.60624],
          [143.75599, -37.60609],
          [143.75631, -37.60596],
          [143.75693, -37.6057],
          [143.75743, -37.60541],
          [143.758, -37.60499],
          [143.75902, -37.60419],
          [143.75979, -37.6036],
          [143.76136, -37.60235],
          [143.76815, -37.597],
          [143.76846, -37.59679],
          [143.76889, -37.5965],
          [143.77378, -37.59336],
          [143.7744, -37.59305],
          [143.77504, -37.59282],
          [143.77613, -37.59258],
          [143.77821, -37.59212],
          [143.78139, -37.59143],
          [143.78643, -37.59031],
          [143.79146, -37.58925],
          [143.79224, -37.58923],
          [143.79265, -37.58923],
          [143.79311, -37.58927],
          [143.79358, -37.58933],
          [143.79408, -37.58942],
          [143.79766, -37.59033],
          [143.79808, -37.59042],
          [143.79862, -37.59052],
          [143.79917, -37.59057],
          [143.79972, -37.5906],
          [143.80117, -37.59055],
          [143.8026, -37.59051],
          [143.8039, -37.59047],
          [143.80526, -37.59044],
          [143.8054, -37.59042],
          [143.80597, -37.59037],
          [143.80619, -37.59034],
          [143.80632, -37.59031],
          [143.80639, -37.59025],
          [143.80648, -37.59012],
          [143.80657, -37.58987],
          [143.8069, -37.58816],
          [143.80726, -37.58621],
          [143.80737, -37.58546],
          [143.8075, -37.58483],
          [143.80745, -37.58458],
          [143.80743, -37.58456],
          [143.80741, -37.58454],
          [143.8074, -37.58451],
          [143.80739, -37.58449],
          [143.8074, -37.58446],
          [143.80741, -37.58443],
          [143.80742, -37.58441],
          [143.80744, -37.58438],
          [143.80747, -37.58437],
          [143.80755, -37.58423],
          [143.80761, -37.58414],
          [143.80782, -37.58311],
          [143.80791, -37.58266],
          [143.80818, -37.58116],
          [143.80856, -37.57919],
          [143.80861, -37.57893],
          [143.80863, -37.57867],
          [143.80863, -37.57854],
          [143.80861, -37.5785],
          [143.80861, -37.57847],
          [143.80862, -37.57843],
          [143.80864, -37.5784],
          [143.80869, -37.57837],
          [143.80871, -37.57832],
          [143.80875, -37.57822],
          [143.80877, -37.57811],
          [143.80877, -37.578],
          [143.80878, -37.57785],
          [143.80906, -37.57633],
          [143.80938, -37.57464],
          [143.80982, -37.57229],
          [143.81, -37.57123],
          [143.81009, -37.57078],
          [143.81014, -37.57058],
          [143.81021, -37.57044],
          [143.81028, -37.57033],
          [143.81033, -37.57017],
          [143.81033, -37.57],
          [143.8103, -37.56992],
          [143.8103, -37.56991],
          [143.81028, -37.56988],
          [143.81027, -37.56984],
          [143.81028, -37.56981],
          [143.8103, -37.56977],
          [143.81032, -37.56975],
          [143.81033, -37.56974],
          [143.81038, -37.56966],
          [143.81043, -37.56954],
          [143.81046, -37.56945],
          [143.81051, -37.56932],
          [143.81059, -37.56915],
          [143.81085, -37.56795],
          [143.81189, -37.56217],
          [143.81205, -37.56129],
          [143.81206, -37.56115],
          [143.81245, -37.55907],
          [143.81248, -37.55894],
          [143.81257, -37.55845],
          [143.81259, -37.55834],
          [143.81271, -37.55768],
          [143.81285, -37.55688],
          [143.81293, -37.55648],
          [143.813, -37.5561],
          [143.81307, -37.55566],
          [143.81307, -37.5555],
          [143.81307, -37.55542],
          [143.81307, -37.55533],
          [143.81304, -37.55531],
          [143.81303, -37.55529],
          [143.81302, -37.55526],
          [143.81302, -37.55524],
          [143.81303, -37.55521],
          [143.81305, -37.55519],
          [143.81308, -37.55517],
          [143.81311, -37.55516],
          [143.81311, -37.55512],
          [143.81316, -37.55504],
          [143.81322, -37.55487],
          [143.81328, -37.55453],
          [143.81337, -37.55418],
          [143.81351, -37.55379],
          [143.81356, -37.55369],
          [143.8136, -37.5536],
          [143.8136, -37.55359],
          [143.81359, -37.55356],
          [143.81359, -37.55353],
          [143.81359, -37.55351],
          [143.81362, -37.55346],
          [143.81365, -37.55343],
          [143.81369, -37.55341],
          [143.81375, -37.55339],
          [143.81378, -37.55338],
          [143.81382, -37.55338],
          [143.81391, -37.55341],
          [143.81395, -37.55344],
          [143.81399, -37.55348],
          [143.814, -37.5535],
          [143.81406, -37.55355],
          [143.8141, -37.55358],
          [143.81414, -37.55361],
          [143.81428, -37.55368],
          [143.81471, -37.55386],
          [143.81566, -37.55426],
          [143.81635, -37.55455],
          [143.81694, -37.5548],
          [143.81706, -37.55485],
          [143.81764, -37.55509],
          [143.81798, -37.55525],
          [143.81862, -37.55554],
          [143.81883, -37.55556],
          [143.81893, -37.55555],
          [143.81906, -37.55551],
          [143.81909, -37.55542],
          [143.81915, -37.55521],
          [143.81929, -37.55426],
          [143.81932, -37.55406],
          [143.81935, -37.55387],
          [143.81937, -37.55368],
          [143.81944, -37.55332],
          [143.81946, -37.55324],
          [143.81951, -37.55295],
          [143.81971, -37.55192],
          [143.81997, -37.5507],
          [143.82, -37.55058],
          [143.81996, -37.55056],
          [143.81993, -37.55053],
          [143.81991, -37.55049],
          [143.81991, -37.55046],
          [143.81993, -37.55041],
          [143.81997, -37.55038],
          [143.82005, -37.55019],
          [143.82073, -37.54661],
          [143.82113, -37.54456],
          [143.8215, -37.54268],
          [143.82157, -37.54231],
          [143.82169, -37.54165],
          [143.82183, -37.54091],
          [143.82185, -37.54084],
          [143.82187, -37.54073],
          [143.82201, -37.54006],
          [143.82202, -37.54001],
          [143.82205, -37.53987],
          [143.82209, -37.53952],
          [143.82215, -37.53913],
          [143.82216, -37.53902],
          [143.82218, -37.53889],
          [143.82224, -37.53856],
          [143.82228, -37.53833],
          [143.82239, -37.53774],
          [143.82271, -37.53612],
          [143.82281, -37.53558],
          [143.82283, -37.53546],
          [143.823, -37.53456],
          [143.82306, -37.5343],
          [143.8232, -37.53356],
          [143.82323, -37.53343],
          [143.82326, -37.5333],
          [143.82341, -37.53251],
          [143.82343, -37.53233],
          [143.82341, -37.5323],
          [143.8234, -37.53227],
          [143.8234, -37.53223],
          [143.82342, -37.53218],
          [143.82345, -37.53215],
          [143.82347, -37.53214],
          [143.82353, -37.53212],
          [143.8236, -37.53212],
          [143.82365, -37.53214],
          [143.82367, -37.53216],
          [143.82388, -37.53219],
          [143.8242, -37.53224],
          [143.82571, -37.53242],
          [143.82593, -37.53244],
          [143.8275, -37.53263],
          [143.82813, -37.53271],
          [143.82969, -37.5329],
          [143.83232, -37.5332],
          [143.83242, -37.53321],

          [143.83251, -37.5332],
          [143.83256, -37.53316],
          [143.83262, -37.53316],
          [143.83268, -37.53317],
          [143.83274, -37.53319],
          [143.83277, -37.53323],
          [143.83298, -37.53329],
          [143.83478, -37.5335],
          [143.83549, -37.53359],
          [143.83586, -37.53363],
          [143.83689, -37.53376],
          [143.83774, -37.53386],
          [143.83875, -37.53398],
          [143.8399, -37.53414],
          [143.84081, -37.53426],
          [143.84092, -37.53426],
          [143.84102, -37.53426],
          [143.84113, -37.53425],
          [143.84116, -37.53422],
          [143.84119, -37.53421],
          [143.84121, -37.5342],
          [143.84125, -37.53419],
          [143.84129, -37.53419],
          [143.84132, -37.53419],
          [143.84136, -37.5342],
          [143.84139, -37.53422],
          [143.84142, -37.53424],
          [143.84144, -37.53427],
          [143.84159, -37.53431],
          [143.84171, -37.53434],
          [143.84213, -37.53442],
          [143.84452, -37.53472],
          [143.84466, -37.53474],
          [143.84726, -37.53506],
          [143.84882, -37.53526],
          [143.84934, -37.53532],
          [143.84985, -37.53537],
          [143.85033, -37.53543],
          [143.8505, -37.53545],
          [143.85013, -37.52107],
          [143.84934, -37.52113],
          [143.84785, -37.52122],
          [143.84755, -37.52126],
          // [143.84709, -37.52137],
        ],
        type: 'MultiPolygon',
      },
    },
    {
      type: 'Feature',
      properties: {
        fill: 'none',
        address: 'Adelaide',
        time: '6h 45m',
      },
      geometry: {
        coordinates: [
          [138.602539, -34.928448],
          [138.604406, -34.92836],
          [138.606001, -34.928286],
          [138.608215, -34.928173],
          [138.608421, -34.928162],
          [138.611776, -34.927991],
          [138.612076, -34.927974],
          [138.612116, -34.928546],
          [138.612173, -34.929389],
          [138.612185, -34.929581],
          [138.612233, -34.930233],
          [138.612405, -34.932375],
          [138.612493, -34.9337],
          [138.612583, -34.935057],
          [138.612722, -34.937579],
          [138.612914, -34.938768],
          [138.61367, -34.939244],
          [138.615331, -34.940289],
          [138.61579, -34.940561],
          [138.616512, -34.941052],
          [138.618058, -34.942034],
          [138.619762, -34.943089],
          [138.62079, -34.943715],
          [138.620993, -34.943842],
          [138.621166, -34.943944],
          [138.623668, -34.945484],
          [138.624721, -34.94611],
          [138.625002, -34.946272],
          [138.625263, -34.946433],
          [138.625835, -34.946774],
          [138.626655, -34.947259],
          [138.629731, -34.949044],
          [138.633228, -34.951076],
          [138.634346, -34.95178],
          [138.634542, -34.951959],
          [138.635784, -34.953443],
          [138.637475, -34.955493],
          [138.638333, -34.956516],
          [138.639697, -34.958153],
          [138.640948, -34.959663],
          [138.641573, -34.960436],
          [138.642253, -34.961235],
          [138.642536, -34.961542],
          [138.643467, -34.962681],
          [138.643889, -34.963133],
          [138.644125, -34.963365],
          [138.644456, -34.963621],
          [138.645421, -34.96417],
          [138.646093, -34.964491],
          [138.647239, -34.965003],
          [138.647621, -34.965267],
          [138.648126, -34.965749],
          [138.648405, -34.966158],
          [138.648931, -34.967002],
          [138.649478, -34.967867],
          [138.64999, -34.968754],
          [138.650242, -34.969721],
          [138.650348, -34.970689],
          [138.650714, -34.971596],
          [138.651435, -34.972336],
          [138.652432, -34.972834],
          [138.653575, -34.973017],
          [138.654726, -34.972874],
          [138.655741, -34.972417],
          [138.65696, -34.97158],
          [138.65773, -34.971103],
          [138.658569, -34.970604],
          [138.659541, -34.970054],
          [138.660521, -34.969526],
          [138.661597, -34.969044],
          [138.66302, -34.96856],
          [138.664089, -34.968182],
          [138.665122, -34.96769],
          [138.665818, -34.9674],
          [138.66694, -34.967113],
          [138.66811, -34.967032],
          [138.669271, -34.967162],
          [138.670386, -34.967472],
          [138.671297, -34.967832],
          [138.672531, -34.968456],
          [138.673344, -34.968956],
          [138.67406, -34.969469],
          [138.674869, -34.970181],
          [138.675558, -34.970971],
          [138.676127, -34.971831],
          [138.676553, -34.97273],
          [138.677276, -34.974295],
          [138.678478, -34.975701],
          [138.6802, -34.976796],
          [138.681247, -34.977224],
          [138.682049, -34.977567],
          [138.682901, -34.978066],
          [138.683653, -34.978774],
          [138.684155, -34.979634],
          [138.684365, -34.980571],
          [138.684333, -34.981589],
          [138.684404, -34.982313],
          [138.684746, -34.983195],
          [138.685387, -34.984025],
          [138.686266, -34.984674],
          [138.687314, -34.985111],
          [138.688466, -34.985311],
          [138.689238, -34.985331],
          [138.6904, -34.985394],
          [138.69152, -34.98564],
          [138.692554, -34.986072],
          [138.69327, -34.986532],
          [138.694123, -34.987359],
          [138.694653, -34.988182],
          [138.694968, -34.989091],
          [138.695057, -34.990072],
          [138.695075, -34.990776],
          [138.695266, -34.991679],
          [138.695741, -34.99257],
          [138.696457, -34.993337],
          [138.697389, -34.993941],
          [138.698451, -34.994377],
          [138.69947, -34.994848],
          [138.700205, -34.995412],
          [138.700669, -34.995978],
          [138.701097, -34.996674],
          [138.701734, -34.99744],
          [138.702686, -34.998023],
          [138.70335, -34.99822],
          [138.703952, -34.998305],
          [138.704524, -34.998301],
          [138.705821, -34.998227],
          [138.707602, -34.998271],
          [138.708849, -34.998497],
          [138.710145, -34.998875],
          [138.712186, -34.999859],
          [138.71233, -34.999956],
          [138.713749, -35.001015],
          [138.714382, -35.001396],
          [138.714862, -35.001608],
          [138.715827, -35.001837],
          [138.716088, -35.001868],
          [138.716771, -35.001856],
          [138.718662, -35.001454],
          [138.720238, -35.001022],
          [138.721633, -35.000568],
          [138.72278, -35.000172],
          [138.7246, -34.99946],
          [138.72694, -34.998429],
          [138.728818, -34.997505],
          [138.730674, -34.996453],
          [138.732726, -34.995226],
          [138.73413, -34.994671],
          [138.735741, -34.99439],
          [138.737476, -34.994494],
          [138.739019, -34.994867],
          [138.740654, -34.995684],
          [138.743507, -34.997138],
          [138.747277, -34.998274],
          [138.748657, -34.998697],
          [138.750265, -34.999602],
          [138.750922, -35.000169],
          [138.751679, -35.00085],
          [138.752933, -35.001789],
          [138.753525, -35.002127],
          [138.754495, -35.002535],
          [138.755541, -35.002831],
          [138.757017, -35.003023],
          [138.758251, -35.003022],
          [138.759968, -35.002965],
          [138.76181, -35.003005],
          [138.764025, -35.003296],
          [138.765238, -35.003599],
          [138.766575, -35.004039],
          [138.769739, -35.005101],
          [138.771693, -35.005669],
          [138.773122, -35.006016],
          [138.774421, -35.006305],
          [138.776251, -35.006629],
          [138.77787, -35.007075],
          [138.779215, -35.007691],
          [138.780146, -35.008277],
          [138.780983, -35.008943],
          [138.781468, -35.009391],
          [138.782455, -35.010809],
          [138.783083, -35.012325],
          [138.783276, -35.013278],
          [138.783538, -35.015187],
          [138.783759, -35.016676],
          [138.784052, -35.018626],
          [138.784322, -35.020251],
          [138.784703, -35.021369],
          [138.78535, -35.022466],
          [138.786352, -35.023537],
          [138.786928, -35.023974],
          [138.78858, -35.024857],
          [138.789517, -35.025159],
          [138.792322, -35.025842],
          [138.79379, -35.02621],
          [138.796783, -35.026986],
          [138.799793, -35.027977],
          [138.803381, -35.029692],
          [138.806685, -35.031681],
          [138.808712, -35.033143],
          [138.810951, -35.035265],
          [138.812777, -35.037305],
          [138.814975, -35.040413],
          [138.817938, -35.044601],
          [138.820022, -35.0465],
          [138.822331, -35.04787],
          [138.828043, -35.049874],
          [138.829463, -35.050443],
          [138.83157, -35.051762],
          [138.832744, -35.052688],
          [138.835552, -35.055506],
          [138.837458, -35.056865],
          [138.838647, -35.057519],
          [138.840259, -35.058228],
          [138.842778, -35.05892],
          [138.84547, -35.059227],
          [138.846837, -35.05921],
          [138.849483, -35.058892],
          [138.858902, -35.056422],
          [138.862684, -35.055385],
          [138.86671, -35.054286],
          [138.867989, -35.053961],
          [138.8702, -35.053537],
          [138.87259, -35.053292],
          [138.874537, -35.053221],
          [138.876829, -35.053225],
          [138.879027, -35.053298],
          [138.882394, -35.053404],
          [138.88373, -35.053452],
          [138.885969, -35.05351],
          [138.887497, -35.053563],
          [138.889843, -35.053648],
          [138.891618, -35.05374],
          [138.892605, -35.053808],
          [138.895244, -35.054073],
          [138.898293, -35.054529],
          [138.9012, -35.055128],
          [138.903686, -35.055788],
          [138.907718, -35.056973],
          [138.909615, -35.057406],
          [138.911416, -35.057645],
          [138.912721, -35.057732],
          [138.914124, -35.057695],
          [138.915375, -35.057593],
          [138.919075, -35.056963],
          [138.920915, -35.056767],
          [138.92259, -35.056752],
          [138.924116, -35.056849],
          [138.925398, -35.057017],
          [138.927876, -35.057627],
          [138.929501, -35.058228],
          [138.931984, -35.059436],
          [138.933825, -35.060499],
          [138.936607, -35.062398],
          [138.938613, -35.063922],
          [138.941297, -35.066294],
          [138.942381, -35.067398],
          [138.944757, -35.070135],
          [138.948003, -35.074999],
          [138.950232, -35.078036],
          [138.952457, -35.080396],
          [138.954238, -35.081895],
          [138.957354, -35.084102],
          [138.958516, -35.084952],
          [138.959737, -35.086168],
          [138.960976, -35.087916],
          [138.961765, -35.089809],
          [138.962096, -35.091771],
          [138.962427, -35.093452],
          [138.962772, -35.094393],
          [138.963409, -35.095525],
          [138.964608, -35.096891],
          [138.965741, -35.09775],
          [138.969238, -35.09987],
          [138.971552, -35.101464],
          [138.973917, -35.103611],
          [138.976745, -35.107269],
          [138.977858, -35.108425],
          [138.979307, -35.109445],
          [138.980448, -35.109992],
          [138.981963, -35.110494],
          [138.984296, -35.111084],
          [138.986235, -35.111728],
          [138.989069, -35.113117],
          [138.990635, -35.113969],
          [138.992521, -35.114705],
          [138.994458, -35.11516],
          [138.996375, -35.115289],
          [138.99948, -35.11511],
          [139.00198, -35.115092],
          [139.005768, -35.115451],
          [139.009155, -35.116207],
          [139.01161, -35.116983],
          [139.014967, -35.118412],
          [139.020928, -35.12113],
          [139.026095, -35.122799],
          [139.032413, -35.124287],
          [139.035219, -35.125286],
          [139.037787, -35.126308],
          [139.04164, -35.128278],
          [139.044103, -35.129439],
          [139.048449, -35.130611],
          [139.055457, -35.131378],
          [139.058118, -35.131779],
          [139.062393, -35.132897],
          [139.066911, -35.134474],
          [139.070156, -35.134784],
          [139.072649, -35.134496],
          [139.085992, -35.131408],
          [139.096475, -35.129646],
          [139.101239, -35.129328],
          [139.105509, -35.12944],
          [139.10897, -35.129852],
          [139.113545, -35.130769],
          [139.117804, -35.132091],
          [139.125428, -35.135247],
          [139.130916, -35.137445],
          [139.135655, -35.139118],
          [139.143103, -35.1413],
          [139.151852, -35.143315],
          [139.168315, -35.146561],
          [139.172444, -35.147538],
          [139.177505, -35.149114],
          [139.180584, -35.150078],
          [139.184018, -35.150939],
          [139.187556, -35.151583],
          [139.191846, -35.152059],
          [139.19643, -35.152186],
          [139.20064, -35.15196],
          [139.205405, -35.151332],
          [139.208766, -35.150616],
          [139.212749, -35.149486],
          [139.217173, -35.147776],
          [139.22144, -35.14604],
          [139.226843, -35.144292],
          [139.230126, -35.143555],
          [139.234572, -35.142913],
          [139.239827, -35.142749],
          [139.244578, -35.143131],
          [139.249534, -35.144095],
          [139.260702, -35.147407],
          [139.263407, -35.148215],
          [139.269827, -35.149929],
          [139.274466, -35.150691],
          [139.279769, -35.1511],
          [139.284629, -35.151073],
          [139.288795, -35.150713],
          [139.293238, -35.150075],
          [139.302277, -35.148571],
          [139.314188, -35.146424],
          [139.318196, -35.145961],
          [139.323974, -35.146436],
          [139.327006, -35.147404],
          [139.328934, -35.148298],
          [139.332638, -35.150619],
          [139.334539, -35.152218],
          [139.335662, -35.153426],
          [139.341155, -35.160588],
          [139.343606, -35.163835],
          [139.345282, -35.16605],
          [139.349531, -35.171665],
          [139.356607, -35.181015],
          [139.358773, -35.183414],
          [139.363857, -35.186908],
          [139.366145, -35.188375],
          [139.370524, -35.191183],
          [139.372613, -35.192389],
          [139.376417, -35.194007],
          [139.384855, -35.197535],
          [139.392896, -35.200885],
          [139.399947, -35.203793],
          [139.402968, -35.205207],
          [139.405299, -35.207136],
          [139.407089, -35.209347],
          [139.411773, -35.215311],
          [139.41301, -35.216462],
          [139.422988, -35.223548],
          [139.432704, -35.230718],
          [139.438175, -35.236606],
          [139.441119, -35.240119],
          [139.44897, -35.249579],
          [139.452331, -35.25358],
          [139.45374, -35.255281],
          [139.455602, -35.257497],
          [139.456122, -35.258548],
          [139.457023, -35.261768],
          [139.457907, -35.26481],
          [139.458334, -35.266353],
          [139.45855, -35.267322],
          [139.458683, -35.268349],
          [139.458795, -35.269432],
          [139.459245, -35.271163],
          [139.459973, -35.272506],
          [139.461751, -35.274484],
          [139.463348, -35.275663],
          [139.464755, -35.276319],
          [139.466189, -35.276757],
          [139.475495, -35.278582],
          [139.476655, -35.278879],
          [139.477523, -35.279182],
          [139.478418, -35.279584],
          [139.479398, -35.280119],
          [139.480356, -35.280793],
          [139.481379, -35.281693],
          [139.482766, -35.283408],
          [139.485238, -35.286724],
          [139.485388, -35.286921],
          [139.496539, -35.300476],
          [139.503155, -35.307684],
          [139.506492, -35.312102],
          [139.509585, -35.316041],
          [139.518164, -35.326009],
          [139.534355, -35.345525],
          [139.538627, -35.35067],
          [139.547873, -35.363825],
          [139.551035, -35.368073],
          [139.552801, -35.369683],
          [139.561665, -35.375885],
          [139.564107, -35.37852],
          [139.570547, -35.386175],
          [139.584621, -35.399791],
          [139.588137, -35.402751],
          [139.592682, -35.406609],
          [139.612753, -35.424542],
          [139.619444, -35.429912],
          [139.640004, -35.444573],
          [139.643044, -35.445761],
          [139.65221, -35.449057],
          [139.654879, -35.451194],
          [139.658102, -35.455737],
          [139.66028, -35.457771],
          [139.665074, -35.460914],
          [139.671492, -35.464478],
          [139.683184, -35.465122],
          [139.689052, -35.466456],
          [139.695994, -35.470174],
          [139.697161, -35.470846],
          [139.702075, -35.473576],
          [139.724785, -35.486186],
          [139.74459, -35.496887],
          [139.748802, -35.499682],
          [139.756541, -35.507763],
          [139.759506, -35.510953],
          [139.769943, -35.521757],
          [139.770938, -35.523922],
          [139.772502, -35.535787],
          [139.773713, -35.539725],
          [139.775135, -35.542193],
          [139.78765, -35.559953],
          [139.793885, -35.568547],
          [139.796251, -35.571895],
          [139.800829, -35.578017],
          [139.804754, -35.58246],
          [139.806629, -35.586022],
          [139.807004, -35.591964],
          [139.807293, -35.601361],
          [139.807658, -35.602864],
          [139.808288, -35.604394],
          [139.81572, -35.620473],
          [139.824126, -35.638217],
          [139.82851, -35.648624],
          [139.835999, -35.668323],
          [139.836507, -35.669725],
          [139.837799, -35.672375],
          [139.839674, -35.67529],
          [139.852777, -35.691047],
          [139.854674, -35.693289],
          [139.855189, -35.693903],
          [139.856378, -35.695315],
          [139.857038, -35.696102],
          [139.859547, -35.69928],
          [139.860501, -35.700461],
          [139.866814, -35.707978],
          [139.880962, -35.7251],
          [139.893215, -35.739063],
          [139.900674, -35.748314],
          [139.906057, -35.756276],
          [139.922002, -35.778786],
          [139.923918, -35.781015],
          [139.925529, -35.782525],
          [139.938931, -35.793035],
          [139.941652, -35.794786],
          [139.944772, -35.796351],
          [139.946864, -35.797773],
          [139.948332, -35.799332],
          [139.95018, -35.80172],
          [139.952069, -35.803707],
          [139.953605, -35.804992],
          [139.962012, -35.811435],
          [139.968415, -35.81648],
          [140.031834, -35.865504],
          [140.049558, -35.87905],
          [140.050867, -35.880019],
          [140.055292, -35.883312],
          [140.061293, -35.887789],
          [140.0653, -35.890975],
          [140.089648, -35.909112],
          [140.091423, -35.910093],
          [140.094223, -35.911116],
          [140.09609, -35.912035],
          [140.097535, -35.913326],
          [140.098531, -35.914973],
          [140.099211, -35.917384],
          [140.099496, -35.918543],
          [140.100171, -35.919888],
          [140.101755, -35.921469],
          [140.102923, -35.922159],
          [140.105713, -35.923275],
          [140.107652, -35.924368],
          [140.113326, -35.92878],
          [140.120723, -35.934654],
          [140.123312, -35.936898],
          [140.124169, -35.937506],
          [140.125777, -35.938394],
          [140.127926, -35.939246],
          [140.135828, -35.941358],
          [140.139978, -35.942319],
          [140.144099, -35.942955],
          [140.164584, -35.943992],
          [140.167551, -35.944229],
          [140.170021, -35.944643],
          [140.172725, -35.945325],
          [140.18257, -35.948371],
          [140.231557, -35.963264],
          [140.233653, -35.96412],
          [140.235333, -35.965111],
          [140.236956, -35.966448],
          [140.238917, -35.968666],
          [140.263831, -35.99765],
          [140.287485, -36.025236],
          [140.28872, -36.027595],
          [140.289667, -36.030887],
          [140.291195, -36.033114],
          [140.296233, -36.036302],
          [140.298598, -36.03808],
          [140.304371, -36.044733],
          [140.307416, -36.048269],
          [140.315587, -36.057756],
          [140.323758, -36.067241],
          [140.329256, -36.072736],
          [140.33918, -36.082447],
          [140.340688, -36.083917],
          [140.341666, -36.084866],
          [140.342459, -36.085644],
          [140.344918, -36.088052],
          [140.348452, -36.091522],
          [140.350093, -36.093127],
          [140.352304, -36.095291],
          [140.356828, -36.099718],
          [140.360283, -36.103099],
          [140.365314, -36.107999],
          [140.370095, -36.112699],
          [140.390172, -36.132281],
          [140.394594, -36.135849],
          [140.40314, -36.140245],
          [140.411879, -36.145322],
          [140.425626, -36.152585],
          [140.43046, -36.154558],
          [140.433884, -36.155301],
          [140.442031, -36.15686],
          [140.452548, -36.161185],
          [140.466008, -36.167347],
          [140.473578, -36.170495],
          [140.476949, -36.171517],
          [140.480323, -36.172099],
          [140.487247, -36.172615],
          [140.491637, -36.172949],
          [140.495611, -36.173533],
          [140.499631, -36.174566],
          [140.504965, -36.176457],
          [140.534937, -36.187334],
          [140.557429, -36.195499],
          [140.571082, -36.200472],
          [140.57341, -36.201673],
          [140.576355, -36.20376],
          [140.5785, -36.20512],
          [140.58308, -36.207316],
          [140.586259, -36.208303],
          [140.588192, -36.208842],
          [140.590552, -36.209699],
          [140.604771, -36.217109],
          [140.625227, -36.227836],
          [140.648688, -36.240129],
          [140.662062, -36.247122],
          [140.666743, -36.249678],
          [140.670763, -36.25197],
          [140.673955, -36.253287],
          [140.683924, -36.258258],
          [140.698163, -36.26575],
          [140.719523, -36.276851],
          [140.733537, -36.284126],
          [140.747302, -36.291409],
          [140.749411, -36.292889],
          [140.752893, -36.295834],
          [140.754599, -36.297023],
          [140.757463, -36.298546],
          [140.76035, -36.300377],
          [140.762292, -36.301496],
          [140.76574, -36.30321],
          [140.76756, -36.304357],
          [140.768635, -36.304999],
          [140.769958, -36.30558],
          [140.774149, -36.306755],
          [140.77518, -36.30703],
          [140.776903, -36.307447],
          [140.780236, -36.308344],
          [140.78116, -36.308607],
          [140.782575, -36.308898],
          [140.78472, -36.309232],
          [140.787468, -36.309456],
          [140.78996, -36.309708],
          [140.791764, -36.30996],
          [140.79398, -36.310503],
          [140.798679, -36.312343],
          [140.802269, -36.313747],
          [140.807154, -36.315639],
          [140.808258, -36.316001],
          [140.818254, -36.317783],
          [140.84679, -36.322239],
          [140.848399, -36.3223],
          [140.856639, -36.321242],
          [140.868041, -36.319801],
          [140.869276, -36.319688],
          [140.872138, -36.319752],
          [140.878231, -36.320566],
          [140.913754, -36.326129],
          [140.9237, -36.327783],
          [140.937025, -36.329921],
          [140.942595, -36.330754],
          [140.943686, -36.331001],
          [140.945213, -36.331538],
          [140.947062, -36.332493],
          [140.956416, -36.340201],
          [140.958518, -36.341804],
          [140.960717, -36.342864],
          [140.962556, -36.343379],
          [140.965939, -36.343888],
          [140.968094, -36.344243],
          [140.970325, -36.344804],
          [140.972798, -36.345415],
          [140.982092, -36.346465],
          [140.989616, -36.347247],
          [140.99882, -36.348271],
          [141.015906, -36.350157],
          [141.038943, -36.352615],
          [141.066054, -36.355569],
          [141.102087, -36.359319],
          [141.106515, -36.359998],
          [141.115103, -36.360916],
          [141.122542, -36.361677],
          [141.12635, -36.361804],
          [141.127936, -36.361906],
          [141.132997, -36.362415],
          [141.147337, -36.36387],
          [141.159349, -36.365712],
          [141.1787, -36.36882],
          [141.198052, -36.371928],
          [141.209758, -36.37387],
          [141.226794, -36.376563],
          [141.231016, -36.377308],
          [141.235577, -36.378073],
          [141.237081, -36.378261],
          [141.237184, -36.378247],
          [141.237262, -36.378302],
          [141.237633, -36.378415],
          [141.239746, -36.378765],
          [141.241045, -36.378983],
          [141.245846, -36.379732],
          [141.249562, -36.380294],
          [141.25331, -36.38082],
          [141.26922, -36.382927],
          [141.277641, -36.384037],
          [141.302362, -36.387335],
          [141.319744, -36.389583],
          [141.333442, -36.391385],
          [141.337624, -36.391922],
          [141.348268, -36.392333],
          [141.362312, -36.392763],
          [141.372406, -36.393008],
          [141.375938, -36.393234],
          [141.392184, -36.394924],
          [141.405923, -36.396347],
          [141.418298, -36.397583],
          [141.428085, -36.398592],
          [141.442981, -36.400176],
          [141.474792, -36.403557],
          [141.477494, -36.403716],
          [141.47901, -36.403551],
          [141.491366, -36.401049],
          [141.493222, -36.400405],
          [141.494773, -36.399703],
          [141.49696, -36.398319],
          [141.498387, -36.39714],
          [141.503352, -36.391938],
          [141.50502, -36.390554],
          [141.513964, -36.385716],
          [141.515175, -36.3853],
          [141.524627, -36.383374],
          [141.53162, -36.382277],
          [141.543443, -36.381898],
          [141.554357, -36.378622],
          [141.557865, -36.37734],
          [141.574465, -36.372224],
          [141.590996, -36.367255],
          [141.60084, -36.364278],
          [141.613845, -36.360327],
          [141.617991, -36.358994],
          [141.62137, -36.357242],
          [141.623653, -36.355718],
          [141.633039, -36.350548],
          [141.633937, -36.349867],
          [141.638301, -36.345382],
          [141.641919, -36.341495],
          [141.642842, -36.340489],
          [141.644506, -36.338716],
          [141.646699, -36.336377],
          [141.647916, -36.335079],
          [141.648633, -36.334327],
          [141.649847, -36.333065],
          [141.650008, -36.332937],
          [141.650288, -36.332847],
          [141.650938, -36.332858],
          [141.651618, -36.332911],
          [141.652299, -36.332981],
          [141.654853, -36.333244],
          [141.660116, -36.333824],
          [141.661512, -36.333942],
          [141.666026, -36.334073],
          [141.673753, -36.334089],
          [141.674982, -36.334136],
          [141.67751, -36.334284],
          [141.679768, -36.334382],
          [141.681721, -36.334591],
          [141.683923, -36.334745],
          [141.687321, -36.334985],
          [141.717448, -36.337674],
          [141.730013, -36.341414],
          [141.751817, -36.348743],
          [141.753798, -36.349517],
          [141.758905, -36.351058],
          [141.775373, -36.356384],
          [141.788618, -36.36086],
          [141.791111, -36.361864],
          [141.794255, -36.362932],
          [141.799133, -36.364567],
          [141.802719, -36.365276],
          [141.819565, -36.366989],
          [141.864021, -36.371469],
          [141.886963, -36.373805],
          [141.889255, -36.374406],
          [141.891606, -36.375314],
          [141.900239, -36.378848],
          [141.925049, -36.382811],
          [141.929296, -36.382432],
          [141.931847, -36.382981],
          [141.934151, -36.38437],
          [141.958107, -36.400376],
          [141.960348, -36.402464],
          [141.9627, -36.404675],
          [141.964727, -36.406041],
          [141.969797, -36.408784],
          [141.971333, -36.410015],
          [141.973993, -36.413383],
          [141.976342, -36.416181],
          [141.977692, -36.417344],
          [141.979126, -36.418241],
          [141.981188, -36.419159],
          [141.982641, -36.41961],
          [141.984016, -36.419887],
          [141.997666, -36.421784],
          [141.999983, -36.42235],
          [142.002149, -36.423225],
          [142.005348, -36.425478],
          [142.009468, -36.429929],
          [142.012809, -36.433459],
          [142.01432, -36.434593],
          [142.015363, -36.435131],
          [142.016503, -36.435598],
          [142.018744, -36.436157],
          [142.021276, -36.436418],
          [142.026886, -36.43696],
          [142.030157, -36.437523],
          [142.035066, -36.438924],
          [142.040601, -36.441504],
          [142.046326, -36.445695],
          [142.049146, -36.448754],
          [142.050883, -36.450969],
          [142.054602, -36.456619],
          [142.055919, -36.458026],
          [142.056286, -36.458327],
          [142.057601, -36.459274],
          [142.059627, -36.460356],
          [142.068344, -36.464919],
          [142.077117, -36.469534],
          [142.07854, -36.470437],
          [142.097105, -36.484767],
          [142.098752, -36.486406],
          [142.099464, -36.487718],
          [142.099774, -36.488993],
          [142.099883, -36.492834],
          [142.099909, -36.501576],
          [142.100284, -36.502826],
          [142.101003, -36.503909],
          [142.103144, -36.506147],
          [142.103792, -36.507262],
          [142.104122, -36.508721],
          [142.104581, -36.511177],
          [142.105235, -36.512324],
          [142.106588, -36.513422],
          [142.10848, -36.514443],
          [142.109885, -36.51556],
          [142.111898, -36.518011],
          [142.112557, -36.519458],
          [142.113849, -36.531011],
          [142.115368, -36.545668],
          [142.115525, -36.548691],
          [142.115743, -36.558123],
          [142.116134, -36.575389],
          [142.116441, -36.581345],
          [142.116975, -36.582973],
          [142.119883, -36.586808],
          [142.124656, -36.59314],
          [142.12506, -36.595168],
          [142.125173, -36.609914],
          [142.125626, -36.611905],
          [142.128707, -36.618018],
          [142.136565, -36.633507],
          [142.140989, -36.642302],
          [142.142064, -36.645294],
          [142.143375, -36.647746],
          [142.14503, -36.650171],
          [142.148253, -36.656657],
          [142.155235, -36.670288],
          [142.15964, -36.678995],
          [142.164173, -36.687884],
          [142.169075, -36.696966],
          [142.169756, -36.697545],
          [142.173412, -36.699617],
          [142.184487, -36.704927],
          [142.188862, -36.706978],
          [142.190467, -36.707624],
          [142.191687, -36.708189],
          [142.192675, -36.708663],
          [142.193437, -36.709028],
          [142.1941, -36.709347],
          [142.194374, -36.709477],
          [142.194625, -36.709597],
          [142.195106, -36.709829],
          [142.195527, -36.710026],
          [142.195736, -36.710118],
          [142.196643, -36.710531],
          [142.197321, -36.710892],
          [142.197957, -36.711225],
          [142.19834, -36.71141],
          [142.198646, -36.711575],
          [142.198916, -36.711673],
          [142.199261, -36.711696],
          [142.199755, -36.711697],
          [142.200597, -36.711698],
          [142.20101, -36.711698],
          [142.2021, -36.7117],
          [142.203243, -36.711693],
          [142.204958, -36.711683],
          [142.204854, -36.712168],
          [142.204859, -36.713622],
          [142.204934, -36.714227],
          [142.204853, -36.715421],
          [142.204936, -36.716574],
          [142.204905, -36.717795],
          [142.204935, -36.720839],
          [142.205032, -36.722535],
          [142.205036, -36.722763],
          [142.205122, -36.723049],
          [142.205088, -36.72328],
          [142.205078, -36.72355],
          [142.205206, -36.725366],
          [142.205219, -36.725891],
          [142.205249, -36.726976],
          [142.205343, -36.730038],
          [142.205464, -36.732892],
          [142.205584, -36.733289],
          [142.206056, -36.733895],
          [142.206834, -36.734531],
          [142.209228, -36.736496],
          [142.214089, -36.740522],
          [142.218098, -36.743763],
          [142.223606, -36.746902],
          [142.233967, -36.752637],
          [142.241772, -36.756801],
          [142.246395, -36.75928],
          [142.253972, -36.763351],
          [142.261244, -36.767428],
          [142.285277, -36.780298],
          [142.293065, -36.784228],
          [142.303426, -36.788949],
          [142.314389, -36.794036],
          [142.324179, -36.799281],
          [142.342706, -36.80936],
          [142.362346, -36.819966],
          [142.375424, -36.826501],
          [142.393888, -36.835079],
          [142.396471, -36.836251],
          [142.401138, -36.838641],
          [142.418754, -36.852111],
          [142.423791, -36.855962],
          [142.432111, -36.86197],
          [142.449171, -36.874584],
          [142.453307, -36.877733],
          [142.467237, -36.888312],
          [142.479373, -36.897603],
          [142.486528, -36.902946],
          [142.497557, -36.911569],
          [142.500216, -36.913294],
          [142.503308, -36.91404],
          [142.506784, -36.914081],
          [142.509301, -36.914838],
          [142.510872, -36.91578],
          [142.516871, -36.919511],
          [142.519061, -36.920425],
          [142.523252, -36.922714],
          [142.530001, -36.926323],
          [142.55936, -36.942693],
          [142.560857, -36.943508],
          [142.568453, -36.947761],
          [142.578673, -36.953448],
          [142.604098, -36.967691],
          [142.613413, -36.97296],
          [142.628859, -36.981723],
          [142.630429, -36.982521],
          [142.641409, -36.985925],
          [142.661188, -36.991972],
          [142.688401, -37.000184],
          [142.709101, -37.006533],
          [142.712373, -37.007572],
          [142.714236, -37.008717],
          [142.715444, -37.009987],
          [142.71795, -37.014506],
          [142.719601, -37.019751],
          [142.72078, -37.024583],
          [142.721833, -37.029002],
          [142.726878, -37.036799],
          [142.733257, -37.045797],
          [142.745753, -37.053436],
          [142.749321, -37.055631],
          [142.753497, -37.058185],
          [142.756069, -37.059683],
          [142.756846, -37.060145],
          [142.758944, -37.061436],
          [142.760275, -37.062248],
          [142.761418, -37.062735],
          [142.763085, -37.063379],
          [142.764938, -37.064099],
          [142.76584, -37.064452],
          [142.766773, -37.06493],
          [142.767682, -37.065645],
          [142.770016, -37.068753],
          [142.773126, -37.072948],
          [142.774461, -37.074713],
          [142.777986, -37.07909],
          [142.782299, -37.083961],
          [142.788625, -37.090928],
          [142.790366, -37.092643],
          [142.796339, -37.099128],
          [142.801556, -37.104498],
          [142.804108, -37.105846],
          [142.805288, -37.106854],
          [142.80615, -37.108406],
          [142.810399, -37.114137],
          [142.826283, -37.130674],
          [142.831906, -37.136524],
          [142.838111, -37.14282],
          [142.841313, -37.144923],
          [142.846221, -37.14755],
          [142.85167, -37.150412],
          [142.85599, -37.152731],
          [142.856311, -37.152991],
          [142.859546, -37.1568],
          [142.860924, -37.158406],
          [142.866324, -37.164697],
          [142.8764, -37.176569],
          [142.879151, -37.179712],
          [142.882484, -37.184871],
          [142.883113, -37.187646],
          [142.882493, -37.19075],
          [142.881433, -37.192739],
          [142.880749, -37.195055],
          [142.880709, -37.196476],
          [142.881238, -37.198699],
          [142.884113, -37.204142],
          [142.884817, -37.206339],
          [142.884842, -37.20932],
          [142.884878, -37.211359],
          [142.885318, -37.213089],
          [142.886802, -37.215373],
          [142.890156, -37.219286],
          [142.893364, -37.223047],
          [142.896698, -37.228212],
          [142.902542, -37.237372],
          [142.907017, -37.24122],
          [142.911132, -37.244617],
          [142.912957, -37.247627],
          [142.915271, -37.254816],
          [142.916576, -37.258864],
          [142.917763, -37.262615],
          [142.91879, -37.265771],
          [142.919334, -37.267416],
          [142.921054, -37.270637],
          [142.921845, -37.272093],
          [142.922487, -37.273281],
          [142.924215, -37.276433],
          [142.926372, -37.280512],
          [142.926876, -37.281204],
          [142.927629, -37.281837],
          [142.928127, -37.282037],
          [142.929433, -37.282212],
          [142.931084, -37.28236],
          [142.931715, -37.282403],
          [142.932815, -37.282518],
          [142.935345, -37.282805],
          [142.936583, -37.283002],
          [142.939564, -37.283335],
          [142.944698, -37.283911],
          [142.948624, -37.284371],
          [142.949027, -37.28446],
          [142.949298, -37.284575],
          [142.949507, -37.28473],
          [142.949857, -37.285123],
          [142.949984, -37.285223],
          [142.950144, -37.28528],
          [142.950244, -37.285364],
          [142.950291, -37.285516],
          [142.950416, -37.28568],
          [142.950565, -37.285771],
          [142.951117, -37.285886],
          [142.955124, -37.28638],
          [142.956923, -37.287139],
          [142.965974, -37.294216],
          [142.970173, -37.295999],
          [142.974044, -37.297677],
          [142.984878, -37.302807],
          [142.988642, -37.30459],
          [142.990435, -37.305366],
          [142.992088, -37.305686],
          [143.009377, -37.30768],
          [143.011136, -37.308085],
          [143.017812, -37.310705],
          [143.019362, -37.311234],
          [143.031765, -37.314313],
          [143.040952, -37.316599],
          [143.054046, -37.31957],
          [143.066545, -37.322131],
          [143.071767, -37.324439],
          [143.081254, -37.328667],
          [143.089145, -37.332149],
          [143.098998, -37.33646],
          [143.104814, -37.338749],
          [143.107054, -37.339138],
          [143.108254, -37.339029],
          [143.111269, -37.338552],
          [143.112541, -37.338524],
          [143.116612, -37.338977],
          [143.118285, -37.339455],
          [143.119658, -37.340146],
          [143.120981, -37.340975],
          [143.124759, -37.343362],
          [143.130047, -37.345558],
          [143.131929, -37.346361],
          [143.137689, -37.348908],
          [143.139998, -37.350085],
          [143.141538, -37.351061],
          [143.148295, -37.355571],
          [143.151453, -37.356919],
          [143.1619, -37.360911],
          [143.165981, -37.361552],
          [143.172493, -37.361675],
          [143.178404, -37.362094],
          [143.182563, -37.363286],
          [143.186821, -37.365932],
          [143.191194, -37.370018],
          [143.196163, -37.372793],
          [143.204759, -37.375401],
          [143.213774, -37.377933],
          [143.225135, -37.381168],
          [143.24104, -37.385921],
          [143.25122, -37.388855],
          [143.259258, -37.39107],
          [143.262245, -37.391739],
          [143.265765, -37.392656],
          [143.279079, -37.396418],
          [143.297522, -37.401612],
          [143.299635, -37.402483],
          [143.300876, -37.403412],
          [143.303183, -37.404867],
          [143.305332, -37.40574],
          [143.310759, -37.407513],
          [143.324706, -37.411936],
          [143.333827, -37.414976],
          [143.337011, -37.416408],
          [143.340643, -37.418825],
          [143.343097, -37.420654],
          [143.344154, -37.421375],
          [143.345562, -37.422201],
          [143.346925, -37.422867],
          [143.34887, -37.423634],
          [143.35134, -37.424362],
          [143.35525, -37.42527],
          [143.358234, -37.426188],
          [143.360227, -37.427419],
          [143.362459, -37.428257],
          [143.36374, -37.428449],
          [143.366463, -37.428361],
          [143.368012, -37.428281],
          [143.369285, -37.428303],
          [143.371079, -37.428495],
          [143.376024, -37.429012],
          [143.378568, -37.429306],
          [143.381009, -37.429564],
          [143.381917, -37.429676],
          [143.383637, -37.429876],
          [143.385622, -37.430092],
          [143.386331, -37.43008],
          [143.388483, -37.429632],
          [143.389611, -37.429534],
          [143.390515, -37.429778],
          [143.392002, -37.430852],
          [143.395338, -37.4327],
          [143.39697, -37.433519],
          [143.398375, -37.43384],
          [143.400399, -37.433846],
          [143.402463, -37.433718],
          [143.405082, -37.433834],
          [143.409152, -37.434843],
          [143.414131, -37.436488],
          [143.417721, -37.436801],
          [143.420451, -37.436085],
          [143.426645, -37.433408],
          [143.429884, -37.43235],
          [143.432387, -37.43197],
          [143.435015, -37.431968],
          [143.437354, -37.43223],
          [143.444742, -37.433208],
          [143.447696, -37.433589],
          [143.456212, -37.43472],
          [143.459058, -37.435193],
          [143.461758, -37.436051],
          [143.463218, -37.4367],
          [143.465464, -37.438055],
          [143.467058, -37.438916],
          [143.468373, -37.43947],
          [143.469921, -37.439984],
          [143.472045, -37.440454],
          [143.47371, -37.440659],
          [143.475795, -37.440708],
          [143.478195, -37.440494],
          [143.481193, -37.43981],
          [143.484317, -37.43937],
          [143.486827, -37.439607],
          [143.495991, -37.441933],
          [143.500972, -37.44332],
          [143.505735, -37.445031],
          [143.507882, -37.445808],
          [143.521443, -37.450615],
          [143.52675, -37.452182],
          [143.535133, -37.453845],
          [143.540036, -37.454686],
          [143.547949, -37.456002],
          [143.562304, -37.46125],
          [143.578002, -37.463716],
          [143.58208, -37.464364],
          [143.588178, -37.465818],
          [143.592468, -37.466938],
          [143.594099, -37.46721],
          [143.596914, -37.467496],
          [143.59862, -37.467605],
          [143.60058, -37.467605],
          [143.605142, -37.467422],
          [143.607665, -37.467271],
          [143.611364, -37.466914],
          [143.613424, -37.466697],
          [143.615524, -37.466653],
          [143.617669, -37.466819],
          [143.621432, -37.467506],
          [143.626257, -37.468423],
          [143.63035, -37.469243],
          [143.633807, -37.469862],
          [143.638252, -37.470602],
          [143.642609, -37.471245],
          [143.651994, -37.472446],
          [143.658734, -37.473242],
          [143.670062, -37.474681],
          [143.672118, -37.475185],
          [143.674757, -37.476127],
          [143.677283, -37.477071],
          [143.679383, -37.477663],
          [143.681184, -37.477926],
          [143.682761, -37.477949],
          [143.684869, -37.477645],
          [143.689206, -37.476985],
          [143.692403, -37.476934],
          [143.694363, -37.477074],
          [143.706452, -37.478426],
          [143.711016, -37.479096],
          [143.712333, -37.479273],
          [143.722272, -37.480663],
          [143.730408, -37.481735],
          [143.733605, -37.482156],
          [143.748988, -37.484062],
          [143.75696, -37.48493],
          [143.764828, -37.486265],
          [143.770694, -37.487283],
          [143.779312, -37.488286],
          [143.7817, -37.488592],
          [143.783286, -37.488835],
          [143.784865, -37.489141],
          [143.786397, -37.489517],
          [143.787913, -37.489955],
          [143.789392, -37.490459],
          [143.790823, -37.491028],
          [143.792231, -37.491653],
          [143.793598, -37.492349],
          [143.794888, -37.493107],
          [143.796093, -37.493949],
          [143.79723, -37.494856],
          [143.798262, -37.495838],
          [143.799197, -37.496886],
          [143.800028, -37.497975],
          [143.80075, -37.499131],
          [143.801348, -37.500307],
          [143.801838, -37.501522],
          [143.802241, -37.502752],
          [143.802645, -37.503974],
          [143.803125, -37.505192],
          [143.803746, -37.506361],
          [143.80449, -37.50748],
          [143.805371, -37.508523],
          [143.806363, -37.509527],
          [143.807482, -37.510445],
          [143.808688, -37.511264],
          [143.809967, -37.51202],
          [143.811285, -37.512752],
          [143.815452, -37.514904],
          [143.818052, -37.516081],
          [143.821191, -37.517],
          [143.823345, -37.517341],
          [143.825579, -37.517451],
          [143.827144, -37.51743],
          [143.830208, -37.517364],
          [143.833753, -37.517726],
          [143.836055, -37.518234],
          [143.838376, -37.518984],
          [143.840589, -37.519556],
          [143.841951, -37.519558],
          [143.843083, -37.519118],
          [143.843878, -37.518497],
          [143.844138, -37.518221],
          [143.844334, -37.518091],
          [143.844557, -37.518129],
          [143.844669, -37.518262],
          [143.844599, -37.518458],
          [143.844337, -37.518701],
          [143.844134, -37.519678],
          [143.84414, -37.52058],
          [143.844292, -37.521706],
          [143.844822, -37.523082],
          [143.845257, -37.523796],
          [143.846252, -37.52502],
          [143.848554, -37.527276],
          [143.848818, -37.527444],
          [143.849157, -37.527563],
        ],
        type: 'MultiPolygon',
      },
    },
  ],
}
