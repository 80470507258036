import React, { Component } from 'react'
import {
  CardContainer,
  Card,
  Links,
  IconsContainer,
  ImageOverlay,
} from './style.js'
import { Link } from 'gatsby'
import { Icons } from '../Icons'
import { ResponsiveImage } from '../ResponsiveImage'
import FancyLink from '../../utils/FancyLink.js'
import RegionTag from '../Teaser/RegionTag.js'

class CarouselCard extends Component {
  constructor(props) {
    super(props)

    this.cardContainerRef = React.createRef()
    this.imgRef = React.createRef()

    this.state = {
      initialOffset: 0,
      iconTargeted: false,
    }

    this.positionReset = this.positionReset.bind(this)
  }

  componentDidMount() {
    this.props.venue
      ? this.setState({
          initialOffset: 0,
        })
      : this.setState({
          initialOffset: this.props.index * 5,
        })

    this.mediaQueryMobile = window.matchMedia('(max-width: 768px)')

    this.mediaQueryMobile.addListener(this.positionReset)

    this.positionReset(this.mediaQueryMobile)
  }

  componentWillUnmount() {
    this.mediaQueryMobile.removeListener(this.positionReset)
  }

  positionReset(mediaQuery) {
    if (mediaQuery.matches) {
      this.setState({
        initialOffset: 0,
      })
    } else {
      this.props.venue
        ? this.setState({
            initialOffset: 0,
          })
        : this.setState({
            initialOffset: this.props.index * 5,
          })
    }
  }

  componentDidUpdate() {
    if (this.props.isMobile) {
      this.cardContainerRef.current.style.flex = `1 0 65% `
    } else {
      this.imageMove()
      this.setCardFlexBasis()
    }
  }

  setCardFlexBasis() {
    this.cardContainerRef.current.style.flex = `1 0 ${
      this.props.cardFlexBasis
    }% `
  }

  imageMove() {
    this.imgRef.current.style.transform = `translateX(${this.state
      .initialOffset + this.props.imagePosition}%)`
  }

  render() {
    return (
      <CardContainer
        {...this.props}
        key={this.props.title}
        ref={this.cardContainerRef}
      >
        {this.props.selector && (
          <Links {...this.props}>
            <h3>
              <FancyLink
                openInNewTab
                to={this.props.path ? this.props.path : '/'}
                dangerouslySetInnerHTML={{ __html: this.props.title }}
              />
              <RegionTag {...this.props.region} />
            </h3>

            {this.props.categories && (
              <IconsContainer {...this.props}>
                <Icons carousel categories={this.props.categories} />
              </IconsContainer>
            )}
          </Links>
        )}

        <Card {...this.props}>
          {this.props.srcSet ? (
            <ResponsiveImage
              setRef={this.imgRef}
              src={this.props.url}
              srcSet={this.props.srcSet}
              alt={this.props.title}
              sizes={this.props.sizes}
            />
          ) : (
            <img
              ref={this.imgRef}
              src={this.props.imgURL}
              alt={this.props.title}
            />
          )}

          <ImageOverlay />
        </Card>
      </CardContainer>
    )
  }
}

export default CarouselCard
