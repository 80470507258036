import React, { Component } from 'react'
import Card from './card.js'

import {
  PageContainer,
  PageWrapper,
  Wrap,
  CarouselMain,
  PreviousButton,
  NextButton,
  ProgressBarContainer,
  ProgressBarWrapper,
  ProgressBarPercentage,
  FullWidthContainer,
  ButtonContainer,
  ProgressAndButtons,
} from './style.js'

class Carousel extends Component {
  constructor(props) {
    super(props)

    this.carouselRef = React.createRef()
    this.cardContainer = React.createRef()
    this.progressBarRef = React.createRef()
    this.cardRef = React.createRef()

    this.state = {
      carouselPosition: 0,
      numberOfCards: 0,
      isMobile: false,
      progressPosition: 0,
      barWidth: 25,
      nextDisabled: false,
      prevDisabled: true,
      cardFlexBasis: 0,
      carouselVisible: false,
      imagePosition: 0,
    }

    this.next = this.next.bind(this)
    this.positionReset = this.positionReset.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.setProgressBar = this.setProgressBar.bind(this)
    this.handleMobileScroll = this.handleMobileScroll.bind(this)
  }

  _mounted = false

  componentDidMount() {
    this._mounted = true

    this.setState({
      numberOfCards: this.carouselRef.current.childElementCount,
      cardFlexBasis: 100 / this.props.cardsToShow,
      carouselVisible: true,
    })

    //Mobile QUERY
    // Initialize the media query
    this.mediaQueryMobile = window.matchMedia('(max-width: 768px)')

    // Add a listen event
    this.mediaQueryMobile.addListener(this.positionReset)

    // On load
    this.positionReset(this.mediaQueryMobile)
    this.setProgressBar(this.mediaQueryMobile)

    if (this.mediaQueryMobile.matches) {
      this.setState({
        progressPosition: 0,
      })
    } else {
      this.setState({
        progressPosition: 0,
      })

      setTimeout(() => {
        this.setProgressBar()
      }, 50)
    }
  }

  componentWillUnmount() {
    this._mounted = false

    this.mediaQueryMobile.removeListener(this.positionReset)

    clearTimeout(this.makeMobileId)
  }

  positionReset(mediaQuery) {
    const el = this.carouselRef.current

    if (!this._mounted) {
      return false
    } else {
      el.scrollLeft = 0
    }

    this.setState({
      carouselPosition: 0,
      progressPosition: 0,
      imagePosition: 0,
      nextDisabled: false,
      prevDisabled: true,
    })

    if (mediaQuery.matches) {
      this.makeMobile()
    } else {
      clearTimeout(this.makeMobileId)

      this.setState({
        isMobile: mediaQuery.matches,
        progressPosition: 0,
      })
    }
  }

  makeMobile = () => {
    this.makeMobileId = setTimeout(() => {
      this.setState({ isMobile: true })
    }, 1000)
  }

  next() {
    //updating carouselPosition
    const currentState = this.state.carouselPosition

    this.setState({ carouselPosition: currentState + 1 }, () =>
      this.setProgressBar()
    )

    //moving images left
    const currentImagePosition = this.state.imagePosition

    this.setState({ imagePosition: currentImagePosition - 6 })

    //disabled btn
    if (
      this.state.carouselPosition ===
      this.props.numberOfCards - this.props.cardsToShow - 1
    ) {
      this.setState({ nextDisabled: true })

      if (this.props.numberOfCards === 4) {
        this.setState({ nextDisabled: true, prevDisabled: false })
      }
    } else {
      this.setState({
        nextDisabled: false,
        prevDisabled: false,
      })
    }
  }

  previous() {
    const currentState = this.state.carouselPosition
    this.setState({ carouselPosition: currentState - 1 }, () =>
      this.setProgressBar()
    )

    //moving images right

    const currentImagePosition = this.state.imagePosition

    this.setState({ imagePosition: currentImagePosition + 6 })

    if (this.state.carouselPosition === 1) {
      this.setState({
        prevDisabled: true,
      })

      if (this.props.numberOfCards === 4) {
        this.setState({ prevDisabled: true, nextDisabled: false })
      }
    } else {
      this.setState({
        prevDisabled: false,
        nextDisabled: false,
      })
    }
  }

  setProgressBar() {
    this.setState({
      // this.state.carouselPosition * ((100 - this.state.barWidth) / (this.props.numberOfCards - this.props.cardsToShow))

      progressPosition:
        this.state.carouselPosition *
        (300 / (this.props.numberOfCards - this.props.cardsToShow)),
    })
  }

  handleClick(e) {
    //need to stop this function at mobile
    if (this.state.isMobile) {
      return false
    }

    const progressBarClickX = e.pageX - this.progressBarRef.current.offsetLeft
    const progressBarWidth = this.progressBarRef.current.offsetWidth
    const ceiledClick = Math.ceil(
      (progressBarClickX / progressBarWidth) * this.props.numberOfCards
    )
    const flooredClick = Math.floor(
      (progressBarClickX / progressBarWidth) * this.props.numberOfCards
    )

    const h = this.state.carouselPosition + this.props.cardsToShow

    if (flooredClick < h) {
      if (flooredClick < this.props.cardsToShow) {
        this.setState(
          {
            carouselPosition: 0,
            progressWidth: this.state.initialWidthDesktop,
            imagePosition: 0,
          },
          () => this.setProgressBar()
        )
      } else {
        this.setState(
          {
            carouselPosition: flooredClick - this.props.cardsToShow,
            imagePosition: 0 - 6 * (flooredClick - this.props.cardsToShow),
          },
          () => this.setProgressBar()
        )
      }
    } else {
      if (ceiledClick < this.props.cardsToShow) {
        this.setState(
          {
            carouselPosition: 0,
            progressWidth: this.state.initialWidthDesktop,
            imagePosition: 0,
          },
          () => this.setProgressBar()
        )
      } else {
        this.setState(
          {
            carouselPosition: ceiledClick - this.props.cardsToShow,
            imagePosition: 0 - 6 * (ceiledClick - this.props.cardsToShow),
          },
          () => this.setProgressBar()
        )
      }
    }
  }

  handleMobileScroll() {
    // if (!this.state.isMobile) {
    // 	return false;
    // }

    const el = this.carouselRef.current
    const scrollPercent = el.scrollLeft / (el.scrollWidth - el.clientWidth)

    this.setState({
      carouselPosition: 0,
      progressPosition:
        this.state.barWidth *
        this.props.numberOfCards *
        scrollPercent *
        (12 / this.props.numberOfCards),
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.resetTriggered !== prevProps.resetTriggered &&
      (this.state.carouselPosition || this.state.progressPosition) !== 0
    ) {
      this.positionReset(this.mediaQueryMobile)
    }
    return null
  }

  render() {
    // console.log(this.props)

    return (
      <FullWidthContainer>
        <PageContainer
          {...this.props}
          carouselVisible={this.state.carouselVisible === true}
        >
          <PageWrapper {...this.props}>
            <Wrap {...this.props}>
              <CarouselMain
                {...this.props}
                style={{
                  transform: `translateX( ${this.state.carouselPosition *
                    -this.state.cardFlexBasis}%)`,
                  overflow: this.state.isMobile ? 'auto' : 'visible',
                }}
                ref={this.carouselRef}
                onScroll={this.handleMobileScroll}
              >
                {this.props.journeys
                  ? this.props.journeysImgs.map((img, index) => (
                      <Card
                        ref={this.cardRef}
                        key={index}
                        isMobile={this.state.isMobile}
                        index={index}
                        imgURL={img.url}
                        srcSet={img.srcSet}
                        sizes="500px"
                        imagePosition={this.state.imagePosition}
                        cardFlexBasis={100 / this.props.cardsToShow}
                        setCardFlexBasis={this.setCardFlexBasis}
                      />
                    ))
                  : this.props.selector
                  ? this.props.selectedData.map((card, index) => {
                      if (card.status !== 'publish') {
                        return null
                      }

                      return (
                        <Card
                          selector
                          categories={card.categories}
                          region={card.region}
                          ref={this.cardRef}
                          key={index}
                          isMobile={this.state.isMobile}
                          index={index}
                          title={card.title}
                          path={card.path}
                          srcSet={card.image.srcSet}
                          sizes="800px"
                          imgURL={card.image.url}
                          imagePosition={this.state.imagePosition}
                          cardFlexBasis={100 / this.props.cardsToShow}
                          setCardFlexBasis={this.setCardFlexBasis}
                        />
                      )
                    })
                  : null}
              </CarouselMain>
            </Wrap>

            {this.props.journeys || this.props.operators ? null : (
              <ProgressAndButtons>
                <ButtonContainer>
                  <PreviousButton
                    onClick={() => this.previous()}
                    prevDisabled={this.state.prevDisabled}
                  >
                    <span>
                      <svg
                        width="10"
                        height="16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M.921 8c0-.276.106-.552.316-.763L8.474 0 10 1.526 3.526 8 10 14.474 8.474 16 1.237 8.763A1.076 1.076 0 0 1 .921 8z"
                          fill="#545C52"
                        />
                      </svg>
                    </span>
                  </PreviousButton>

                  <NextButton
                    onClick={() => this.next()}
                    nextDisabled={this.state.nextDisabled}
                  >
                    <span>
                      <svg
                        width="10"
                        height="16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 8c0-.276-.106-.552-.316-.763L2.447 0 .92 1.526 7.395 8 .921 14.474 2.447 16l7.237-7.237c.21-.21.316-.487.316-.763z"
                          fill="#545C52"
                        />
                      </svg>
                    </span>
                  </NextButton>
                </ButtonContainer>

                <div>
                  <ProgressBarContainer>
                    <ProgressBarWrapper
                      {...this.props}
                      onClick={this.handleClick}
                      ref={this.progressBarRef}
                    >
                      <ProgressBarPercentage
                        style={{
                          width: `${this.state.barWidth}%`,
                          transform: `translateY(-2px) translateX(${
                            this.state.progressPosition
                          }%)`,
                        }}
                      />
                    </ProgressBarWrapper>
                  </ProgressBarContainer>
                </div>
              </ProgressAndButtons>
            )}
          </PageWrapper>
        </PageContainer>
      </FullWidthContainer>
    )
  }
}

export default Carousel
