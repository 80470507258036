import React, { Component } from 'react'
import { Link } from 'gatsby'
import { AvenueImg } from '../AvenueImg'
import Slider from 'react-slick'
import '../../utils/slick.css'
import '../../utils/slick-theme.css'
// import { featuredItineraries } from '../data.js';

import NavButton from './navButton.js'
import BgImage from './bgImage.js'

import '../../utils/animate.css'

import {
  Container,
  HeaderContainer,
  NavWrapper,
  NavContainer,
  FeaturedContainer,
  Featured,
  FeaturedTitle,
  ButtonContainer,
  Button,
  PreviousSVG,
  NextSVG,
  InfoContainer,
  CalendarContainer,
  Calendar,
  Days,
  TextContainer,
  Title,
  Text,
  FeaturedPhoto,
  NavLink,
  InfoWrapper,
} from './styled'

export default class FeaturedItineraries extends Component {
  constructor(props) {
    super(props)

    this.navRef = React.createRef()

    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goTo = this.goTo.bind(this)
    this.updateSlideAmount = this.updateSlideAmount.bind(this)

    this.slideChanged = this.slideChanged.bind(this)

    this.state = {
      current: this.props.featuredItineraries[0],
      previousIndex: 0,
      currentIndex: 0,
      days: this.props.featuredItineraries[0].itinerary.avItinerary?.summary
        .days,
      title: this.props.featuredItineraries[0].itinerary.title,
      desc: this.props.featuredItineraries[0].itinerary.excerpt,
      category: this.props.featuredItineraries[0].categoryLabel,
      path: this.props.featuredItineraries[0].itinerary.uri,
    }
  }

  componentDidMount() {
    this.mediaQuery = window.matchMedia('(max-width: 1024px)')
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }

  updateSlideAmount(newIndex) {
    if (!this.mediaQuery.matches) {
      return false
    }

    const el = this.navRef.current
    const scrollPercent = 0.2 * newIndex
    el.scrollLeft = scrollPercent * (el.scrollWidth - el.clientWidth)
  }

  slideChanged(oldIndex, newIndex) {
    const last = this.props.featuredItineraries.length - 1
    this.updateSlideAmount(newIndex)
    this.setState(
      {
        current: this.props.featuredItineraries[newIndex],
        currentIndex: newIndex,
        previousIndex: oldIndex,
      },
      () =>
        this.animateCSS(
          '.info-slide',
          `${
            oldIndex === 0 && newIndex === last
              ? 'fadeOutLeft'
              : oldIndex < newIndex || (oldIndex === last && newIndex === 0)
              ? 'fadeOutRight'
              : 'fadeOutLeft'
          }`,
          () => this.updateInfo(oldIndex, newIndex)
        )
    )
  }

  goTo(element) {
    this.slider.slickGoTo(element)
  }

  animateCSS(element, animationName, callback) {
    const node = document.querySelector(element)
    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
      node.classList.remove('animated', animationName)
      node.removeEventListener('animationend', handleAnimationEnd)

      if (typeof callback === 'function') {
        callback()
      }
    }

    node.addEventListener('animationend', handleAnimationEnd)
  }

  updateInfo(oldIndex, newIndex) {
    const last = this.props.featuredItineraries.length - 1

    this.setState(
      {
        days: this.state.current.itinerary.avItinerary.summary.days,
        title: this.state.current.itinerary.title,
        desc: this.state.current.itinerary.excerpt,
        category: this.state.current.categoryLabel,
        path: this.state.current.itinerary.uri,
      },
      () =>
        this.animateCSS(
          '.info-slide',
          `${
            oldIndex === 0 && newIndex === last
              ? 'fadeInRight'
              : oldIndex < newIndex || (oldIndex === last && newIndex === 0)
              ? 'fadeInLeft'
              : 'fadeInRight'
          }`
        )
    )
  }

  render() {
    const settings = {
      fade: true,
      infinite: true,
      speed: 700,
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.slideChanged,
    }

    return (
      <Container>
        <HeaderContainer>
          <BgImage
            fluid={{
              ...this.props.featuredItineraries[this.state.previousIndex]
                .itinerary.avContent?.featureImage,
              sizes: '700px',
            }}
            alt=""
          />

          {this.props.featuredItineraries.map(
            (feature, index) =>
              this.state.currentIndex === index && (
                <BgImage
                  key={index}
                  fluid={{
                    ...this.props.featuredItineraries[index].itinerary.avContent
                      ?.featureImage,
                    sizes: '700px',
                  }}
                  alt=""
                />
              )
          )}
          <NavContainer ref={this.navRef}>
            <NavWrapper>
              {this.props.featuredItineraries.map((feature, index) => {
                return feature.itinerary ? (
                  <NavButton
                    active={this.state.currentIndex === index}
                    goTo={this.goTo}
                    key={index}
                    index={index}
                    label={feature.categoryLabel}
                  />
                ) : (
                  false
                )
              })}

              <NavLink>
                <Link to="/things-to-do/itineraries">VIEW ALL</Link>
              </NavLink>
            </NavWrapper>
          </NavContainer>

          <FeaturedContainer>
            <Featured>
              <Slider ref={c => (this.slider = c)} {...settings}>
                {this.props.featuredItineraries.map((feature, index) => {
                  return feature.itinerary ? (
                    <FeaturedPhoto key={index}>
                      <AvenueImg
                        style={{ position: 'absolute', width: '100%' }}
                        fluid={{
                          ...feature.itinerary.avContent?.featureImage,
                          sizes: '700px',
                        }}
                        alt=""
                      />
                    </FeaturedPhoto>
                  ) : (
                    false
                  )
                })}
              </Slider>

              <Link to={this.state.path}>
                <FeaturedTitle>Featured itineraries</FeaturedTitle>{' '}
              </Link>

              <ButtonContainer>
                <Button previous onClick={this.previous}>
                  <PreviousSVG />
                </Button>
                <Button next onClick={this.next}>
                  <NextSVG />
                </Button>
              </ButtonContainer>
            </Featured>

            <InfoContainer>
              <InfoWrapper className="info-slide faster5">
                <CalendarContainer>
                  <Calendar />
                  <Days>
                    {this.state.days} Day{this.state.days > 1 && 's'}
                  </Days>
                </CalendarContainer>

                <TextContainer>
                  <Title>
                    <Link
                      to={this.state.path}
                      dangerouslySetInnerHTML={{ __html: this.state.title }}
                    />
                  </Title>

                  <Text dangerouslySetInnerHTML={{ __html: this.state.desc }} />
                </TextContainer>
              </InfoWrapper>
            </InfoContainer>
          </FeaturedContainer>
        </HeaderContainer>
      </Container>
    )
  }
}
