import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { WhatsOn } from '../components/WhatsOn'
import { Carousel } from '../components/Carousel'
import { Journeys } from '../components/Journeys'
import { Acknowledgement } from '../components/Acknowledgement'

import SignUp from '../components/SignUp/LargeSignUp'
import { Unearth } from '../components/Unearth'
import { ParallaxProvider } from 'react-scroll-parallax'

import Map from '../components/Map/index'
import FeaturedItineraries from '../components/FeaturedItineraries'
import { Stories } from '../components/Stories'
import HomeBanner from '../components/HomeBanner/HomeBanner'

import { gql, useQuery } from '@apollo/client'
import styled from 'styled-components'
const GET_SUGGESTION = gql`
  query($databaseId: ID!) {
    suggestion(id: $databaseId, idType: DATABASE_ID) {
      title
    }
  }
`

function getGqlString(doc) {
  return doc.loc && doc.loc.source.body
}

const IndexPage = ({ data }) => {
  // const [isShown, setIsShown] = useState(false)
  const { loading, error, data: yo } = useQuery(GET_SUGGESTION, {
    variables: { databaseId: 6517 },
  })

  const filteredItineraries = data.page.avFeaturedContent.itineraryWidget.filter(
    function(el) {
      return el.itinerary != null
    }
  )
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsShown(true)
  //   }, 3000)
  // }, [])

  return (
    <ParallaxProvider>
      <Layout
        headerTitle="So much to be discovered"
        headerImage={data.page.avContent.featureImage}
        homeLink={data.page.avContent.link}
        homeText={data.page.avContent.text}
        home
      >
        <SEO
          title={data?.page?.seo?.title}
          description={data?.page?.seo?.metaDesc}
          image={data?.page?.avContent?.featureImage?.sourceUrl}
        />
        <HomePageIndenter>
          {!data.page.avFeaturedContent.highlightBanner?.hide &&
            data.page.avFeaturedContent.highlightBanner?.link && (
              <HomeBanner
                highlightBanner={data.page.avFeaturedContent.highlightBanner}
              />
            )}

          {data?.page?.avFeaturedContent?.whatsOn && (
            // <div style={{ maxWidth: `1440px`, margin: `0 auto` }}>
            <WhatsOn featuredEvents={data?.page?.avFeaturedContent?.whatsOn} />
            // </div>
          )}
        </HomePageIndenter>

        <Carousel selector cardsToShow={3} />

        <FeaturedItineraries featuredItineraries={filteredItineraries} />
        <HomePageIndenter secondary>
          {!data.page.avFeaturedContent.secondaryBanner?.hide &&
            data.page.avFeaturedContent.secondaryBanner?.link && (
              <HomeBanner
                secondary
                highlightBanner={data.page.avFeaturedContent.secondaryBanner}
              />
            )}
        </HomePageIndenter>

        <div style={{ maxWidth: `1440px`, margin: `0 auto` }}>
          <Stories stories={data.stories.nodes} />

          <Unearth
            title={data.page.mobUnearth.unearthctaHeading}
            subtitle={data.page.mobUnearth.unearthctaSubheading}
            body={data.page.mobUnearth.unearthctaDescription}
            linkText={data.page.mobUnearth.unearthctaLabel}
            link={data.page.mobUnearth.unearthctaLink}
            image1={data.page.mobUnearth.unearthctaImage1}
            image2={data.page.mobUnearth.unearthctaImage2}
          />

          <Map attractions={data.page.avAttractionsMap.attractions} />
          {/* 	<Journeys
						link={data.page.acf.journeys_cta_link}
						text={data.page.acf.journeys_cta_text}
						desc={data.page.acf.journeys_description}
						gallery={data.page.acf.journeys_gallery}
						title={data.page.acf.journeys_title}
					/> */}

          <SignUp variation="light" tag="VB Homepage" />
        </div>
        {/* {isShown && <Acknowledgement />} */}
      </Layout>
    </ParallaxProvider>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query($id: String!) {
    stories: allWpPost(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { slug: { ne: "industry-hub" } } } }
      }
    ) {
      nodes {
        ...StoryFragment
      }
    }
    page: wpPage(id: { eq: $id }) {
      ...HomeFragment
    }
  }
`

export const HomePageIndenter = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: ${props => props.theme.globalPaddingMobile};
  margin-top: ${props => props.secondary && '20px'};
  margin-bottom: ${props => props.secondary && '-20px'};

  @media (min-width: 768px) {
    padding: 0 30px;
    margin-top: ${props => props.secondary && '0'};
    margin-bottom: ${props => props.secondary && '0'};
  }

  @media (min-width: 1280px) {
    padding: ${props => props.theme.globalPaddingDesktop};
  }

  @media (min-width: 1280px) {
    margin-top: ${props => props.secondary && '-25px'};
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    > * {
      grid-column: 2 / span 10;
    }
  }

  @media (min-width: 1440px) {
    margin-top: ${props => props.secondary && '-55px'};
    margin-bottom: ${props => props.secondary && '133px'};
    display: block;
  }
`
