import React, { Component } from 'react';

import { NavItem } from './styled';

class NavButton extends Component {

  constructor(props) {
		super(props);


		this.handleClick = this.handleClick.bind(this);

  }


  handleClick() {
    this.props.goTo(this.props.index);
  }


	// shouldComponentUpdate(nextProps, nextState) {

	// 	if (nextProps.active) {
	// 		return true;
	// 	}


	// 	return false;


	// }

	render() {

		return (
			<NavItem active={this.props.active}>
        <button onClick={this.handleClick}><span>{this.props.label}</span></button>

			</NavItem>
		);
	}
}

export default NavButton;
