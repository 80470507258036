import React from 'react'

import { Item, ItemWrapper } from './styles/indexStyle'

const City = props => {
  return (
    <ItemWrapper key={props.index}>
      <Item
        activeBtn={props.activeBtn}
        activeCityID={props.activeCityID}
        index={props.index}
        onClick={e => {
          e.preventDefault()
          props.goToCity(props.index)
          if (window.innerWidth < '1024') {
            window.location.href = '#map'
          }
        }}
      >
        <div>
          <h3 key={props.id}>{props.properties.address}</h3>
          <h4 className="time" key={props.id}>
            {props.properties.time}
          </h4>
        </div>
      </Item>
    </ItemWrapper>
  )
}
export default City
