import React from 'react'
import axios from 'axios'

import {
  Container,
  InnerContainer,
  LeftColumn,
  Subtitle,
  Title,
  Text,
  RightColumn,
  Form,
  Button,
  InputWrapper,
  Sent,
} from './style.js'

import { ThemeProvider } from 'styled-components'
import { gql, useMutation } from '@apollo/client'
import { ComponentContainer } from '../../../utils/globalStyledComponents'

import { ErrorMessage } from '../style.js'
import { ReactComponent as Arrow } from '../../../images/arrow-right.svg'
import { Formik } from 'formik'

const darkTheme = {
  primary: '#fff',
  secondary: '#B3BEB1',
  backgroundColor: '#212C1F',
}

const lightTheme = {
  primary: '#212C1F',
  secondary: '#545C52',
  backgroundColor: 'none',
}

const CREATE_SIGNUP = gql`
  mutation($email: String, $name: String, $postcode: String, $tag: String) {
    createSignup(
      input: {
        clientMutationId: "createSignup"
        email: $email
        name: $name
        postcode: $postcode
        tag: $tag
      }
    ) {
      data
      success
    }
  }
`

function LargeSignUp(props) {
  const [createSignup, { data }] = useMutation(CREATE_SIGNUP)

  return (
    <ThemeProvider theme={props.variation === 'light' ? lightTheme : darkTheme}>
      <ComponentContainer>
        <Container {...props}>
          <InnerContainer {...props}>
            <LeftColumn>
              <Subtitle>{props.sub_heading}</Subtitle>
              <Title>{props.heading}</Title>
              <Text>{props.description}</Text>
            </LeftColumn>
            <RightColumn>
              <Formik
                initialValues={{ fullName: '', email: '', postcode: '' }}
                validateOnChange={false}
                validate={values => {
                  let errors = {}

                  if (!values.email) {
                    errors.email = 'Please enter a valid email address'
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = 'Please enter a valid email address'
                  }

                  return errors
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    setSubmitting(true)
                  }, 400)

                  const { email, fullName, postcode } = values

                  createSignup({
                    variables: {
                      email: email,
                      name: fullName,
                      postcode: postcode,
                      tag: props.tag || '',
                    },
                  })
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  validateForm,
                }) => (
                  <Form
                    showError={errors.email}
                    onSubmit={handleSubmit}
                    disabled={isSubmitting}
                  >
                    <Sent disabled={isSubmitting}>
                      Thanks for your interest! <br /> We'll be in touch soon.
                    </Sent>
                    <InputWrapper disabled={isSubmitting}>
                      <input
                        type="text"
                        name="fullName"
                        value={values.fullName}
                        onChange={handleChange}
                        placeholder="Your full name…"
                      />
                      <input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        placeholder="Your email address…"
                      />
                      <input
                        type="text"
                        name="postcode"
                        value={values.postcode}
                        onChange={handleChange}
                        placeholder="Your postcode…"
                      />
                      <ErrorMessage variation name="email">
                        {errors.email}
                      </ErrorMessage>
                    </InputWrapper>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      onSubmit={handleSubmit}
                      onClick={() => {
                        validateForm()
                      }}
                    >
                      <Arrow />
                    </Button>
                  </Form>
                )}
              </Formik>
            </RightColumn>
          </InnerContainer>
        </Container>
      </ComponentContainer>
    </ThemeProvider>
  )
}

// Set default props
LargeSignUp.defaultProps = {
  sub_heading: 'Looking for the best travel ideas?',
  heading: 'Get our curated travel tips',
  description:
    'Find out the best places to eat, drink, stay, shop and explore in Ballarat.',
}

export default LargeSignUp
