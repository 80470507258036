import styled from 'styled-components'
import { device } from '../../utils/breakpoints'

export const Container = styled.div`
  position: fixed;
  top: 0;
  width: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(254, 254, 254);
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 100;

  @media (${device.desktop}) {
    padding: 60px 0;
  }

  svg.cross {
    height: 17px;
    width: 17px;
    position: absolute;
    top: 30px;
    right: 30px;
    opacity: 1;
    transition: 100ms;
    cursor: pointer;

    :hover {
      opacity: 0.6;
    }
    path {
      fill: black;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 180%;

      height: 180%;
    }
  }
`

export const Flags = styled.div`
  display: inline-flex;
  margin: 0 auto;
  margin-bottom: 30px;
  @media (${device.desktop}) {
    margin-bottom: 60px;
  }

  .avenueImg {
    margin-right: 17px;

    height: 40px;
    width: 40px;

    @media (${device.desktop}) {
      margin-right: 38px;
      height: 58px;
      width: 58px;
    }
    :last-of-type {
      margin-right: 0;
    }
  }
`

export const Text = styled.p`
  font-size: 16px;
  font-family: ${({ theme }) => theme.headingFont};
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #212c1f;
  max-width: 792px;
  margin: 0 auto;
  margin-bottom: 15px;

  @media (${device.desktop}) {
    font-size: 20px !important;
    line-height: 30px;
    margin-bottom: 20px;
  }
`

export const EnterButton = styled.button`
  font-family: ${({ theme }) => theme.headingFont};
  display: flex;
  transition: 120ms background ease 0s;
  justify-content: center;
  align-items: center;
  text-transform: none;
  cursor: pointer;
  font-weight: 500;
  color: #212c1f;
  width: 200px;
  margin: 35px auto 0;
  border: none;
  background: none;

  :hover {
    color: #6a2413;
  }
`

export const Icon = styled.img`
  margin-bottom: 0;
  margin-right: 15px;
  svg {
    width: 40px;
    height: 40px;
  }
`
