import styled from 'styled-components';

export const StoriesContainer = styled.div`
	display: grid;
	position: relative;
	grid-template-columns: 1fr;

	@media (min-width: 480px) {
		::after {
			content: "";
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
			z-index: -1;
			transform: translateY(-2px);
			background: black;
			height: 1px;
		}
	}
	@media (min-width: 768px) {
		grid-column-gap: 30px;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	@media (min-width: 1024px) {
		/* padding-bottom: 40px; */
	}

	@media (min-width: 1280px) {
		grid-column-gap: 55px;

		/* grid-row-gap: 40px; */
	}
`;

export const MainWrapper = styled.div`
	display: grid;
	grid-column: span 2;
	grid-row: span 3;
`;

export const RightWrapper = styled.div`
	display: grid;
	overflow: visible;
	grid-column: span 2;
	grid-template-rows: 1fr;
	div {
		> div {
			@media (min-width: 768px) {
				::after {
					display: none;
				}
			}

			@media (min-width: 1280px) {
				::after {
					display: block;
				}
			}
		}
	}
	div {
		:nth-of-type(3) {
			> div {
				@media (min-width: 768px) {
					::before {
						display: none;
					}
				}

				@media (min-width: 1280px) {
					::after {
						bottom: -25px;
					}
				}
			}
		}
	}

	@media (min-width: 480px) {
		grid-column-gap: 20px;
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 768px) {
		grid-gap: 30px;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
	}

	@media (min-width: 1280px) {
		grid-gap: 40px;
		/* grid-template-rows: 160px 160px 160px; */
	}
`;
