import React, { Component } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { AvenueImg } from '../AvenueImg';

import { ComponentContainer, ImageContainer, TextContainer, Img1, Img2, Body } from './style.js';

class Unearth extends Component {
	render() {
		return (
			this.props.image1 && (
				<ComponentContainer {...this.props}>
					<ImageContainer {...this.props}>
						<div>
							<Parallax
								y={this.props.unearth ? [ 20, 40 ] : this.props.variation ? [ -25, 20 ] : [ -15, 10 ]}
								x={this.props.unearth ? [ 5, -2 ] : [0,0]}
								tagOuter="figure"
							>
								<Img1 {...this.props}>
								<AvenueImg
										fluid={{
											...this.props.image1,
											sizes: '600px'
										}}
										alt={this.props.title}
									/>
								</Img1>
							</Parallax>
							<Parallax
								y={this.props.unearth ? [ 14, 40 ] : this.props.variation ? [ -15, 15 ] : [ -15, 10 ]}
								x={this.props.unearth ? [ 0, 0 ] : [ -14, 0 ]}
								styleOuter={this.props.unearth ? { zIndex: '3' } : { zIndex: '-4' }}
								tagOuter="figure"
							>
								<Img2 {...this.props}>
									<AvenueImg
										fluid={{
											...this.props.image2,
											sizes: '600px'
										}}
										alt={this.props.title}
									/>
								</Img2>
							</Parallax>
						</div>
					</ImageContainer>

					<TextContainer {...this.props}>
						<h2>{this.props.title}</h2>
						<h3>{this.props.subtitle}</h3>

						{this.props.variation ? null : (
							<Body unearth={this.props.unearth}>
								<p>{this.props.body}</p>
								<a href={this.props.link}>
									<span>{this.props.linkText}</span>
								</a>
							</Body>
						)}
					</TextContainer>

					{this.props.variation && (
						<Body variation>
							<p>{this.props.body}</p>

							<a href={this.props.link}>
								<span>{this.props.linkText}</span>
							</a>
						</Body>
					)}
				</ComponentContainer>
			)
		);
	}
}

export default Unearth;
