import React, { Component } from 'react';
import { Line } from './style.js'

class HorizontalLine extends Component {
  render() {
    return (
      <Line {...this.props}/>
    );
  }
}

export default HorizontalLine;