import styled from 'styled-components'

export const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  padding: ${props => props.theme.globalPaddingMobile};
  margin-bottom: 12px;

  @media (min-width: 768px) {
    margin-bottom: 10px;
    padding: 0 30px;
  }

  @media (min-width: 1280px) {
    padding: ${props => props.theme.globalPaddingDesktop};
  }
`

export const SelectorTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 8px;
  width: 100%;
  z-index: 2;

  img {
    margin: 0;
    margin-bottom: 10px;
  }
`

export const ClickContainer = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  position: relative;
  flex-direction: row;
`

export const DivingLine = styled.div`
  display: inline-block;
  height: 1px;
  width: 100%;
  text-align: center;
  background: black;
`

export const SelectorTag = styled.div`
  text-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  width: 100%;

  p {
    font-family: 'Freight Big';
    font-size: 20px;
    font-style: italic;
    /* min-width: 220px; */
    line-height: 200%;
    margin: 0 20px;
    color: #212c1f;
    white-space: nowrap;

    @media (min-width: 768px) {
      font-size: 28px;
      /* min-width: 320px; */
      line-height: 140%;
    }
  }
`

export const SelectorTitle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  margin: 0;

  @media (min-width: 768px) {
    margin-top: 20px;
    flex-direction: row;
  }

  @media (min-width: 1024px) {
    margin-bottom: 30px;
  }
  p {
    margin: 0;
    margin-right: 20px;
  }

  h1 {
    font-size: 20px;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 23px;
      margin-right: 20px;
      margin-bottom: 0;
    }

    @media (min-width: 1280px) {
      font-size: 28px;
    }
  }
`

export const Selector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #889390;
  /* position: relative; */
  width: 100%;
  padding: 0 14px;
  height: 60px;
  /* width: 253px; */

  @media (min-width: 768px) {
    height: 60px;

    padding: 0 30px;

    /* width: 330px; */
  }

  h1 {
    line-height: 120%;
    margin: 0;
    font-size: 20px;
    @media (min-width: 768px) {
      font-size: 23px;
      margin: 0;
    }

    @media (min-width: 1280px) {
      font-size: 28px;
    }
  }
`

export const DropDown = styled.div`
  border: 1px solid #889390;
  height: 60px;
  min-width: 60px;
  transform: translateX(-1px);
  display: grid;
  place-content: center;
  img {
    margin: 0;
  }
`

export const SelectorMenu = styled.div`
  background: white;
  min-height: 100%;
  width: 380px;
  position: absolute;
  /* top: -180px; */
  top: 60px;
  /* left: 50%; */
  left: -43%;
  right: -50%;
  padding: 20px 20px;
  border: 1px solid black;
  z-index: 20;
  color: black;
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: ${props => (props.menuOpen ? 'scale(1.0) ' : 'scale(0) ')};
  transform-origin: top;

  @media (min-width: 768px) {
    /* transform: ${props => (props.menuOpen ? 'scale(1.0) ' : 'scale(0)')}; */
    /* top: -180px; */

    left: 0;
    width: 400px;
  }

  @media (min-width: 1280px) {
    width: 500px;
  }
`

export const MenuItem = styled.div`
  background: none;
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
  :hover {
    h1 {
      color: #a55331;
    }
  }
  h1 {
    margin: 20px 0;
  }
`
