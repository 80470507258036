import styled from 'styled-components'
import Art from '../img/svg/art.svg'
import Building from '../img/svg/building.svg'
import Castle from '../img/svg/castle.svg'
import Park from '../img/svg/park.svg'
import Sport from '../img/svg/sport.svg'
import Water from '../img/svg/water.svg'
import Marker from '../img/svg/marker.svg'
import BalMarker from '../img/svg/balMarker.svg'

const Container = styled.div`
  margin: 60px auto;
  margin-bottom: 70px;

  @media (min-width: 1024px) {
    position: relative;
    max-width: 1380px;
    max-height: 696px;
    margin: 79px auto;
    margin-bottom: 114px;
  }
`

const Map = styled.div`
  position: relative;
  height: 340px;

  @media (min-width: 667px) {
    height: 440px;
  }

  @media (min-width: 1024px) {
    position: relative;
    top: 0;
    bottom: 0;

    height: 696px;
  }

  .mapboxgl-marker {
    position: absolute;
    background-image: url(${Marker});
    width: 42px;
    height: 42px;
    background-repeat: no-repeat;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }

  .mapboxgl-marker.last__marker {
    background-image: url(${BalMarker});
    width: 48px;
    height: 48px;
  }
  .marker.park {
    background-image: url(${Park});
  }
  .marker.building {
    background-image: url(${Building});
  }
  .marker.castle {
    background-image: url(${Castle});
  }
  .marker.art {
    background-image: url(${Art});
  }
  .marker.wool {
    background-image: url(${Sport});
  }
  .marker.water {
    background-image: url(${Water});
  }

  .label {
    background: #fbf9f6;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    font-family: Maison Neue;
    font-weight: 500;
    font-size: 15px;
    color: #212c1f;
    letter-spacing: 0;
    text-align: center;
    width: 86px;
    padding-top: 14px;
  }

  .mapboxgl-popUp {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }



  .popup__img-wrap {
    display: block;
    margin-bottom: 0;

    img {
      object-fit: cover;
    }
  }

  .mapboxgl-popup-content  {
    padding: 0;
  }

  .mapboxgl-popup-content h4 {
    font-family: Maison Neue;
    font-weight: 500;
    font-size: 15px;
    color: #212c1f;
    letter-spacing: 0;
    text-align: center;
    margin: 0;
    padding: 0 20px;
    max-width: 200px;
    text-shadow: 2px 0px 4px rgba(0, 0, 0, 0.05);
    text-transform: capitalize;

    padding-bottom: 10px;

    @media (min-width: 1024px) {
      padding-bottom: 15px;
    }
  }
  .mapboxgl-popup-content img {
    margin-bottom: 10px;
  }
  .mapboxgl-popup-tip {
    width: 0;
    height: 0;
    border: 0 solid transparent;
    z-index: 0;
    margin-top: 33px;
  }
`

const Sidebar = styled.div`
  @media (min-width: 667px) {
    position: relative;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    display: ${props => (props.displaySideBar === false ? 'none' : '')};
    position: absolute;
    top: 30px;
    right: 30px;
    max-width: 316px;
    padding-bottom: ${props =>
      props.activeBtn === 'attractions' ? '35px' : '65px'};
    z-index: 1;
    background-color: #fbf9f6;

    -webkit-box-shadow: 5px 10px 35px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 5px 10px 35px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 10px 35px 0px rgba(0, 0, 0, 0.2);
  }
`

const Header = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: 50%;
  grid-template-rows: 1;
  background-color: #fbf9f6;
`

const Button = styled.button`
  font-weight: 700;
  font-size: 12px;
  color: #fbf9f6;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: left;
  border: none;
  padding: 20px 30px;

  cursor: pointer;
  :focus {
    outline: 0;
  }
`

const Attractions = styled(Button)`
  grid-column-start: 1;
  grid-column-end: 1;
  color: ${props =>
    props.activeBtn === 'attractions' ? '#212C1F' : '#9f9f9F'};
  background-color: ${props =>
    props.activeBtn === 'attractions' ? '#FBF9F6' : '#212C1F'};

  @media (min-width: 1024px) {
    margin-right: 0;
  }
`

const Ballarat = styled(Button)`
  grid-column-start: 2;
  grid-column-end: 2;
  color: ${props => (props.activeBtn === 'ballarat' ? '#212C1F' : '#9f9f9F')};
  background-color: ${props =>
    props.activeBtn === 'ballarat' ? '#FBF9F6' : '#212C1F'};
`
const Heading = styled.h1`
  display: ${props => (props.activeBtn === 'ballarat' ? '' : 'none')};
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.25px;
  line-height: 28px;
  color: #212c1f;
  margin: 30px 85px 20px 23px;
`
const SubHeading = styled.h2`
  display: ${props => (props.activeBtn === 'ballarat' ? '' : 'none')};
  font-size: 18px;
  font-weight: 300;
  color: #545c52;
  letter-spacing: 0;
  line-height: 27px;
  border-bottom: 1px solid #dededb;
  margin: 0 20px 18px 20px;
  padding-bottom: 22px;
  text-transform: lowercase;
  :first-letter {
    text-transform: uppercase;
  }
`
const ItemWrapper = styled.li`
  list-style-type: none;
  margin-bottom: 0;
  cursor: pointer;
  border-right: none;
  @media (min-width: 430px) {
    border-right: 1px solid #dededb;
    :nth-child(2n) {
      border-right: none;
    }
  }
  @media (min-width: 1024px) {
    border-right: none;
  }
`

const Item = styled.div`
  display: block;
  text-decoration: none;
  color: ${props => (props.activeCityID === props.index ? '#fff' : '#FBF9F6')};
  border-left: ${props =>
    props.activeBtn === 'ballarat'
      ? props.activeCityID === props.index
        ? '4px solid #A55331'
        : '#FBF9F6'
      : props.activeCityID === props.index
      ? props.attractionCategory === 'art'
        ? '4px solid #D18F75'
        : props.attractionCategory === 'building'
        ? '4px solid #A8444E'
        : props.attractionCategory === 'castle'
        ? '4px solid #88938F'
        : props.attractionCategory === 'park'
        ? '4px solid #5F8864'
        : props.attractionCategory === 'wool'
        ? '4px solid #C4AF8B'
        : props.attractionCategory === 'water'
        ? '4px solid #226593'
        : '#fff'
      : ' #FBF9F6'};
  -webkit-box-shadow: ${props =>
    props.activeCityID === props.index
      ? '0px 5px 20px 0px rgba(0, 0, 0, 0.05);'
      : '#FBF9F6'};
  -moz-box-shadow: ${props =>
    props.activeCityID === props.index
      ? ' 0px 5px 20px 0px rgba(0, 0, 0, 0.05);'
      : '#FBF9F6'};
  box-shadow: ${props =>
    props.activeCityID === props.index
      ? ' 0px 5px 20px 0px rgba(0, 0, 0, 0.05);'
      : '#FBF9F6'};

  div {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: ${props =>
      props.activeBtn === 'attractions' ? 'flex-start' : 'space-between'};
    align-items: center;
    padding-top: ${props => (props.activeBtn === 'ballarat' ? '16px' : '11px')};
    padding-bottom: ${props =>
      props.activeBtn === 'ballarat' ? '16px' : '11px'};
    padding-right: 30px;
    margin-left: ${props =>
      props.activeBtn === 'ballarat'
        ? props.activeCityID === props.index
          ? '16px'
          : '20px'
        : props.activeCityID === props.index
        ? '13px'
        : '15px'};
  }

  h3,
  h4 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.58px;
    line-height: 1.2;
    text-align: left;
    color: ${props =>
      props.activeCityID === props.index ? '#A55331' : '#212c1f;'};
    padding-left: ${props =>
      props.activeBtn === 'attractions' ? '20px' : '0'};
    margin-bottom: 0;
    text-transform: capitalize;
    :focus,
    :hover {
      color: #a55331;
    }
  }

  h4.time {
    font-weight: 300;
    font-size: 14px;
    color: #545c52;
    letter-spacing: 0.58px;
    line-height: 1;
    margin-bottom: 0;
  }

  :focus,
  :hover {
    color: #a55331;
  }
`
const ListWrapper = styled.div`
  ::after {
    content: ' ';
    position: absolute;
    padding-top: 50px;
    left: 10px;
    border-bottom: 1px solid #dededb;
    width: calc(100% - 20px);
  }

  @media (min-width: 1024px) {
    ::after {
      border-bottom: none;
    }
  }
`

const List = styled.ul`
  margin-left: 0;
  margin-bottom: 0;
  @media (min-width: 430px) {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  @media (min-width: 1024px) {
    display: block;
    text-decoration: none;
  }
`

const Listings = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
`

const Icon = styled.div`
  background-size: cover;
  width: 42px;
  height: 42px;
  background-image: ${props =>
    props.attractionCategory === 'art'
      ? `url(${Art})`
      : props.attractionCategory === 'building'
      ? `url(${Building})`
      : props.attractionCategory === 'castle'
      ? `url(${Castle})`
      : props.attractionCategory === 'park'
      ? `url(${Park})`
      : props.attractionCategory === 'wool'
      ? `url(${Sport})`
      : props.attractionCategory === 'water'
      ? `url(${Water})`
      : null};
`

export {
  Container,
  Sidebar,
  Header,
  Heading,
  SubHeading,
  Map,
  ItemWrapper,
  Item,
  ListWrapper,
  List,
  Listings,
  Ballarat,
  Attractions,
  Icon,
}
