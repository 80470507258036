import React, { Component } from 'react';

import { MenuItem } from './style.js';

class SelectorItem extends Component {
	constructor(props) {
		super(props);

		// this.state = {

		// };

		this.itemSelect = this.itemSelect.bind(this);
	}

	// shouldComponentUpdate(nextProps, nextState) {
	//   if(this.props === nextProps) {
	//     return false
	//   }

	//   return true

	// }

	itemSelect() {
		this.props.handleSelect(this.props.selection);
		// console.log(this.props.index);
	}

	render() {
		// console.log(this.props)

		return (
			<MenuItem onClick={this.itemSelect}>
				<h1 dangerouslySetInnerHTML={{ __html: this.props.selection.title }} />
			</MenuItem>
		);
	}
}

export default SelectorItem;
