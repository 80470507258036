import styled from 'styled-components'

export const BannerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: ${props => (props.secondary ? '#A45331' : '#212c1f')};
  margin: 30px 0;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    margin: 80px 0;

    :hover {
      img {
        transform: scale(1.039) !important;
      }
    }
  }

  @media (min-width: 1024px) {
    margin: ${props =>
      props.secondary ? ' 50px 0 110px 0' : '100px 0 110px 0'};
  }
  @media (min-width: 1280px) {
    margin: 50px 0 110px 0;
  }

  @media (min-width: 1440px) {
    margin: 120px 0 110px 0;
  }
`

export const ImgContainer = styled.div`
  height: 250px;
  width: 100%;

  @media (min-width: 768px) {
    height: 100%;
    padding-left: ${props => !props.secondary && '30px'};
    padding-right: ${props => props.secondary && '30px'};
    order: ${props => props.secondary && 2};
  }

  @media (min-width: 1024px) {
    padding-left: ${props => !props.secondary && '50px'};
    padding-right: ${props => props.secondary && '50px'};
  }

  .gatsby-image-wrapper {
    object-fit: cover;
    width: 100%;
    overflow: visible !important;
    height: 100%;

    @media (min-width: 768px) {
      transform: translateY(-30px);
      height: calc(100% + 60px);
    }

    @media (min-width: 1024px) {
      transform: translateY(-40px);
      height: calc(100% + 80px);
    }

    > div {
      padding-bottom: 0 !important;
    }

    img {
      margin: 0;
      transform: scale(1);
      transition: 400ms !important;
      transition-timing-function: ease-in-out;
    }
  }
`

export const TextContainer = styled.div`
  padding: 30px;

  @media (min-width: 768px) {
    padding: 40px;
    order: ${props => props.secondary && 1};
  }

  @media (min-width: 1024px) {
    padding: 70px;
    padding-right: 80px;
  }

  @media (min-width: 1280px) {
    padding: 50px;
    padding-right: 60px;
  }

  @media (min-width: 1440px) {
    padding: 70px;
    padding-right: 80px;
  }
  h2 {
    font-size: 24px;
    color: #fbf9f6;
    text-transform: none;
    font-family: 'Freight Big', serif;

    @media (min-width: 768px) {
      margin-bottom: 25px;
    }
    @media (min-width: 1024px) {
      font-size: 36px;
      margin-bottom: 30px;
    }
  }

  h5 {
    color: #fbf9f6;
    font-size: 12px;
    margin-bottom: 0;
    display: inline-block;
    position: relative;
    text-decoration: underline;
    line-height: 1.3;
    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }
`
