import styled from 'styled-components'

//index.js

export const FullWidthContainer = styled.div`
  width: 100%;
  overflow: hidden;
`

export const PageContainer = styled.div`
  width: 100%;
  overflow: visible;

  visibility: visible;
  display: ${props => (props.journeys ? 'inline-block' : null)};

  @media (min-width: 768px) {
    visibility: ${props => (props.carouselVisible ? 'visible' : 'hidden')};
    display: ${props => (props.journeys ? 'none' : null)};
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  padding-left: ${props => (props.journeys ? '0px' : '10px')};
  padding-top: ${props => (props.selector ? '366px' : null)};
  position: relative;
  @media (min-width: 768px) {
    padding-left: 0px;
    padding-top: ${props => (props.selector ? '440px' : null)};
    margin-bottom: 40px;
  }
  @media (min-width: 1024px) {
    padding-top: ${props => (props.selector ? '370px' : null)};
  }

  @media (min-width: 1280px) {
    margin-bottom: 60px;
    padding-top: ${props => (props.selector ? '531px' : null)};
  }
`

export const Wrap = styled.div`
  width: 100%;
  overflow: visible;
  position: ${props => (props.selector ? 'absolute' : null)};
  top: 0;
  padding-left: 0px;
  padding-right: 0;
  transition: ${props => (props.opaque ? '540ms' : 'none')};
  opacity: ${props => (props.opaque ? '1' : '0')};

  @media (min-width: 768px) {
    padding: 0 40px;
  }

  @media (min-width: 1280px) {
    padding: 0 120px;
  }
`

export const CarouselMain = styled.div`
  display: flex;
  flex-direction: row;
  list-style: none;
  overflow: visible;
  transition: transform 1.5s cubic-bezier(0.23, 1, 0.32, 1);
  position: relative;
  margin-left: -5px;
  margin-right: -5px;
  padding: ${props => (props.journeys ? '20px 0' : '18px 0 12px 0')};
  margin-bottom: 0;

  ::-webkit-scrollbar {
    width: 10px !important;
    /* background: transparent !important; */
    height: 10px !important;
    display: none !important;
  }

  @media (min-width: 768px) {
    margin-left: -10px;
    margin-right: -10px;
  }

  @media (min-width: 1280px) {
    margin-left: -21px;
    margin-right: -21px;
  }
`

export const ProgressBarContainer = styled.div`
  padding-right: 8px;

  @media (min-width: 768px) {
    padding: 0 30px;
  }

  @media (min-width: 1280px) {
    padding: 0 120px;
  }
`

export const ProgressBarWrapper = styled.div`
  height: 1px;
  align-self: center;
  background: #545c52;
  position: relative;
  margin-bottom: 50px;
  align-self: flex-start;
  /* max-width: 1010px; */
  display: ${props => (props.journeys ? 'none' : null)};
  z-index: 3;

  @media (min-width: 768px) {
    /* max-width: 1200px; */
    /* width: 100%; */
    align-self: center;
  }
`

export const ProgressBarPercentage = styled.div`
  background: #a55331;
  height: 5px;
  display: inline-block;
  position: absolute;
  transition: 0ms;
  transform: translateY(-40%);
  z-index: 2;

  @media (min-width: 768px) {
    transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`

export const ProgressBarLength = styled(ProgressBarPercentage)`
  background: #fbf9f6;
  opacity: 1;
  z-index: 3;
`

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background: #545c52;
  transform: translateY(2px);
  position: absolute;
  top: 0;
  z-index: 100;
`

//Card.js
export const CardContainer = styled.div`
  display: flex;
  border: black;
  position: relative;
  height: ${props => (props.journeys ? '305px' : '336px')} !important;
  padding-left: 5px;
  padding-right: 5px;
  flex: ${props => (props.journeys ? '1 0 70%' : null)} !important;
  overflow: visible;

  @media (min-width: 768px) {
    overflow: visible;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 410px !important;

    :hover {
      > div:last-of-type {
        box-shadow: ${props =>
          props.selector ? ' 5px 11px 20px -9px rgba(0,0,0,0.75)' : null};
        /* box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.35); */
      }
    }
  }

  @media (min-width: 1024px) {
    min-height: 180px !important;
  }

  @media (min-width: 1280px) {
    padding-left: 21px;
    padding-right: 21px;
    height: 501px !important;

    max-width: 406px !important;
  }
`

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #ddd;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: 0.4s cubic-bezier(0.23, 1, 0.32, 1);

  ::before {
    content: '';
    background-image: linear-gradient(
      -255deg,
      rgba(0, 0, 0, 0.4) 0.5%,
      rgba(0, 0, 0, 0) 26%
    );
    z-index: 2;
    width: 500px;
    height: 100%;
    position: Absolute;
    display: block;
    top: 0;
    left: 0;
  }

  ::after {
    content: '';
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 1%,
      rgba(0, 0, 0, 0.25) 26%
    );
    z-index: 2;
    width: 100%;
    height: 30%;
    position: Absolute;
    display: block;
    bottom: 0;
    left: 0;
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    margin: 0;
  }

  figure {
    display: block;
    height: 100%;
    width: 170%;
    position: absolute;
    top: 0;
    /* object-fit: cover; */
    margin: 0;
    transition: 1.2s;
    max-width: 200%;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      margin: 0;
    }
  }
`

export const Links = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 5px;
  z-index: 3;
  background: transparent;

  :hover + div {
    @media (min-width: 768px) {
      transform: ${props => (props.selector ? 'scale(1.05)' : 'none')};
    }
  }

  :hover > div {
    opacity: 1;
  }

  @media (min-width: 768px) {
    padding: 0 10px;
  }

  @media (min-width: 1280px) {
    padding: 0 21px;
  }

  h3 {
    padding-left: 25px;
    padding-bottom: 20px;
    color: white;
    margin: 0;
    font-size: 20px;
    display: ${props => (props.journeys ? 'none' : null)};
    padding-right: 0;

    .region {
      margin-top: 10px;
      text-transform: capitalize;
      @media (min-width: 1280px) {
        margin-top: 16px;
      }

      svg {
        background: white;
        border: 1.5px solid white;
        transform: scale(1.2);
        border-radius: 50%;
      }
    }

    @media (min-width: 768px) {
      bottom: 30px;
      left: 40px;
      font-size: 26px;
      padding-bottom: 30px;
      padding-right: 60px;
    }

    @media (min-width: 1280px) {
      left: 51px;
      font-size: 30px;
    }

    a {
      text-decoration: none;
      color: #fbf9f6;
      cursor: pointer;

      :before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
    }
  }
`

export const IconsContainer = styled.div`
  position: absolute;
  top: 0;
  opacity: 0.5;
  z-index: 10;
  transition: 160ms;
  pointer-events: none;
  margin-left: -2px;
  width: 100%;
`

//buttons

export const ProgressAndButtons = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonContainer = styled.div`
  margin: 0 auto;
  display: none;
  z-index: 5;
  background: rgb(251, 249, 246);

  @media (min-width: 768px) {
    display: flex;
    transform: translateY(50%);
  }
`

export const NextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 1px solid #545c52;
  background: #fbf9f6;
  z-index: 4;
  transition: 100ms;
  opacity: ${props => (props.nextDisabled ? '0.3' : '1')};
  pointer-events: ${props => (props.nextDisabled ? 'none' : null)};
  margin-right: -1px;
  cursor: pointer;
  span {
    height: 16px;
  }
  :hover {
    border: 1px solid #a55331;
    color: #a55331;
    z-index: 1000;

    span {
      svg {
        path {
          fill: #a55331;
        }
      }
    }
  }

  :focus {
    outline: none;
  }
`

export const PreviousButton = styled(NextButton)`
  /* transform: translateX(0.5px); */

  opacity: ${props => (props.prevDisabled ? '0.3' : '1')};
  pointer-events: ${props => (props.prevDisabled ? 'none' : null)};
`

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  z-index: 2;
  opacity: 0.1;
`
