import React, { Component } from 'react';

import { BgWrapper, BackgroundImage } from './styled';

class BgImage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fadedIn: false
		};

	}

	componentDidMount() {

		setTimeout(() => {
			this.setState({
				fadedIn: true
			});
		}, 545);
	}
	render() {
		return (
      <BgWrapper>
        <BackgroundImage fadedIn={this.state.fadedIn} {...this.props} />
      </BgWrapper>
    )


	}
}

export default BgImage;
