import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	/* border: 1px dashed teal; */
	grid-template-columns: 1fr;
	padding: 0;
	margin-top: 90px;
	margin-bottom: 60px;

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 40px;
		padding-top: 40px;
	}

	@media (min-width: 1280px) {
		padding: ${(props) => props.theme.globalPaddingDesktop};
		grid-gap: 30px;
		padding-top: 80px;

		margin: 100px 0;
		margin-bottom: 140px;
	}
`;

export const Icon = styled.img`
	width: 32px;
	height: 32px;
	margin: 0;
	margin-top: 10px;
`;

export const LeftSide = styled.div`
	display: grid;
	padding-top: 0px;
	text-align: center;

	grid-template-columns: 1fr;
	justify-items: center;

	max-width: 100%;

	img {
		@media (min-width: 768px) {
			justify-self: start;
		}
	}

	@media (min-width: 768px) {
		grid-template-columns: 60px 1fr;
		grid-template-rows: 1fr;
		padding-top: 40px;
		padding-left: 45px;
		justify-self: end;
		max-width: 400px;
		text-align: left;
	}

	@media (min-width: 1024px) {
		grid-template-columns: 60px 1fr;
		padding-top: 80px;
		padding-left: 30px;
		justify-self: center;
	}

	@media (min-width: 1280px) {
		justify-self: start;
	}
`;
export const TextContainer = styled.div`
width: 100%;
	h1 {
		margin-top: 15px;
		margin-bottom: 10px;
		line-height: 100%;
		font-size: 32px;
		max-width: 200px;
		display: inline-block;

		@media (min-width: 768px) {
			font-size: 36px;
			max-width: 100%;
			margin-top: 0;
			margin-bottom: 20px;
		}

		@media (min-width: 1024px) {
			font-size: 45px;
		}
		@media (min-width: 1280px) {
			font-size: 64px;
			margin-bottom: 30px;
		}
	}

	div.textWrap {
		padding: 0 25px;
		margin-top: 10px;
		font-weight: 300;
		margin-bottom: 27px;

		@media (min-width: 768px) {
			margin-bottom: 20px;
			margin-top: 0px;
			padding: 0;
		}

		@media (min-width: 1280px) {
			margin-bottom: 30px;
		}


	p,
	span,
	.hashtag,
	a {
		font-size: 16px;
		@media (min-width: 1024px) {
			font-size: 18px;
		}

		@media (min-width: 1280px) {
			font-size: 20px;
		}
	}

	}

	span,
	a {
		color: #212c1f;
		font-weight: 500;
		font-size: 12px;
		text-decoration: none;

		@media (min-width: 768px) {
			font-size: 14px;
		}

		:hover {
			color: #a55331;
		}
	}


`;
export const InstaLink = styled.a`
	text-transform: uppercase;
	font-size: 12px !important;

	@media (min-width: 768px) {
		font-size: 14px!important;
	}
`;

export const ImagesContainer = styled.div`
	display: none;

	@media (min-width: 768px) {
		display: grid;
		grid-gap: 0;
		grid-template-columns: repeat(13, 25px);
		grid-template-rows: repeat(13, 25px);
		place-content: start center;
	}

	@media (min-width: 1024px) {
		grid-template-columns: repeat(13, 30px);
		grid-template-rows: repeat(13, 30px);
		place-content: start start;
	}

	@media (min-width: 1280px) {
		grid-template-columns: repeat(13, 42px);
		grid-template-rows: repeat(13, 42px);
		place-content: start end;
	}

	figure {
		margin-bottom: 0;

		:nth-of-type(3n + 1) {
			grid-column: span 8;
			grid-row: span 8;
			transform: translateX(20px);
			z-index: -3;
		}

		:nth-of-type(3n + 2) {
			grid-column: 4 / span 5;
			grid-row: 9 / span 5;
			transform: translateY(-10px);
		}

		:nth-of-type(3n + 3) {
			transform: translateX(-30px);

			grid-column: 9 / span 5;
			grid-row: 6 / span 5;
		}

		div {
			box-shadow: 6px 4px 15px 0 rgba(0, 0, 0, 0.15);

			img {
				margin: 0;
			}
		}
	}
`;
