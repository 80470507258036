import styled from 'styled-components'

export const ComponentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: ${props => (props.unearth ? '-80px' : '0px')};
  margin-bottom: ${props => (props.unearth ? '40px' : '0px')};
  padding: ${props => props.theme.globalPaddingMobile};
  padding-top: 20px;
  padding-bottom: ${props => (props.unearth ? '40px' : null)};
  border-bottom: ${props => (props.unearth ? '1px solid black' : null)};
  /* border: 1px dashed teal; */
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  grid-auto-flow: dense;
  overflow: ${props => (props.unearth ? 'hidden' : null)};
  ${'' /* pointer-events: none; */}

  @media (min-width: 768px) {
    border-bottom: none;
    padding: 0;
    padding-top: 20px;
    grid-template-columns: ${props =>
      props.variation ? '1fr 1fr 1fr' : '1fr 1fr'};
    position: relative;
    ::after {
      content: '';
      position: absolute;
      background: black;
      height: 1px;
      width: 500%;
      bottom: 0;
      right: 0;
      z-index: 100;
      display: ${props => (props.unearth ? 'block' : 'none')};
    }
  }

  @media (min-width: 1024px) {
    margin-bottom: ${props => (props.unearth ? '80px' : null)};
    margin-top: ${props => (props.unearth ? '-120px' : '90px')};
    place-items: baseline;
  }

  @media (min-width: 1280px) {
    place-items: center;
  }

  @media (min-width: 1440px) {
    margin-top: ${props => (props.unearth ? '-150px' : '90px')};
  }
`

export const ImageContainer = styled.div`
  grid-column: ${props => (props.variation ? 'span 1 / 3' : null)};
  position: relative;
  overflow: ${props => (props.unearth ? 'hidden' : null)};
  margin-bottom: ${props => (props.unearth ? '25px' : null)};

  @media (min-width: 768px) {
    grid-column: ${props => (props.unearth ? '2 / span 1' : null)};
    overflow: visible;
    margin-bottom: 0px;
    padding-bottom: ${props => (props.unearth ? '0' : '100px')};

    figure {
      margin: 0;
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: ${props => (props.unearth ? 'flex-end' : 'center')};
    width: 100%;

    ::after {
      content: '';
      position: absolute;
      background: black;
      height: 1px;
      width: 500%;
      bottom: 0;
      right: 0;
      z-index: 100;
      display: ${props => (props.unearth ? 'block' : 'none')};
    }

    @media (min-width: 768px) {
      margin-bottom: 0;

      ::after {
        display: none;
      }
    }

    @media (min-width: 1024px) {
      padding-bottom: ${props => (props.unearth ? '0' : '150px')};
    }
  }

  /* img {
		position: absolute;
		width:100%;
		height:100%;
		top:0;
	} */
`

export const Img1 = styled.div`
  position: relative;
  margin-bottom: 0;
  width: ${props => (props.unearth ? '175px' : '190px')};
  z-index: 1000;
  box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.15);
  transform: translateX(24px);

  @media (min-width: 768px) {
    width: ${props =>
      props.unearth ? '160px' : props.variation ? '190px' : '250px'};
    transform: translateX(33px);
  }

  @media (min-width: 1024px) {
    width: ${props => (props.unearth ? '240px' : null)};
  }

  @media (min-width: 1280px) {
    width: ${props =>
      props.unearth ? '240px' : props.variation ? '220px' : '345px'};
    transform: ${props =>
      props.unearth
        ? 'translateX(40px) '
        : props.variation
        ? 'translateX(36px) '
        : 'translateX(53px)'};
  }

  @media (min-width: 1440px) {
    transform: ${props => (props.unearth ? 'translateX(170px) ' : null)};
    width: ${props => (props.unearth ? '377px' : null)};
  }
`

export const Img2 = styled.div`
  position: relative;
  margin-bottom: 0;
  width: ${props => (props.unearth ? '196px' : '145px')};

  box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.15);
  transform: translateX(-24px);

  @media (min-width: 768px) {
    width: ${props =>
      props.unearth ? '230px' : props.variation ? '150px' : '190px'};
    transform: ${props =>
      props.unearth
        ? 'translateX(-40px) '
        : props.variation
        ? 'translateX(-16px) '
        : 'translateX(-38px)'};
  }

  @media (min-width: 1024px) {
    width: ${props => (props.unearth ? '320px' : null)};
    transform: ${props => (props.unearth ? 'translateX(-80px) ' : null)};
  }

  @media (min-width: 1280px) {
    width: ${props =>
      props.unearth ? '320px' : props.variation ? '170px' : '264px'};

    transform: ${props =>
      props.unearth
        ? 'translateX(-70px) '
        : props.variation
        ? 'translateX(-23px) '
        : 'translateX(-38px)'};
  }

  @media (min-width: 1440px) {
    width: ${props => (props.unearth ? '422px' : null)};

    transform: ${props => (props.unearth ? 'translateX(-50px) ' : null)};
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
    padding-left: ${props => (props.unearth ? '40px' : null)};
  }

  @media (min-width: 1024px) {
    max-width: ${props => (props.unearth ? '400px' : '364px')};
  }

  @media (min-width: 1280px) {
    margin-top: 80px;
    justify-self: ${props => (props.variation ? 'flex-start' : null)};
  }

  > h2 {
    font-family: 'Maison Neue';
    font-weight: bold;
    font-size: ${props => (props.unearth ? '42px' : '42px')};
    line-height: 100%;
    color: #212c1f;
    margin-bottom: ${props => (props.unearth ? '12px' : '6px')};
    text-transform: none;
    @media (min-width: 768px) {
      font-size: ${props =>
        props.unearth ? '56px' : props.variation ? '66px' : '35px'};
    }

    @media (min-width: 1024px) {
      margin-bottom: ${props => (props.unearth ? '18px' : null)};

      font-size: ${props => (props.unearth ? '64px' : '64px')};
    }
  }

  h3 {
    font-size: 18px;
    color: ${props => (props.unearth ? '#545C52' : '#889390')};
    line-height: 100%;
    padding-bottom: ${props => (props.unearth ? '0' : '28px')};
    position: relative;
    font-weight: ${props => (props.unearth ? '500' : null)};

    margin: 0;
    text-transform: ${props => (props.unearth ? 'none' : null)};

    @media (min-width: 768px) {
      font-size: ${props => (props.variation ? '16px' : '18px')};
    }

    @media (min-width: 1024px) {
      font-size: 24px;
      padding-bottom: ${props => (props.unearth ? '0' : '36px')};
    }

    ::before {
      content: '';
      position: absolute;
      bottom: 0;
      margin: 0;
      background: #889390;
      height: 1px;
      width: 100%;

      display: ${props => (props.unearth ? 'none' : 'block')};

      @media (min-width: 768px) {
        display: ${props =>
          props.unearth ? 'none' : props.variation ? 'none' : 'block'};
        font-size: 18px;
      }
    }
  }
`

export const Body = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
    padding-left: ${props => (props.variation ? '25px' : '0')};
  }
  @media (min-width: 1280px) {
    padding-left: ${props => (props.variation ? '45px' : '0')};
  }

  p {
    pointer-events: all;

    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #545c52;
    text-align: center;
    line-height: 150%;
    max-width: 312px;

    @media (min-width: 768px) {
      max-width: 100%;
      text-align: left;
      font-size: 18px;
      padding-right: ${props => (props.variation ? '20px' : '40px')};
    }

    @media (min-width: 1024px) {
      margin: ${props => (props.unearth ? '45px 0' : '25px 0 ')};

      padding-right: ${props => (props.variation ? '20px' : '0px')};
      font-size: 20px;
    }
  }

  a {
    font-size: 12px;
    font-weight: 500;
    color: ${props => (props.unearth ? '#545C52' : ' #212c1f')};
    text-decoration: none;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 14px;
    }

    :hover {
      color: #a55331;
    }
  }
`
