import styled from 'styled-components';
import CalendarSVG from './img/calendar.svg';
import Arrow from './img/next.svg';
import Arrow2 from './img/previous.svg';
import { AvenueImg } from '../AvenueImg';

const mobileFeatureImageGap = '8px';




export const Container = styled.div`
	margin: 0 auto;
	margin-top: 40px;
	margin-bottom: 10px;

	@media (min-width: 768px) {
		margin-top: 80px;
		margin-bottom: 30px;
	}
	@media (min-width: 1024px) {
		margin-top: 120px;
	}
`;

export const BgWrapper = styled.div`
z-index:-1;
	position: Absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	pointer-events: none;
	overflow: hidden;

	@media (min-width: 1024px) {
		height: 75%;
		width: 100%;
	}

	::before {
		content: '';
		background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.27) 1%, rgba(0, 0, 0, 0) 26%);
		z-index: 1;
		width: 100%;
		height: 300px;
		position: Absolute;
		display: block;
		top: 0;
		left: 0;

		@media (min-width: 1024px) {
			height: 1040px;
		}
	}
`;

export const BackgroundImage = styled(AvenueImg)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 780ms;
  opacity: ${(props) => (props.fadedIn ? '1' : '0')};
  filter: blur(12px);
  transform: ${(props) => (props.fadedIn ? 'scale(1.03)' : 'scale(1.09)')};
`;

export const HeaderContainer = styled.div`
	position: relative;
	overflow: hidden;
`;
export const NavWrapper = styled.div`
  margin: 0 auto;
	width:95%;
	list-style: none;
	display: flex;
	margin: 0 auto;
  overflow:visible;
	white-space: nowrap;
	max-width: 1278px;
	transition: 440ms;

	@media (min-width: 1024px) {
		justify-content: space-between;
	}
  @media (min-width: 1440px) {
		width: 90%;
	}

`;

export const NavContainer = styled.div`
  width: 100%;
	padding-top: 30px;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	margin-bottom: 15px;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	::-webkit-scrollbar {
		display: none;
	}

	@media (min-width: 1024px) {
		margin-bottom: 0px;
		padding-top: 56px;
		justify-content: space-between;
	}


`;

export const NavItem = styled.li`
	font-weight: 500;
	font-size: 12px;
	color: #ffffff;
	z-index: 4;
	letter-spacing: 0;
	position: relative;
	margin-right: 40px;

	button {
		text-decoration: none;
		color: #ffffff;
		text-transform: uppercase;
		background: none;
		border: none;
		outline: none;
		cursor: pointer;
		pointer-events: all;
		/* padding: 0 30px; */
	}

	:last-child {
		margin-right: 0;
	}

	span {
		color: white;
		text-shadow: red;
		position: relative;

		::after {
			cursor: pointer;
			content: '';
			position: absolute;
			left: 0;
			bottom: -6px;
			transform: translateY(${(props) => (props.active ? '-3px' : '0')});
			opacity: ${(props) => (props.active ? '1' : '0')};
			transition: 200ms;
			display: inline-block;
			width: 100%;
			border-bottom: 2px solid;
		}
	}

	:hover {
		span ::after {
			transform: translateY(-3px);
			opacity: 1;
		}
	}

	@media (min-width: 1024px) {
		font-size: 15px;

		margin-right: 0px;
	}
`;

export const NavLink = styled(NavItem)`
  a {
    position: relative;
    color: #ffffff;

    ::after {
      cursor: pointer;
      content: '';
      position: absolute;
      left: 0;
      bottom: -6px;
      transform: translateY(${(props) => (props.active ? '-3px' : '0')});
      opacity: ${(props) => (props.active ? '1' : '0')};
      transition: 200ms;
      display: inline-block;
      width: 100%;
      border-bottom: 2px solid;
    }
  }

  :hover {
    a::after {
      transform: translateY(-3px);
      opacity: 1;
    }
  }
`;
export const FeaturedContainer = styled.div`
	-webkit-box-shadow: 0px 35px 50px 0px rgba(0, 0, 0, 0.08);
	-moz-box-shadow: 0px 35px 50px 0px rgba(0, 0, 0, 0.08);
	box-shadow: 0px 35px 50px 0px rgba(0, 0, 0, 0.08);

	@media (min-width: 1024px) {
		background-color: #fbf9f6;
		display: grid;
		max-width: 960px;
		grid-template-columns: 50% 50%;
		grid-template-rows: 455px;

		margin: 40px auto;
		margin-bottom: 100px;
	}

	@media (min-width: 1440px) {
		grid-template-rows: 607px;
		max-width: 1278px;
		margin: 23px auto 100px auto;
	}
`;

export const FeaturedPhoto = styled.div`
  padding-bottom: 85.915493%;
  display:block !important;
  position: relative;
  /* margin: 0 ${mobileFeatureImageGap}; */


  @media (min-width: 600px) {
    padding-bottom: 56.25%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (min-width: 1024px) {
    padding-bottom: 118.436573%;
    margin: 0;
  }
`;

export const Featured = styled.div`
	margin: 0 8px;
	position: relative;
	overflow: hidden;
	background: white;

	@media (min-width: 600px) {
		margin: 0 20px;
	}

	@media (min-width: 1024px) {
		grid-column-start: 1;
		grid-column-end: 1;
		margin: 0;
	}

	::before {
		content: '';
		background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.34) 1%, rgba(0, 0, 0, 0) 26%);
		z-index: 1;
		width: 100%;
		height: 100%;
		position: Absolute;
		display: block;
		top: 0;
		left: 0;

		/* @media (min-width: 1024px) {
			height: 100%;
		} */
	}

	::after {
		content: '';
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 60%);
		z-index: 1;
		width: 100%;
		height: 50%;
		position: Absolute;
		display: block;
		bottom: 0;
		left: 0;
	}

	a {
		cursor: pointer;

		:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
		}
	}
`;

export const FeaturedTitle = styled.h3`
	font-family: Freight Big;
	z-index: 2;
	font-weight: 500;
	font-size: 32px;
	margin: 0;
	color: #fbf9f6;
	letter-spacing: -0.25px;
	line-height: 26px;
	text-align: left;
	padding-top: 28px;
	border-left: 1px solid rgb(251, 249, 246, 0.5);
	margin-left: calc(25px + ${mobileFeatureImageGap});
	padding-left: 22px;
	text-transform: none;
	position: absolute;
	top: 0;
	left: 0;

	@media (min-width: 1024px) {
		font-size: 36px;
		letter-spacing: -0.28px;
		line-height: 39px;
		margin-left: 40px;
		padding-left: 32px;
		width: 300px;
	}
	@media (min-width: 1440px) {
		font-size: 42px;
		letter-spacing: -0.33px;
		padding-top: 43px;
	}
`;
export const ButtonContainer = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;

	bottom: -1px;
	z-index: 3;
`;

export const PreviousSVG = styled.div`
	background-image: url(${Arrow2});
	background-repeat: no-repeat;
	width: 10px;
	height: 16px;
`;

export const NextSVG = styled.div`
	background-image: url(${Arrow});
	background-repeat: no-repeat;
	width: 10px;
	height: 16px;
`;

export const Button = styled.div`
	display: flex;
	flex-direction: row;
	background-color: transparent;
	width: 48px;
	height: 48px;
	border: 1px solid rgb(251, 249, 246, 0.5);
	border-bottom: none;
	border-left: none;
	display: grid;
	place-items: center;
	opacity: 0.5;

	:hover {
		opacity: 1;
		border: 1px solid rgb(251, 249, 246);
		border-left: ${(props) => props.previous && 'transparent'};
		cursor: pointer;
	}

	@media (min-width: 1440px) {
		width: 72px;
		height: 72px;
		font-weight: 500;
	}
`;

export const InfoContainer = styled.div`
	margin: 0 auto;
	display: flex;
	align-items: center;
	background: #fbf9f6;
	position: relative;
	justify-content: center;
	z-index: 1;
	padding: 30px 40px;

	@media (min-width: 1024px) {
		grid-column-start: 2;
		grid-column-end: 2;
		padding: 0 80px;
	}

	@media (min-width: 1440px) {
		padding: 0 110px;
	}
`;

export const CalendarContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Calendar = styled.div`
	background-image: url(${CalendarSVG});
	width: 18px;
	height: 18px;
	background-size: 18px;
	margin-right: 14px;
	background-repeat: no-repeat;

	@media (min-width: 1024px) {
		background-image: url(${CalendarSVG});
		width: 24px;
		height: 23px;
		background-size: 24px;
	}
`;
export const Days = styled.div`
	font-weight: 500;
	font-size: 12px;
	color: #545c52;
	letter-spacing: 0;
	line-height: 20px;

	@media (min-width: 1024px) {
		font-size: 14px;
		letter-spacing: 0;
		line-height: 20px;
	}
`;
export const TextContainer = styled.div`
	width: 100%;
	margin-top: 20px;

	@media (min-width: 1024px) {
		margin-top: 25px;
	}
	@media (min-width: 1440px) {
		margin-top: 25px;
	}
`;

export const Title = styled.h1`
	font-weight: 700;
	font-size: 32px;
	color: #212c1f;
	letter-spacing: -0.19px;
	line-height: 32px;

	@media (min-width: 1024px) {
		font-size: 36px;
		letter-spacing: -0.21px;
		line-height: 36px;
	}

	@media (min-width: 1440px) {
		font-size: 42px;
		letter-spacing: -0.25px;
		line-height: 42px;
	}

	a {
		cursor: pointer;

		:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
		}
	}
`;
export const Text = styled.p`
	font-weight: 300;
	font-size: 16px;
	margin-bottom: 0;
	color: #545c52;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 500px;

	@media (min-width: 1024px) {
		font-size: 18px;
		line-height: 27px;
		max-width: 100%;
	}
	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 30px;
		padding-top: 17px;
	}
`;
export const InfoWrapper = styled.div``;
