import styled from 'styled-components';

export const Container = styled.div`
	margin: 0 auto;
	background-color: ${({ theme }) => theme.backgroundColor};
	margin-bottom: 45px;
	margin-top: 30px;
	border-top: ${props => props.variation === 'light' ? '1px solid #212C1F' : 'none'};
	@media (min-width: 1024px) {
		margin-top:40px;
		margin-bottom: 70px;
	}
	`;
export const InnerContainer = styled.div`
	padding: 40px 0;
	display: flex;
	flex-direction: column;

	padding-bottom: 	 ${props => props.variation === 'light' ? '0 !important' : '40px'};

	

	@media (min-width: 768px) {
		padding: 60px 0;
		flex-direction: row;
		padding-bottom: 0;
	}

	@media (min-width: 1024px) {
		padding: 60px 0;
	}
`;
export const LeftColumn = styled.div`
	@media (min-width: 768px) {
		padding-right: 40px;
		width: 50%;
	}

	@media (min-width: 1024px) {
		padding-right: 90px;
	}
`;
export const Subtitle = styled.p`
	font-weight: 500;
	text-transform: none;
	font-size: 14px;
	color: ${({ theme }) => theme.secondary};
	letter-spacing: 0;
	margin-bottom: 10px;

	@media (min-width: 768px) {
		font-size: 16px;
	}
`;
export const Title = styled.h1`
	font-family: Freight Big;
	text-transform: none;
	font-weight: 700;
	font-size: 40px;
	color: ${({ theme }) => theme.primary};
	letter-spacing: -0.31px;
	line-height: 42px;
	@media (min-width: 768px) {
		margin-bottom: 28px;
	}
`;
export const Text = styled.p`
	font-weight: 300;
	font-size: 17px;
	color: ${({ theme }) => theme.secondary};
	letter-spacing: 0;
	line-height: 27px;
	max-width: 640px;

	@media (min-width: 768px) {
		margin-bottom: 0;
	}
`;

export const RightColumn = styled.div`
	display: inline-flex;
	margin: auto 0;
	@media (min-width: 768px) {
		width: 50%;
	}

	@media (min-width: 1024px) {
		width: 50%;
	}
`;

export const InputWrapper = styled.div`
	width: 100%;
	margin-right: 20px;
	display: ${(props) => (props.disabled ? 'none' : 'grid')};
	grid-template-columns: 1fr;
	grid-gap: 18px;
	max-width: 410px;
	min-width:235px;

	font-size: 16px;
	color: #212c1f;
	letter-spacing: 0;
	line-height: 24px;
	position: relative;
	margin-bottom: 0px;

	@media (min-width: 768px) {
		max-width: 310px;

		grid-template-rows: auto auto auto;
		grid-gap: 30px;
		margin-bottom: 0;
	}
	@media (min-width: 1024px) {
		max-width: 100%;
		font-weight: 300px;
		font-size: 20px;
		color: #545c52;
		letter-spacing: 0;
		line-height: 30px;
	}
`;

export const Form = styled.form`
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px #212c1f inset !important;
		-webkit-text-fill-color: ${({ theme }) => theme.secondary} !important;
	}
	margin:0;
	width: 100%;
	display: flex;

	@media (min-width: 768px) {
		justify-content: center;
	}
	input {
		display: ${(props) => (props.disabled ? 'none' : 'inline')};
		/* width: 80%; */
		color: ${({ theme }) => theme.secondary};
		line-height: 30px;
		font-size: 18px;
		background: none;
		outline: none;
		border: none;
		border-bottom: 1px solid ${({ theme }) => theme.primary};
		border-radius: 0;
		padding-bottom: 5px;
		margin-bottom: 8px;

		::placeholder {
			opacity: 1;
		}

		@media (min-width: 768px) {
			padding-bottom: 10px;
		margin-bottom: 0px;

		}
		@media (min-width: 1024px) {
			font-size: 20px;

			padding-bottom: 7px;
		}

		@media (min-width: 1366px) {
			margin-left: 0;
		}

		:last-of-type {
			border-bottom: ${(props) => (props.showError ? '1px solid #A7444D' : '1px solid ${({ theme }) => theme.primary}')};

		}
	}
`;

export const Button = styled.button`
	width: 40px;
	height: 40px;
	background: transparent;
	border: 1px solid ${({ theme }) => theme.primary};
	display: ${(props) => (props.disabled ? 'none' : 'flex')};

	outline: none;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	align-self: flex-end;
	svg {
		width: 10px;
		path {
			fill: ${({ theme }) => theme.primary};
		}
	}
`;

export const Sent = styled.p`
	color: ${({ theme }) => theme.primary};
	display: ${(props) => (props.disabled ? 'flex' : 'none')};
	margin: 0;
	margin-top: 20px;
	margin-right: 20px;
	font-size: 17px;
	line-height: 134%;
	font-weight: 500;
	align-items: center;

	@media (min-width: 768px) {
		font-size: 20px;
	margin: 0;

	}
`;
