import styled from 'styled-components'

export const Line = styled.div`
	background: ${props => (props.white ? 'white' : 'black')};
	height: 1px;
	width:${props => (props.teaser ? '600%' : '100%')};
	/* margin: ${props =>
    props.standard
      ? '0'
      : props.fullWidth
      ? '0'
      : props.mobile
      ? '20px 0'
      : props.white
      ? '10px 0'
      : props.header
      ? '14px 0 20px 0'
      : props.search
      ? ' 0'
      : '20px 0'}; */
	margin:0;
	/* display: ${props =>
    props.fullWidth
      ? 'none'
      : props.standard
      ? 'none'
      : props.mobile
      ? 'inline-block'
      : props.desktop
      ? 'none'
      : props.storyMain
      ? 'none'
      : props.story
      ? 'none'
      : props.search
      ? 'block'
      : 'none'}; */
	display: block;

	@media (min-width: 768px) {
		/* display: ${props =>
      props.fullWidth
        ? 'none'
        : props.standard
        ? 'none'
        : props.mobile
        ? 'none'
        : props.desktop
        ? 'inline-block'
        : null}; */
		/* margin: ${props =>
      props.standard
        ? '0'
        : props.fullWidth
        ? '0'
        : props.mobile
        ? '20px 0'
        : props.desktop
        ? '30px 0'
        : props.header
        ? '18px 0 30px 0'
        : ' 0'}; */
		margin:0;


	}

	@media (min-width: 1280px) {
		/* margin: ${props =>
      props.standard
        ? '0'
        : props.fullWidth
        ? '0'
        : props.mobile
        ? '20px 0'
        : props.desktop
        ? '48px 0'
        : props.header
        ? '25px 0 50px 0'
        : null}; */
		margin:0;

	}



`
